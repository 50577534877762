import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from "@material-ui/core/CircularProgress";

function DialogConfirm(props) {
    return (
        <Dialog
            open={props.dialogConfirm.open}
            onClose={props.handleCloseDialogConfirm}
            aria-labelledby="confirm-dialog-title"
            aria-describedby="confirm-dialog-description"
        >
            <DialogTitle id="confirm-dialog-title">{props.dialogConfirm.title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="confirm-dialog-description" component={'div'}>
                    <div hidden={props.dialogConfirm.isLoading}>
                        {props.dialogConfirm.description}
                    </div>
                    <div className={"text-center"} hidden={!props.dialogConfirm.isLoading}>
                        <CircularProgress color={"primary"}/>
                    </div>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleSelectNo} color="default"
                        disabled={props.dialogConfirm.isLoading} variant="outlined">
                    {props.dialogConfirm.textCancelBtn}
                </Button>
                <Button onClick={props.handleSelectYes} color="primary"
                        variant="outlined"
                        disabled={props.dialogConfirm.isLoading}>
                    {props.dialogConfirm.textConfirmBtn}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default DialogConfirm;
