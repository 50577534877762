import React from 'react';
import HeaderSection from "../../common/HeaderSection";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import GridOnIcon from '@material-ui/icons/GridOn';

export default function Tables({ponderacion, handleChangeComplements}) {
    return (
        <div className={'pb-4'}>
            <div className={'row'}>
                <div className={'col-md-12'}>
                    <HeaderSection icon={<GridOnIcon/>}
                                   title={'Tablas'}
                                   subtitle={'Configura las propiedades de las tablas'}/>
                </div>
            </div>
            <div className={'row'}>
                <div className={'col-md-12'}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={ponderacion}
                                name="ponderacion"
                                color="primary"
                                onChange={handleChangeComplements}
                            />
                        }
                        label="Ponderación"
                    />
                </div>
            </div>
        </div>
    );
}