import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";

const useStyles = makeStyles((theme) => ({
    primary: {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main
    }
}));

export default function HeaderSection({icon, title, subtitle}) {
    const classes = useStyles();
    return (
            <List dense={true} className={'pb-2'}>
                <ListItem>
                    <ListItemAvatar>
                        <Avatar className={classes.primary}>
                            {icon}
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={
                            <strong>
                                {title}
                            </strong>
                        }
                        secondary={subtitle}
                    />
                </ListItem>
            </List>
    );
}