import React from 'react'
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);

class SimplePieChart extends React.Component {

    componentDidMount() {
        let chart = am4core.create("simple-pie-chart-div-" + this.props.chartId, am4charts.PieChart);
        chart.responsive.enabled = true;
        chart.responsive.useDefault = false;

        chart.responsive.rules.push({
            relevant: function (target) {
                return target.pixelWidth <= 1000;
            },
            state: function (target, stateId) {

                if (target instanceof am4charts.Chart) {
                    let state = target.states.create(stateId);
                    state.properties.paddingTop = 0;
                    state.properties.paddingRight = 25;
                    state.properties.paddingBottom = 0;
                    state.properties.paddingLeft = 25;
                    return state;
                } else if (target instanceof am4charts.AxisLabelCircular ||
                    target instanceof am4charts.PieTick) {
                    let state = target.states.create(stateId);
                    state.properties.disabled = true;
                    return state;
                }
                return null;
            }
        });
        chart.legend = new am4charts.Legend();
        // Add data
        chart.data = this.props.chart.data;

        let colorSet = new am4core.ColorSet();
        colorSet.list = this.props.chart.data.map(function (d) {
            return new am4core.color(d.color);
        });

        // Add and configure Series
        let pieSeries = chart.series.push(new am4charts.PieSeries());
        pieSeries.dataFields.value = "value";
        pieSeries.dataFields.category = "category";
        pieSeries.slices.template.stroke = am4core.color("#fff");
        pieSeries.slices.template.strokeWidth = 1;
        pieSeries.slices.template.strokeOpacity = 1;
        pieSeries.colors = colorSet;

// This creates initial animation
        pieSeries.hiddenState.properties.opacity = 1;
        pieSeries.hiddenState.properties.endAngle = -90;
        pieSeries.hiddenState.properties.startAngle = -90;
        this.chart = chart;
    }

    componentWillUnmount() {
        if (this.chart) {
            this.chart.dispose();
        }
    }

    render() {
        return (
            <div id={"simple-pie-chart-div-" + this.props.chartId} style={{height: this.props.height, width: "100%"}}/>
        );
    }

}

export default SimplePieChart;
