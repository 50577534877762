import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import Metrics from "../Metrics";
import Actions from "../Actions";
import Appearance from "../Appearance";
import Complement from "../Complement";
import Button from "@material-ui/core/Button";
import SaveIcon from '@material-ui/icons/Save';
import * as _ from "lodash";
import Ranges from "../Semaphore/Ranges";
import Constants from "../../../common/Constants";
import AddRange from "../Semaphore/Add";
import Blacklist from "../Blacklist";
import {SettingsService} from "../../../api/service";
import Tables from "../Tables";

const useStyles = theme => ({
    primary: {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main
    }
});

class GeneralSettings extends React.Component {
    constructor(props) {
        super(props);
        let json = this.props.json;
        let colors = json.body.colors;
        let logo = json.header.logo;
        let ranges = json.body.semaforo;
        let blacklist = json.survey === undefined ? [] : json.survey.question_visibility;
        let surveys = this.props.surveys;
        let questions = {};
        for (let i = 0; i < surveys.length; i++) {
            questions[surveys[i].id] = surveys[i].questions;
        }
        this.state = {
            dashboardName: this.props.dashboardName,
            colors: colors,
            logo: logo,
            metric: {
                type: json.metrica.tipo_calculo,
                decimals: json.decimal
            },
            protocol: {
                visible: false,
                text: ''
            },
            excel: {
                visible: false,
                text: ''
            },
            base: {
                visible: false,
                text: ''
            },
            repository: {
                visible: false,
                text: ''
            },
            grill: {
                visible: false,
                text: ''
            },
            ranking: json.footer.ranking === undefined ? false : json.footer.ranking,
            filterGraphs: json.footer.showFilterGraphs === undefined ? false : json.footer.showFilterGraphs,
            logoBase64: '',
            ranges: ranges,
            blacklist: blacklist,
            surveys: surveys,
            search: '',
            questions: questions,
            file: [],
            ponderacion: json.ponderacion === undefined ? false : json.ponderacion,
            task: json.task
        };
        setTimeout(() => {
            let actions = json.header.btn_grp;
            this.setActions(actions);
        }, 100);

    };

    // <editor-fold defaultstate="collapsed" desc="Appearance">

    handleChangeColor = (type, color) => {
        if (type === 1) {
            this.setState(prevState => {
                return {
                    colors: {
                        table_header: color,
                        table_font_header: prevState.colors.table_font_header
                    }
                }
            });
            this.props.handleChangeTheme(1, color);

        } else {
            this.setState(prevState => {
                return {
                    colors: {
                        table_header: prevState.colors.table_header,
                        table_font_header: color
                    }
                }
            });
            this.props.handleChangeTheme(2, color);
        }
    };

    handleRestoreTheme = (type) => {
        let color = type === 1 ? this.props.originalTheme.primary : this.props.originalTheme.font;
        this.handleChangeColor(type, color);
    };

    handleChangeDashboardName = (event) => {
        let name = event.target.value;
        this.setState({
            dashboardName: name
        });
        this.props.handleChangeTitle(name);
    };

    handleRestoreDashboardName = () => {
        this.setState({
            dashboardName: this.props.originalTitle
        });
        this.props.handleChangeTitle(this.props.originalTitle);
    };

    handleChangeFile = (files) => {
        this.getBase64(files[0], (result) => {
            this.setState({
                file: files,
                logoBase64: result
            });
        });
    };

    getBase64(file, cb) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    handleChangeSwitchLogo = (event) => {
        let value = event.target.checked;
        this.setState(prevState => {
            return {
                logo: {
                    visible: value,
                    url: prevState.logo.url
                }
            }
        });
    };

    // </editor-fold>

    // <editor-fold defaultstate="collapsed" desc="Metrics">

    handleChangeType = (event) => {
        this.setState(prevState => {
            return {
                metric: {
                    type: event.target.value,
                    decimals: prevState.metric.decimals
                }
            }
        }, () => {
            setTimeout(() => {
                this.calculateRangeValues(this.state.ranges);
            }, 100);
        });
    };

    handleChangeDecimals = (event) => {
        let decimals = event.target.value;
        if (decimals >= 0 && decimals <= 3) {
            this.setState(prevState => {
                return {
                    metric: {
                        type: prevState.metric.type,
                        decimals: decimals
                    }
                }
            }, () => {
                setTimeout(() => {
                    this.calculateRangeValues(this.state.ranges);
                }, 100);
            });
        }


    };
    // </editor-fold>

    // <editor-fold defaultstate="collapsed" desc="Semaphore">
    handleRemoveRange = (sequence) => {
        let currentRanges = this.state.ranges;
        _.remove(currentRanges, function (o) {
            return o.orden === sequence;
        });
        this.calculateRangeValues(currentRanges);

    };

    addRange = (range) => {
        let currentRanges = this.state.ranges;
        range.orden = currentRanges.length + 1;
        currentRanges.push(range);
        this.setState({
            ranges: currentRanges
        });
        this.props.handleCloseFullScreenDialog();
        this.props.handleOpenSnackbar('El rango se agregó correctamente al semáforo', Constants.SNACKBAR_SUCCESS_TYPE);
    };

    openAddRange = (nextValue) => {
        let content = <AddRange
            nextValue={nextValue}
            metric={this.state.metric}
            addRange={this.addRange}
        />;
        this.props.openFullScreenDialog(this.props.dashboardName, content);
    };
    // </editor-fold>

    // <editor-fold defaultstate="collapsed" desc="Actions">
    setActions = (actions) => {
        let action;
        let visible;
        let text;
        let key;
        for (let i = 0; i < actions.length; i++) {
            action = actions[i];
            switch (action.action) {
                case 'btn-view-pdf':
                    key = 'protocol';
                    break;
                case 'btn-download-excel':
                    key = 'excel';
                    break;
                case 'btn-download-base':
                    key = 'base';
                    break;
                /* case 'btn-repo-multimedia':
                     key = 'repository';
                     break;*/
                case 'btn-multimedia':
                    key = 'grill';
                    break;
                default:
                    key = '';
                    break;
            }

            if (key !== '') {
                visible = action.visible;
                text = action.name;
                this.setState({
                    [key]: {
                        visible: visible,
                        text: text
                    }
                });
            }
        }

    };
    handleChangeSwitch = (event) => {
        let key = event.target.name;
        let value = event.target.checked;
        this.setState(prevState => {
            return {
                [key]: {
                    visible: value,
                    text: prevState[key].text
                }
            }
        });
    };
    handleChangeText = (event) => {
        let key = event.target.name;
        let value = event.target.value;
        this.setState(prevState => {
            return {
                [key]: {
                    visible: prevState[key].visible,
                    text: value
                }
            }
        });
    };

    // </editor-fold>

    // <editor-fold defaultstate="collapsed" desc="Complements">

    handleChangeComplements = (event) => {
        let key = event.target.name;
        let value = event.target.checked;
        this.setState({
            [key]: value
        });
    };

    // </editor-fold>

    // <editor-fold defaultstate="collapsed" desc="blacklist">
    handleToggleBlacklist = (value) => {
        let blacklist = this.state.blacklist;
        let index = blacklist.indexOf(value);
        if (index === -1) {
            blacklist.push(value);
        } else {
            blacklist.splice(index, 1);
        }
        this.setState({
            blacklist: blacklist
        });
    };

    handleOnChangeSearch = (event, surveyId) => {
        let value = event.target.value;
        let surveys = this.state.surveys;
        if (value === '') {
            this.setState({
                search: value,
                surveys: surveys
            });
        } else {
            let currentSurveys = surveys;
            let survey;
            for (let y = 0; y < currentSurveys.length; y++) {
                survey = currentSurveys[y];
                if (survey.id === surveyId) {
                    currentSurveys[y].questions = _.filter(this.state.questions[surveyId], function (o) {
                        let text = o.text.toLowerCase();
                        return text.indexOf(value.toLowerCase()) > -1;
                    });
                    this.setState({
                        search: value,
                        surveys: currentSurveys
                    });
                    break;
                }
            }
        }
    };

    handleClearSearch = () => {
        this.setState({
            search: ''
        });
    };
    // </editor-fold>

    // <editor-fold defaultstate="collapsed" desc="Common">
    calculateRangeValues = (ranges) => {
        let decimals = parseInt(this.state.metric.decimals);
        let complement;

        if (this.state.metric.type === Constants.TYPE_CALCULATION_DECIMAL) {
            complement = 1;
        } else {
            complement = 0.1;
            for (let x = 0; x <= decimals; x++) {
                complement = complement / 10;
            }
        }

        let range;
        for (let i = 0; i < ranges.length; i++) {
            if (i < ranges.length - 1) {
                range = ranges[i];
                ranges[i + 1].min = range.max + complement;
                ranges[i + 1].orden = range.orden + 1;
            }
            ranges[i].orden = i + 1;
        }
        this.setState({
            ranges: ranges
        });
    };

    handleSaveSettings = () => {
        this.props.handleLoading(true);
        if (this.state.logo.visible) {
            if (this.state.file.length > 0) {
                let formData = new FormData();
                formData.append("image", this.state.file[0], this.state.file[0].name);

                let url = "/dashboard-settings/logo/upload";
                SettingsService.postFile(url, formData)
                    .then(response => {
                        let code = response.status;
                        if (code === 200) {
                            this.createJsonSettings(response.url);
                        } else {
                            this.props.handleLoading(false);
                            this.props.handleOpenSnackbar(response.message, Constants.SNACKBAR_ERROR_TYPE);
                        }

                    })
                    .catch(error => console.log(error))
            } else {
                this.createJsonSettings(this.state.logo.url);
            }

        } else {
            this.createJsonSettings('');
        }

    };

    // </editor-fold>

    createJsonSettings = (url) => {
        let json = {
            "decimal": this.state.metric.decimals,
            "ponderacion": this.state.ponderacion,
            "cumplimiento_dimension": true,
            "task": this.state.task,
            "metrica": {
                "tipo_calculo": this.state.metric.type
            },
            "header": {
                "logo": {
                    "visible": this.state.logo.visible,
                    "url": url
                },
                "btn_grp": [
                    {
                        "action": "btn-view-pdf",
                        "name": this.state.protocol.text,
                        "visible": this.state.protocol.visible,
                        "url": ""
                    },
                    {
                        "action": "btn-download-excel",
                        "name": this.state.excel.text,
                        "visible": this.state.excel.visible,
                        "url": ""
                    },
                    {
                        "action": "btn-download-base",
                        "name": this.state.base.text,
                        "visible": this.state.base.visible,
                        "url": ""
                    },
                    {
                        "action": "btn-repo-multimedia",
                        "name": "Repositorio Multimedia",
                        "visible": false,
                        "url": ""
                    },
                    {
                        "action": "btn-multimedia",
                        "name": this.state.grill.text,
                        "visible": this.state.grill.visible,
                        "url": ""
                    },
                    {
                        "action": "btn-nps",
                        "name": "Nps",
                        "visible": false,
                        "url": ""
                    }
                ]
            },
            "body": {
                "data": {
                    "table": {
                        "ponderacion_format": "{0:n0}",
                        "tooltip_comment": false,
                        "ponderacion_rubro": true,
                        "tipo_rubro": true
                    },
                    "chart": {
                        "semaforo_dimension": true,
                        "semaforo_por_paso": true,
                        "semaforo_general": true,
                        "semaforo_ubicacion": true,
                        "font": "8px sans-serif",
                        "position": "outsideEnd",
                        "color": "#000000"
                    }
                },
                "colors": {
                    "table_header": this.state.colors.table_header,
                    "table_font_header": this.state.colors.table_font_header,
                    "alcances": [
                        {
                            "name": "objective",
                            "color": "#479ACD"
                        },
                        {
                            "name": "one",
                            "color": "#BCE3F6"
                        },
                        {
                            "name": "two",
                            "color": "#ED643C"
                        },
                        {
                            "name": "three",
                            "color": "#F0AE5B"
                        },
                        {
                            "name": "four",
                            "color": "#DDDDDD"
                        }
                    ]
                },
                "semaforo": this.state.ranges
            },
            "footer": {
                "ranking": this.state.ranking,
                "sucursal": false,
                "showFilterGraphs": this.state.filterGraphs,
                "indicadores": false
            },
            "survey": {
                "question_visibility": this.state.blacklist
            }
        };

        let url_service = "/dashboard-settings/update";
        let data = {
            dashboardId: this.props.dashboardId,
            modifyUsr: this.props.userId,
            dashboardName: this.state.dashboardName,
            jsonSettings: JSON.stringify(json)
        };
        SettingsService.post(url_service, data)
            .then(response => {
                let code = response.status;
                if (code === 200) {
                    this.setState(prevState => {
                        return {
                            logo: {
                                visible: prevState.logo.visible,
                                url: url
                            },
                            logoBase64: ''
                        }
                    }, () => this.props.getDashboardData());
                    this.props.handleOpenSnackbar('La actualización se realizó correctamente', Constants.SNACKBAR_SUCCESS_TYPE);
                } else {
                    this.props.handleOpenSnackbar(response.message, Constants.SNACKBAR_ERROR_TYPE);
                }

            })
            .catch(error => console.log(error))
    };

    render() {
        const {classes} = this.props;
        return (
            <div>
                <div className={"row pb-3"}>
                    <div className={'col-md-12'}>
                        <Appearance dashboardName={this.state.dashboardName}
                                    colors={this.state.colors}
                                    handleChangeColor={this.handleChangeColor}
                                    logo={this.state.logo}
                                    logoBase64={this.state.logoBase64}
                                    handleChangeDashboardName={this.handleChangeDashboardName}
                                    handleRestoreDashboardName={this.handleRestoreDashboardName}
                                    handleRestoreTheme={this.handleRestoreTheme}
                                    handleChangeFile={this.handleChangeFile}
                                    handleChangeSwitchLogo={this.handleChangeSwitchLogo}
                        />
                        <Metrics metric={this.state.metric}
                                 handleChangeType={this.handleChangeType}
                                 handleChangeDecimals={this.handleChangeDecimals}
                        />
                        <Tables
                            ponderacion={this.state.ponderacion}
                            handleChangeComplements={this.handleChangeComplements}
                        />
                        <Ranges
                            ranges={this.state.ranges}
                            metric={this.state.metric}
                            handleOpenSnackbar={this.props.handleOpenSnackbar}
                            handleRemoveRange={this.handleRemoveRange}
                            openAddRange={this.openAddRange}
                        />
                        <Actions protocol={this.state.protocol}
                                 excel={this.state.excel}
                                 base={this.state.base}
                                 repository={this.state.repository}
                                 grill={this.state.grill}
                                 handleChangeSwitch={this.handleChangeSwitch}
                                 handleChangeText={this.handleChangeText}
                        />
                        <Complement ranking={this.state.ranking}
                                    filterGraphs={this.state.filterGraphs}
                                    handleChangeComplements={this.handleChangeComplements}
                        />
                        <Blacklist
                            surveys={this.state.surveys}
                            blacklist={this.state.blacklist}
                            handleToggleBlacklist={this.handleToggleBlacklist}
                            search={this.state.search}
                            handleOnChangeSearch={this.handleOnChangeSearch}
                            handleClearSearch={this.handleClearSearch}
                        />
                    </div>
                </div>

                <div className={"row"}>
                    <div className={'col-md-12 text-right'}>
                        <Button
                            variant="contained"
                            color="primary"
                            size="medium"
                            className={classes.button}
                            startIcon={<SaveIcon/>}
                            onClick={this.handleSaveSettings}
                        >
                            Guardar
                        </Button>
                    </div>
                </div>
            </div>
        );
    }

}

export default withStyles(useStyles)(GeneralSettings);
