import React from 'react'
import SimpleCard from "../../common/SimpleCard";
import SimplePieChart from "../../common/Chart/SimplePie";
import {DashboardService} from "../../../api/service";
import * as _ from "lodash";
import CircularProgress from "@material-ui/core/CircularProgress";

class ResultsPerRank extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            title: "Sucursales",
            subheader: "Muestra el impacto de resultados por tienda",
            chart: {
                data: []
            }
        };
    }

    getResultsPerRank = () => {
        let url = "/dashboard/rank/location";
        let maxPeriod = _.maxBy(this.props.periods, 'id');
        let data = {
            dashboardId: this.props.dashboardId,
            period: maxPeriod.id,
            semaphore: this.props.semaphore,
            typeCalculation: this.props.typeCalculation,
            decimals: this.props.decimals,
            userId: this.props.userId
        };
        DashboardService.post(url, data)
            .then(response => {
                this.setState(
                    {
                        chart:{
                            data: response.body.data
                        }
                    }
                );
            })
            .catch(error => console.log(error))
            .finally(() => this.setState({isLoading: false}))
    };

    componentDidMount() {
        this.getResultsPerRank();
    }

    render() {
        return (
            <SimpleCard
                cardHeader={
                    {
                        title: this.state.title,
                        subheader: this.state.subheader,
                        action: (
                            <CircularProgress color={"primary"} hidden={!this.state.isLoading}/>
                        )
                    }
                }
                cardContent={
                    this.state.isLoading ? <div style={{height:"300px"}}/> :
                    <SimplePieChart
                        chartId={"rnk"}
                        chart={this.state.chart}
                        height={"300px"}
                    />
                }
            />
        );
    }
}

export default ResultsPerRank;
