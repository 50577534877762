import React from 'react';
import SimpleCard from "../common/SimpleCard";
import BarChart from "../common/Chart/Bar";
import CircularProgress from "@material-ui/core/CircularProgress";

class Bottom extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: 'Bottom 5',
            description: 'Las 5 ubicaciones peor calificadas'
        };
    }
    render() {
        return (
            <SimpleCard
                cardHeader={
                    {
                        title: this.state.title,
                        subheader: this.state.description,
                        action: (
                            <CircularProgress color={"primary"} hidden={!this.props.isLoading}/>
                        )
                    }
                }
                cardContent={
                    this.props.isLoading ? <div style={{height:"350px"}}/> :
                        <BarChart
                            chartId={"bottom"}
                            height={"350px"}
                            width={"100%"}
                            chart={this.props.chart}
                            withLegend={false}
                            symbol={this.props.symbol}
                            semaphore={this.props.semaphore}
                            typeCalculation={this.props.typeCalculation}
                        />
                }
            />
        );
    }
}

export default Bottom;
