import React from 'react';
import CircularProgress from "@material-ui/core/CircularProgress";
import SimpleCard from "../common/SimpleCard";
import Constants from "../../common/Constants";
import {DashboardService} from "../../api/service";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import InputLabel from "@material-ui/core/InputLabel";
import {Combobox} from "react-widgets";
import SearchIcon from "@material-ui/icons/Search";
import Button from "@material-ui/core/Button";
import SnackbarContentWrapper from "../common/SnackBar";
import Snackbar from "@material-ui/core/Snackbar";
import Divider from "@material-ui/core/Divider";
import GroupedColumnChart from "../common/Chart/GroupedColumn";
import {withStyles} from "@material-ui/core";

const useStyles = theme => ({
    button: {
        margin: theme.spacing(1),
        [theme.breakpoints.down("sm")]: {
            minWidth: 32,
            paddingLeft: 8,
            paddingRight: 8,
            "& .MuiButton-startIcon": {
                margin: 0
            }
        }
    },
    buttonText: {
        [theme.breakpoints.down("sm")]: {
            display: "none"
        }
    }
});

class Comparative extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            chart: {
                data: [],
                category: '',
                series: []
            },
            settings: {},
            defaultLocation: this.props.locationName,
            locations: [],
            selectedLocation: {
                id: 0,
                name: ''
            },
            snackbar: {
                open: false,
                type: 'error',
                message: ''
            }
        };

    }

    // <editor-fold defaultstate="collapsed" desc="Snackbar">
    handleCloseSnackbar = () => {
        this.setState({
            snackbar: {
                open: false,
                type: 'error',
                message: ''
            }
        });
    };

    handleOpenSnackbar = (message, type) => {
        this.setState({
            snackbar: {
                open: true,
                type: type,
                message: message
            }
        });
    };

    // </editor-fold>

    getComparativeScore = (locationsStr) => {
        let url = "dashboard/comparative/score";
        let data = {
            dashboardId: this.props.dashboardId,
            decimals: this.props.decimals,
            typeCalculation: this.props.typeCalculation,
            locationsStr: locationsStr,
            userId:this.props.userId
        };
        DashboardService.post(url, data)
            .then(response => {
                this.setState({
                    chart: {
                        data: response.body.data.comparatives,
                        series: response.body.data.periods,
                        category: 'comparativeName'
                    },
                    settings: response.body.data.settings,
                    locations: response.body.data.locations
                });
            })
            .catch(error => console.log(error))
            .finally(() => this.setState({isLoading: false}))
    };

    componentDidMount() {
        this.getComparativeScore(this.props.locationsStr);
    }

    handleChangeLocation = value => {
        this.setState({
            selectedLocation: value
        });
    };

    handleOnClickSearch = () => {
        if (this.state.selectedLocation.id === 0) {
            this.handleOpenSnackbar('Selecciona una ubicación', Constants.SNACKBAR_ERROR_TYPE)
        } else {
            this.setState({
                isLoading: true,
                defaultLocation: this.state.selectedLocation.name
            }, () => {
                setTimeout(() => {
                    this.getComparativeScore(this.state.selectedLocation.id + "");
                }, 100);
            });
        }
    };

    render() {
        let symbol = this.props.typeCalculation === Constants.TYPE_CALCULATION_DECIMAL ? "" : "%";
        const {classes} = this.props;
        return (
            <div className={"pt-5"}>
                <React.Fragment>
                    <CssBaseline/>
                    <Container maxWidth="xl" fixed>
                        <SimpleCard
                            cardHeader={
                                {
                                    title: "Comparativa",
                                    subheader: "Comparativa de dashboard - período configurados",
                                    action: (
                                        <CircularProgress color={"primary"} hidden={!this.state.isLoading}/>
                                    )
                                }
                            }
                            cardContent={
                                <div>
                                    <div className={'row pb-2'}>
                                        <div className={"col-md-10 pb-2"} key={"div-filer-location"}>
                                            <InputLabel htmlFor={"filter-location"} shrink={true}
                                                        style={{fontWeight: "bold"}}
                                                        required={true}>{'Ubicación'}</InputLabel>
                                            <Combobox
                                                key={"filter-location"}
                                                data={this.state.locations}
                                                valueField='id'
                                                textField='name'
                                                defaultValue={this.state.defaultLocation}
                                                caseSensitive={false}
                                                minLength={3}
                                                filter={filterLocation}
                                                disabled={this.state.isLoading}
                                                messages={{
                                                    emptyList: 'No hay datos',
                                                    emptyFilter: 'El filtro no retorno datos',
                                                    openCombobox: 'Desplegar'
                                                }}
                                                onSelect={value => this.handleChangeLocation(value)}
                                            />
                                        </div>
                                        <div className={'col-md-2 text-center pt-4'}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                disabled={this.state.isLoading}
                                                startIcon={<SearchIcon/>}
                                                onClick={() => this.handleOnClickSearch()}
                                                className={classes.button}
                                            >
                                                <span className={classes.buttonText}>{'Buscar'}</span>
                                            </Button>
                                        </div>
                                    </div>
                                    <Divider variant={'fullWidth'}/>
                                    {
                                        this.state.isLoading ? <div style={{height: "450px"}}/> :
                                            (
                                                this.state.chart.data.length > 0 ?
                                                    <GroupedColumnChart
                                                        chartId={"rs-comparative"}
                                                        chart={this.state.chart}
                                                        height={"450px"}
                                                        symbol={symbol}
                                                        semaphore={this.props.semaphore}
                                                        typeCalculation={this.props.typeCalculation}
                                                        settings={this.state.settings}
                                                    /> : <h1 className={"pt-4"}>No hay comparativas configuradas para éste dashboard</h1>
                                            )
                                    }
                                </div>
                            }
                        />
                    </Container>
                </React.Fragment>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={this.state.snackbar.open}
                    autoHideDuration={6000}
                    onClose={this.handleCloseSnackbar}
                >
                    <SnackbarContentWrapper
                        onClose={this.handleCloseSnackbar}
                        variant={this.state.snackbar.type}
                        message={this.state.snackbar.message}
                    />
                </Snackbar>
            </div>
        );
    }

}

function filterLocation(location, value) {
    let filterLocation = location.name.toLowerCase()
    let search = value.toLowerCase();

    return filterLocation.indexOf(search) >= 0;
}

export default withStyles(useStyles)(Comparative);
