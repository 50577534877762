import React from 'react';
import DonutChart from "../common/Chart/Donut";
import {DashboardService} from "../../api/service";
import Constants from "../../common/Constants";

class Result extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            data: [],
            label: {},
            colors: []
        };

    }

    componentDidMount() {
        this.getScorePeriod();
    }

    getScorePeriod = () => {
        let url = "dashboard/score/period";
        let data = {
            dashboardId: this.props.dashboardId,
            periodId: this.props.period.id,
            semaphore: this.props.semaphore,
            appliedFilters: this.props.appliedFilters,
            periodsString: this.props.periodsString,
            decimals: this.props.decimals,
            typeCalculation: this.props.typeCalculation,
            userId: this.props.userId,
            locationsStr: this.props.locationsStr
        };
        DashboardService.post(url, data)
            .then(response => {
                let symbol = this.props.typeCalculation === Constants.TYPE_CALCULATION_DECIMAL ? "" : "%";
                let maxValue = this.props.semaphore[this.props.semaphore.length - 1].max;
                maxValue = this.props.typeCalculation === Constants.TYPE_CALCULATION_DECIMAL ? maxValue: maxValue * 100;
                let visible = response.body.data.visible;
                let text = visible ? response.body.data.score + symbol : '-';
                let color = visible ? response.body.data.color : '#dddddd';
                this.setState(
                    {
                        label: {
                            text: text,
                            fontSize: 20
                        },
                        data: [
                            {
                                "category": this.props.period.name,
                                "value": response.body.data.score
                            },
                            {
                                "category": "",
                                "value": maxValue - response.body.data.score
                            }
                        ],
                        colors: [color, color]
                    }
                );
                if (this.props.toDisable) {
                    this.props.handleDisableLoader();
                }
            })
            .catch(error => console.log(error))
            .finally(() => this.setState({isLoading: false}))
    };

    render() {
        return (
            this.state.isLoading ? "" :
                <DonutChart
                    chartId={this.props.chartId}
                    data={this.state.data}
                    label={this.state.label}
                    colors={this.state.colors}
                    tooltipText={""}
                    height={"250px"}
                    title={this.props.period.year + " | " + this.props.period.name}
                    isPrinting={this.props.isPrinting}
                />

        );
    }
}

export default Result
