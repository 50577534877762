import React from 'react';
import AddComparative from "../components/Settings/Comparative/Add";
import * as _ from "lodash";
import {DashboardService, SettingsService} from "../api/service";
import Constants from "../common/Constants";
import {createMuiTheme, MuiThemeProvider} from "@material-ui/core";
import LoaderFullscreen from "../components/common/loader/Fullscreen";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIosOutlinedIcon from "@material-ui/icons/ArrowBackIosOutlined";
import Typography from "@material-ui/core/Typography";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContentWrapper from "../components/common/SnackBar";
import FullScreenDialog from "../components/common/Dialog/FullScreen";
import {withStyles} from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import SettingsIcon from '@material-ui/icons/Settings';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import GeneralSettings from "../components/Settings/General";
import Dimensions from "../components/Settings/Dimensions";
import ComparativeList from "../components/Settings/Comparative/List";

const drawerWidth = 240;

const useStyles = theme => ({
    primary: {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main
    },
    grow: {
        flexGrow: 1,
    },
    title: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },

    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    root: {
        display: 'flex',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerContainer: {
        overflow: 'auto',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        backgroundColor: '#fff'
    }
});

class Settings extends React.Component {
    constructor(props) {
        super(props);
        let dashboardId = atob(this.props.match.params.dashboardId);
        let userId = atob(this.props.match.params.userId);
        this.state = {
            isLoading: true,
            dashboardId: dashboardId,
            userId: userId,
            originalTitle: '',
            title: '',
            theme: {
                primary: '#000000',
                font: '#fff'
            },
            originalTheme: {
                primary: '#000000',
                font: '#fff'
            },
            logo: {
                visible: false,
                url: ''
            },
            snackbar: {
                open: false,
                type: 'error',
                message: ''
            },
            fullScreenDialog: {
                open: false,
                title: '',
                content: null
            },
            callbackOnClose: function () {
            },
            content: null,
            tabs: {
                general: true,
                dimension: false,
                comparative: false
            },
            json: {},
            surveys: [],
            original: []
        };
    }

    // <editor-fold defaultstate="collapsed" desc="Snackbar">
    handleCloseSnackbar = () => {
        this.setState({
            snackbar: {
                open: false,
                type: 'error',
                message: ''
            }
        });
    };

    handleOpenSnackbar = (message, type) => {
        this.setState({
            snackbar: {
                open: true,
                type: type,
                message: message
            }
        });
    };

    // </editor-fold>

    // <editor-fold defaultstate="collapsed" desc="Dialog fullscreen">
    handleOpenFullScreenDialog = (type, getComparativeList, item) => {
        let title;
        let content;

        this.setState({
            callbackOnClose: getComparativeList
        });

        switch (type) {
            case 1:
                title = this.state.title;
                content = <AddComparative
                    cardTitle={'Nueva comparativa'}
                    comparativeName={''}
                    periodMaster={''}
                    dashboardSlave={''}
                    periodSlave={''}
                    selectedDashboard={0}
                    selectedPeriodMaster={0}
                    selectedPeriodSlave={0}
                    userId={this.state.userId}
                    dashboardId={this.state.dashboardId}
                    defaultDashboard={this.state.title}
                    handleOpenSnackbar={this.handleOpenSnackbar}
                    isUpdate={false}
                    comparativeId={0}
                    defaultPeriodMaster={''}
                    defaultPeriodSlave={''}
                />;
                break;
            case 2:
                let periodMaster = '';
                let dashboardSlave = '';
                let periodSlave = '';
                let selectedDashboard = 0;
                let selectedPeriodMaster = 0;
                let selectedPeriodSlave = 0;
                let periods = _.orderBy(item.periods, 'periodId', 'asc');
                for (let i = 0; i < periods.length; i++) {
                    let dashboardId = periods[i].dashboardId;
                    if (dashboardId === parseInt(this.state.dashboardId) && periodMaster === '') {
                        periodMaster = periods[i].periodName;
                        selectedPeriodMaster = periods[i].periodId;
                    } else {
                        dashboardSlave = periods[i].dashboardName;
                        selectedDashboard = periods[i].dashboardId;
                        periodSlave = periods[i].periodName;
                        selectedPeriodSlave = periods[i].periodId;
                    }
                }
                title = this.state.title;
                content = <AddComparative
                    cardTitle={'Editar comparativa'}
                    comparativeName={item.comparativeName}
                    periodMaster={periodMaster}
                    dashboardSlave={dashboardSlave}
                    periodSlave={periodSlave}
                    selectedDashboard={selectedDashboard}
                    selectedPeriodMaster={selectedPeriodMaster}
                    selectedPeriodSlave={selectedPeriodSlave}
                    userId={this.state.userId}
                    dashboardId={this.state.dashboardId}
                    defaultDashboard={this.state.title}
                    handleOpenSnackbar={this.handleOpenSnackbar}
                    isUpdate={true}
                    comparativeId={item.comparativeId}
                    defaultPeriodMaster={selectedPeriodMaster}
                    defaultPeriodSlave={selectedPeriodSlave}
                />;
                break;
            default:
                title = 'ST';
                content = null;
                break;
        }
        this.setState({
            fullScreenDialog: {
                open: true,
                title: title,
                content: content
            }
        });
    };

    handleCloseFullScreenDialog = () => {
        this.setState({
            fullScreenDialog: {
                open: false,
                title: '',
                content: null,
                isQuestionnaire: false
            }
        });
        this.state.callbackOnClose();
    };

    openFullScreenDialog = (title, content) => {
        this.setState({
            fullScreenDialog: {
                open: true,
                title: title,
                content: content
            }
        });
    };

    // </editor-fold>

    // <editor-fold defaultstate="collapsed" desc="Data comparatives">
    getComparatives = () => {
        let url = "/dashboard/data";
        let data = {
            dashboardId: this.state.dashboardId
        };
        DashboardService.post(url, data)
            .then(response => {
                let code = response.status;
                if (code === 200) {
                    let conf;
                    if (response.body.data.conf.body === undefined) {
                        conf = JSON.parse(JSON.parse(JSON.stringify(response.body.data.conf)));
                    } else {
                        conf = response.body.data.conf;
                    }

                    this.setState({
                        title: response.body.data.name,
                        theme: {
                            primary: conf.body.colors.table_header,
                            font: conf.body.colors.table_font_header
                        },
                        logo: conf.header.logo
                    });
                } else {
                    this.handleOpenSnackbar(response.message, Constants.SNACKBAR_ERROR_TYPE);
                }

            })
            .catch(error => console.log(error))
            .finally(() => this.setState({isLoading: false}))
    };

    // </editor-fold>

    getDashboardData = () => {
        let url = "/dashboard-settings/general";
        let data = {
            dashboardId: this.state.dashboardId
        };
        SettingsService.post(url, data)
            .then(response => {
                let code = response.status;
                if (code === 200) {
                    let dashboard = response.body.data;
                    let primary = dashboard.json.body.colors.table_header;
                    let font = dashboard.json.body.colors.table_font_header;
                    let logo = dashboard.json.header.logo;
                    let surveys = dashboard.surveys;
                    this.setState({
                        title: dashboard.name,
                        originalTitle: dashboard.name,
                        theme: {
                            primary: primary,
                            font: font
                        },
                        originalTheme: {
                            primary: primary,
                            font: font
                        },
                        logo: logo,
                        json: dashboard.json,
                        surveys: surveys
                    }, () => {
                        setTimeout(() => {
                            this.handleChangeContent('general');
                        }, 100);
                    });

                } else {
                    this.handleOpenSnackbar(response.message, Constants.SNACKBAR_ERROR_TYPE);
                }

            })
            .catch(error => console.log(error))
            .finally(() => {
                setTimeout(() => {
                    this.setState({isLoading: false})
                }, 100);
            })
    };

    handleChangeContent = type => {
        this.setState({
            tabs: {
                [type]: true
            }
        });
        let content;
        switch (type) {
            case 'general':
                content = <GeneralSettings
                    dashboardId={this.state.dashboardId}
                    dashboardName={this.state.title}
                    json={this.state.json}
                    surveys={this.state.surveys}
                    originalTitle={this.state.originalTitle}
                    originalTheme={this.state.originalTheme}
                    handleChangeTheme={this.handleChangeTheme}
                    handleChangeTitle={this.handleChangeTitle}
                    handleOpenSnackbar={this.handleOpenSnackbar}
                    openFullScreenDialog={this.openFullScreenDialog}
                    handleCloseFullScreenDialog={this.handleCloseFullScreenDialog}
                    handleLoading={this.handleLoading}
                    userId={this.state.userId}
                    getDashboardData={this.getDashboardData}
                />;
                break;
            case 'dimension':
                content = <Dimensions
                    dashboardId={this.state.dashboardId}
                    userId={this.state.userId}
                    handleOpenSnackbar={this.handleOpenSnackbar}
                />
                break;
            case 'comparative':
                content = <ComparativeList
                    dashboardId={this.state.dashboardId}
                    userId={this.state.userId}
                    handleOpenSnackbar={this.handleOpenSnackbar}
                    handleOpenFullScreenDialog={this.handleOpenFullScreenDialog}
                />
                break;
            default:
                content = <div>Componente desconocido</div>
                break;
        }
        this.setState({
            content
        });
    };

    componentDidMount() {
        this.getDashboardData();
    }

    handleChangeTheme = (type, color) => {
        if (type === 1) {
            this.setState(prevState => {
                return {
                    theme: {
                        primary: color,
                        font: prevState.theme.font
                    }
                }
            });

        } else {
            this.setState(prevState => {
                return {
                    theme: {
                        primary: prevState.theme.primary,
                        font: color
                    }
                }
            });
        }
    };

    handleChangeTitle = (name) => {
        this.setState({
            title: name
        });
    };

    handleLoading = (isLoading) => {
        this.setState({
            isLoading: isLoading
        });
    };

    render() {
        const {classes} = this.props;
        const theme = createMuiTheme({
            palette: {
                primary: {
                    main: this.state.theme.primary,
                    contrastText: this.state.theme.font
                }
            }
        });
        return (
            <MuiThemeProvider theme={theme}>
                <LoaderFullscreen active={this.state.isLoading}
                                  text={"Cargando..."}
                                  child={
                                      <div className={classes.root}>
                                          <CssBaseline/>
                                          <AppBar position="fixed" color={"primary"} className={classes.appBar}>
                                              <Toolbar>
                                                  <div>
                                                      <div className={"float-left"}
                                                           style={{
                                                               height: '64px',
                                                               paddingTop: '7px',
                                                               position: 'absolute'
                                                           }}>
                                                          <Tooltip title={"Regresar"}>
                                                              <IconButton aria-label="regresar"
                                                                          href={process.env.REACT_APP_ADMIN_URL + '/DashboardFranquiciasGuardado.jsp'}
                                                                          target={'_self'}>
                                                                  <ArrowBackIosOutlinedIcon/>
                                                              </IconButton>
                                                          </Tooltip>
                                                      </div>
                                                      <div style={{
                                                          borderRadius: '0px 0px 60px 0px',
                                                          backgroundColor: '#fff',
                                                          width: '260px',
                                                          height: '64px',
                                                          marginTop: '-1px',
                                                          marginRight: '15px'
                                                      }} className={"text-center"}>
                                                          {
                                                              this.state.logo.visible ?
                                                                  <div color="inherit" aria-label="Logo"
                                                                       style={{marginTop: '-13px !important'}}>
                                                                      <img style={{width: "auto", height: "64px"}}
                                                                           src={this.state.logo.url}
                                                                           alt={"Logo"}/>
                                                                  </div>
                                                                  : ""
                                                          }
                                                      </div>
                                                  </div>

                                                  <div>
                                                      <Typography variant="h6" noWrap component={'div'}>
                                                          {this.state.title}
                                                      </Typography>
                                                  </div>
                                                  <div className={classes.grow}/>
                                                  <div className={classes.sectionDesktop}>
                                                  </div>
                                              </Toolbar>
                                          </AppBar>
                                          <Drawer
                                              className={classes.drawer}
                                              variant="permanent"
                                              classes={{
                                                  paper: classes.drawerPaper,
                                              }}
                                          >
                                              <Toolbar/>
                                              <div className={classes.drawerContainer}>
                                                  <List>
                                                      <ListItem button key={'general'}
                                                                selected={this.state.tabs.general}
                                                                onClick={() => this.handleChangeContent('general')}>
                                                          <ListItemIcon>
                                                              <SettingsIcon color={"primary"}/>
                                                          </ListItemIcon>
                                                          <ListItemText primary={'General'}/>
                                                      </ListItem>
                                                      <ListItem button key={'dimension'}
                                                                selected={this.state.tabs.dimension}
                                                                onClick={() => this.handleChangeContent('dimension')}>
                                                          <ListItemIcon>
                                                              <FormatListNumberedIcon color={"primary"}/>
                                                          </ListItemIcon>
                                                          <ListItemText primary={'Dimensiones'}/>
                                                      </ListItem>
                                                      <ListItem button key={'comparative'}
                                                                selected={this.state.tabs.comparative}
                                                                onClick={() => this.handleChangeContent('comparative')}>
                                                          <ListItemIcon>
                                                              <CompareArrowsIcon color={"primary"}/>
                                                          </ListItemIcon>
                                                          <ListItemText primary={'Comparativas'}/>
                                                      </ListItem>
                                                  </List>
                                              </div>
                                          </Drawer>
                                          <main className={classes.content}>
                                              <Toolbar/>
                                              <div>
                                                  {
                                                      this.state.content
                                                  }
                                              </div>
                                          </main>

                                          <Snackbar
                                              anchorOrigin={{
                                                  vertical: 'bottom',
                                                  horizontal: 'center',
                                              }}
                                              open={this.state.snackbar.open}
                                              autoHideDuration={6000}
                                              onClose={this.handleCloseSnackbar}
                                          >
                                              <SnackbarContentWrapper
                                                  onClose={this.handleCloseSnackbar}
                                                  variant={this.state.snackbar.type}
                                                  message={this.state.snackbar.message}
                                              />
                                          </Snackbar>

                                          <FullScreenDialog open={this.state.fullScreenDialog.open}
                                                            title={this.state.fullScreenDialog.title}
                                                            appliedFilters={[]}
                                                            colorFont={this.props.colorFont}
                                                            isQuestionnaire={false}
                                                            isSaveButtonshow={false}
                                                            handleCloseFullScreenDialog={this.handleCloseFullScreenDialog}
                                          >
                                              {this.state.fullScreenDialog.content}
                                          </FullScreenDialog>
                                      </div>
                                  }
                />
            </MuiThemeProvider>
        );
    }
}

export default withStyles(useStyles)(Settings);
