import React from 'react';
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import ExposureIcon from '@material-ui/icons/Exposure';
import HeaderSection from "../../common/HeaderSection";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Constants from "../../../common/Constants";


export default function Metrics({metric, handleChangeType, handleChangeDecimals}) {
    return (
        <div className={'row'}>
            <div className={'col-md-12'}>
                <HeaderSection icon={<ExposureIcon/>}
                               title={'Métrica'}
                               subtitle={'Tipo de métrica para realizar cálculo en tu dashboard'}/>
                <div className={'row pb-4'}>
                    <div className={'col-md-6'}>
                        <FormControl variant="outlined" fullWidth={true} size={"small"} required={true}>
                            <InputLabel id="select-type">Tipo de cálculo</InputLabel>
                            <Select
                                labelId="select-type"
                                id="select-type-cbo"
                                value={metric.type}
                                label="Tipo de cálculo"
                                onChange={handleChangeType}
                            >
                                {
                                    Constants.TYPES_CALCULATION.map(type => {
                                        return (
                                            <MenuItem value={type.value} key={'mi-' + type.value}>{type.name}</MenuItem>
                                        );
                                    })
                                }
                            </Select>
                        </FormControl>
                    </div>
                    <div className={'col-md-6'}>
                        <TextField
                            required
                            id="decimals"
                            label="Decimales"
                            variant="outlined"
                            value={metric.decimals}
                            fullWidth={true}
                            size={"small"}
                            type={'number'}
                            onChange={event => handleChangeDecimals(event)}
                            InputProps={{ inputProps: { min: 0, max: 3 } }}
                            helperText="El número máximo de decimales permitido es 3"
                        />
                    </div>
                </div>

            </div>
        </div>
    );
}