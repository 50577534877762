import React from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import Util from "../../../../common/Util";
import Constants from "../../../../common/Constants";

am4core.useTheme(am4themes_animated);

class ColumnChart extends React.Component {

    componentDidMount() {
        let chart = am4core.create("column-chart-div-" + this.props.chartId, am4charts.XYChart);
        chart.responsive.enabled = true;
        chart.responsive.useDefault = false;
        // Add data
        chart.data = this.props.chart.data;

        //add chart cursor
        chart.cursor = new am4charts.XYCursor();
        chart.cursor.behavior = "zoomX";

        chart.scrollbarX = new am4core.Scrollbar();

        // Create axes
        let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = this.props.chart.category;
        categoryAxis.renderer.minGridDistance = 20;
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.labels.template.fontSize = 9;
        categoryAxis.renderer.labels.template.adapter.add("dy", function (dy, target) {
            if (target.dataItem && target.dataItem.index & 2 == 2) {
                return dy + 25;
            }
            return dy;
        });

        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.min = 0;
        valueAxis.max = 110;
        valueAxis.renderer.minGridDistance = 10;
        valueAxis.strictMinMax = true;

        // Create series
        let semaphore = this.props.semaphore;
        let typeCalculation = this.props.typeCalculation;
        this.props.chart.series.map(serie => {
            let value = serie.name;
            let series = chart.series.push(new am4charts.ColumnSeries());
            series.dataFields.valueY = value;
            series.dataFields.categoryX = this.props.chart.category;
            series.columns.template.tooltipText = '[font-size: 10px;]' + value + '[/]';
            series.tooltip.pointerOrientation = "down";
            series.columns.template.fillOpacity = .9;
            series.columns.template.strokeOpacity = 0;
            series.columns.template.events.once("inited", function (event) {
                event.target.fill = Util.getColorFromSemaphore(event.target.dataItem.valueY, semaphore, typeCalculation);
            });
            //series.filters.push(new am4core.DropShadowFilter());

            let labelBullet = series.bullets.push(new am4charts.LabelBullet());
            labelBullet.label.text = "{valueY}" + this.props.symbol;
            labelBullet.label.fontSize = 9;
            labelBullet.label.dx = 8;
            labelBullet.label.truncate = false;
            labelBullet.label.hideOversized = false;
            labelBullet.label.verticalCenter = "bottom";
        });

        chart.responsive.rules.push({
            relevant: function (target) {
                return target.pixelWidth <= 966;
            },
            state: function (target, stateId) {
                if (target instanceof am4charts.Chart) {
                    if (target.pixelWidth <= Constants.RESPONSIVE_SIZE) {
                        categoryAxis.zoomToIndexes(0, 1);
                    }
                    let state = target.states.create(stateId);
                    state.properties.paddingTop = 0;
                    state.properties.paddingRight = 15;
                    state.properties.paddingLeft = 15;
                    return state;
                }

                if (target instanceof am4core.Scrollbar) {
                    let state = target.states.create(stateId);
                    state.properties.marginBottom = 5;
                    return state;
                }

                if (target instanceof am4charts.AxisRendererY) {
                    let state = target.states.create(stateId);
                    state.properties.inside = true;
                    state.properties.maxLabelPosition = 0.99;
                    return state;
                }

                if ((target instanceof am4charts.AxisLabel) && (target.parent instanceof am4charts.AxisRendererY)) {
                    let state = target.states.create(stateId);
                    state.properties.dy = -15;
                    state.properties.paddingTop = 3;
                    state.properties.paddingRight = 5;
                    state.properties.paddingBottom = 3;
                    state.properties.paddingLeft = 5;

                    // Create a separate state for background
                    target.setStateOnChildren = true;
                    let bgstate = target.background.states.create(stateId);
                    bgstate.properties.fill = am4core.color("#fff");
                    bgstate.properties.fillOpacity = 0.7;

                    return state;
                }
                return null;
            }
        });
        this.chart = chart;
    }

    componentWillUnmount() {
        if (this.chart) {
            this.chart.dispose();
        }
    }

    render() {
        return (
            <div id={"column-chart-div-" + this.props.chartId} style={{height: this.props.height, width: "100%"}}
                 className={"graph"}/>
        );
    }

}

export default ColumnChart;
