import axios from 'axios';

/****No olvidar url de botón Back de dashboard y settings*****/
const BASE_URL = process.env.REACT_APP_API_URL;

export const API = axios.create({
    baseURL: BASE_URL
});

const BASE_URL_SETTINGS = process.env.REACT_APP_API_SETTINGS_URL;

export const API_SETTINGS = axios.create({
    baseURL: BASE_URL_SETTINGS
});
