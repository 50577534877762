import React from 'react';
import Result from "../../Result";
import SimpleCard from "../../common/SimpleCard";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as _ from "lodash";
import AccessTimeIcon from '@material-ui/icons/AccessTime';

class GeneralResults extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true
        };
    }

    handleDisableLoader = () => {
        this.setState({
            isLoading: false
        });
    };


    render() {
        let totalPeriods = this.props.periods.length;
        let lastModification = this.props.lastModification;
        const LastModificationComponent = () => (
            <span><AccessTimeIcon/>{'   Última modificación: ' + lastModification}</span>
        );
        return (
            <div className={"row"}>
                <div className={"col-md-12"}>
                    <SimpleCard
                        cardHeader={
                            {
                                title: "Resultados",
                                subheader: <LastModificationComponent/>,
                                action: (
                                    <CircularProgress color={"primary"} hidden={!this.state.isLoading}/>
                                )
                            }
                        }
                        cardContent={
                            <div className={"text-center"} style={{minHeight: "250px"}}>
                                {
                                    _.orderBy(this.props.periods, ['id'], ['asc']).map((period, index) => {
                                        return (
                                            <Result
                                                key={"rs" + index}
                                                dashboardId={this.props.dashboardId}
                                                chartId={"rs" + index}
                                                period={period}
                                                semaphore={this.props.semaphore}
                                                toDisable={index === (totalPeriods - 1)}
                                                handleDisableLoader={this.handleDisableLoader}
                                                appliedFilters={this.props.appliedFilters}
                                                periodsString={this.props.periodsString}
                                                decimals={this.props.decimals}
                                                typeCalculation={this.props.typeCalculation}
                                                userId={this.props.userId}
                                                locationsStr={this.props.locationsStr}
                                                isPrinting={this.props.isPrinting}
                                            />
                                        )
                                    })
                                }
                            </div>
                        }
                    />
                </div>
            </div>
        );
    }
}

export default GeneralResults;
