import React from 'react';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import Dashboard from "./containers/Dashboard";
import Settings from "./containers/Settings";
import DashboardList from "./containers/DashboardList";

function App() {
    return (
        <Router>
            <div className="">
                <Switch>
                    <Route path="/dashboard/:dashboardId/:userId/:type" component={Dashboard}/>
                    <Route path="/settings/:dashboardId/:userId" component={Settings}/>
                    <Route path="/dashboard-list/:userId" component={DashboardList}/>
                </Switch>
            </div>
        </Router>
    );
}

export default App;
