import React from 'react';
import Typography from "@material-ui/core/Typography";

class ErrorPage extends React.Component{
    render() {
        return (
            <div className={'row'}>
                <div className={'col-md-12 text-center pt-5'}>
                    <img style={{width: "500px"}} src={'https://elasticbeanstalk-us-east-1-460503004594.s3.amazonaws.com/upaxer/administrator/resources/logoUpaxer.png'}
                         alt={"Error"}/>
                    <Typography className={'pt-4'} variant="h4">{this.props.message}</Typography>
                </div>
            </div>
        );
    }
}

export default ErrorPage;
