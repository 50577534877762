import React from 'react'
import {DashboardService} from "../../api/service";
import CircularProgress from "@material-ui/core/CircularProgress";
import SimpleCard from "../common/SimpleCard";
import Typography from "@material-ui/core/Typography";
import StackedColumnChart from "../common/Chart/StackedColumn";
import * as _ from "lodash";

class Scored extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dimensions: [],
            isLoading: true
        };
    }

    getScored = () => {
        let url = "dashboard/dimensions/scored";
        let data = {
            dashboardId: this.props.dashboardId,
            periods: this.props.periodsString,
            appliedFilters: this.props.appliedFilters,
            userId: this.props.userId,
            locationsStr: this.props.locationsStr
        };
        DashboardService.post(url, data)
            .then(response => {
                this.setState({
                    dimensions: response.body.data
                });
            })
            .catch(error => console.log(error))
            .finally(() => this.setState({isLoading: false}))
    };

    componentDidMount() {
        this.getScored();
    }

    render() {
        return (
            !this.state.isLoading && this.state.dimensions.length === 0 ? '' : (
                <SimpleCard
                    cardHeader={
                        {
                            title: 'Scored',
                            subheader: '',
                            action: (
                                <CircularProgress color={"primary"} hidden={!this.state.isLoading}/>
                            )
                        }
                    }
                    cardContent={
                        this.state.isLoading ? "" :
                            <div>
                                {
                                    this.state.dimensions.map(dimension => {
                                        return (
                                            <div key={'div-p' + dimension.itemId}>
                                                <Typography variant="subtitle2" gutterBottom>
                                                    {dimension.item}
                                                </Typography>
                                                <div>
                                                    <StackedColumnChart
                                                        id={dimension.itemId}
                                                        data={ _.orderBy(dimension.periods, 'periodId', 'asc')}
                                                        height={'350px'}
                                                        colorTheme={this.props.colorTheme}
                                                    />
                                                </div>
                                                <br/>
                                            </div>

                                        );
                                    })
                                }
                            </div>
                    }
                />
            )


        );
    }


}

export default Scored;
