import React from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);

class StackedColumnChart extends React.Component {
    componentDidMount() {
        let chart = am4core.create("stacked-column-chart-div-" + this.props.id, am4charts.XYChart);
        chart.hiddenState.properties.opacity = 0; // this creates initial fade-in
        chart.responsive.enabled = true;
        chart.data = this.props.data;
        // Create axes
        let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "period";
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.minGridDistance = 20;
        categoryAxis.renderer.cellStartLocation = 0.1;
        categoryAxis.renderer.cellEndLocation = 0.9;

        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.min = 0;

        // Create series
        let series;
        let color = this.props.colorTheme;

        function createSeries(field, name, opacity) {
            series = chart.series.push(new am4charts.ColumnSeries());
            series.dataFields.valueY = field;
            series.dataFields.categoryX = "period";
            series.name = name;
            series.stacked = true;
            series.columns.template.tooltipText = "{name}: [bold]{valueY}[/]";
            series.tooltip.background.fillOpacity = opacity;
            series.columns.template.width = am4core.percent(95);
            series.columns.template.events.once("inited", function (event) {
                event.target.fill = color;
                event.target.fillOpacity = opacity;
                event.target.strokeOpacity = opacity;
            });
        }

        createSeries("positive", "Sí", 0.9);
        createSeries("negative", "No", 0.2);
        this.chart = chart;
    }

    componentWillUnmount() {
        if (this.chart) {
            this.chart.dispose();
        }
    }

    render() {
        return (
            <div id={"stacked-column-chart-div-" + this.props.id} style={{height: this.props.height, width: "100%"}}
                 className={"graph"}/>
        );
    }
}

export default StackedColumnChart;
