import React from 'react'
import Dimension from "../Dimension";

class Dimensions extends React.Component {

    render() {
        let dashboardId = this.props.dashboardId;
        let periods = this.props.periods;
        let periodsString = this.props.periodsString;
        let semaphore = this.props.semaphore;
        let appliedFilters = this.props.appliedFilters;
        let decimals = this.props.decimals;
        let typeCalculation = this.props.typeCalculation;
        let userId = this.props.userId;
        let locationsStr = this.props.locationsStr;
        let withWeight = this.props.withWeight;
        let isByLocation = this.props.isByLocation;
        let colorTheme = this.props.colorTheme;
        return (
            <div>
                {
                    this.props.dimensions.map(function (dimension, index) {
                        if (dimension.type !== 4) {
                            return (
                                <Dimension
                                    key={'dimension' + index}
                                    dashboardId={dashboardId}
                                    periods={periods}
                                    periodsString={periodsString}
                                    dimension={dimension}
                                    semaphore={semaphore}
                                    appliedFilters={appliedFilters}
                                    decimals={decimals}
                                    typeCalculation={typeCalculation}
                                    userId={userId}
                                    locationsStr={locationsStr}
                                    withWeight={withWeight}
                                    isByLocation={isByLocation}
                                    colorTheme={colorTheme}
                                />
                            );
                        }
                    })
                }
            </div>

        );
    }
}

export default Dimensions;
