import React from 'react';
import * as _ from "lodash";
import {CircularProgress, List} from "@material-ui/core";
import {SettingsService} from "../../../api/service";
import Constants from "../../../common/Constants";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Typography from "@material-ui/core/Typography";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import IconButton from "@material-ui/core/IconButton";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import EditIcon from '@material-ui/icons/Edit';
import ListItem from "@material-ui/core/ListItem";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import {withStyles} from "@material-ui/core/styles";
import {green} from '@material-ui/core/colors';
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";

const useStyles = theme => ({
    primary: {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main
    },
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    buttonSuccess: {
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        },
    },
    fabProgress: {
        color: green[500],
        position: 'absolute',
        top: -6,
        left: -6,
        zIndex: 1,
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
});

class Dimensions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            dimensions: [],
            openDialogEditItem: false,
            currentDimension: 0,
            currentDimensionName: '',
            currentItem: 0,
            currentItemText: '',
            updating: false,
            openDialogEditDimension: false
        };
    }

    // <editor-fold defaultstate="collapsed" desc="on ready">
    getDashboardDimensions = () => {
        let url = "/dashboard-settings/dimensions";
        let data = {
            dashboardId: this.props.dashboardId
        };
        SettingsService.post(url, data)
            .then(response => {
                let code = response.status;
                if (code === 200) {
                    this.setState({
                        dimensions: response.body.data
                    });

                } else {
                    this.props.handleOpenSnackbar(response.message, Constants.SNACKBAR_ERROR_TYPE);
                }

            })
            .catch(error => console.log(error))
            .finally(() => this.setState({isLoading: false}))
    };

    componentDidMount() {
        this.getDashboardDimensions();
    }

    // </editor-fold>

    // <editor-fold defaultstate="collapsed" desc="edit item">

    handleOnclickEditItem = (dimensionId, dimensionName, itemId, itemName) => {
        this.setState({
            currentDimension: dimensionId,
            currentDimensionName: dimensionName,
            currentItem: itemId,
            currentItemText: itemName,
            openDialogEditItem: true
        });
    };

    handleChangeItemText = (event) => {
        let value = event.target.value;
        this.setState({
            currentItemText: value
        });
    };

    handleCloseDialogEditItem = () => {
        this.setState({
            openDialogEditItem: false,
            currentDimension: 0,
            currentDimensionName: '',
            currentItem: 0,
            currentItemText: ''
        });
    };

    handleUpdateItem = () => {
        if (this.state.currentItemText === '') {
            this.props.handleOpenSnackbar('Ingresa el nombre del rubro', Constants.SNACKBAR_ERROR_TYPE);
        } else {
            this.setState({
                updating: true
            });
            let url = "/dashboard-settings/items/update";
            let data = {
                dashboardId: this.props.dashboardId,
                dimensionId: this.state.currentDimension,
                itemId: this.state.currentItem,
                itemName: this.state.currentItemText,
                modifyUsr: this.props.userId
            };
            SettingsService.post(url, data)
                .then(response => {
                    let code = response.status;
                    console.log(response);
                    if (code === 200) {
                        let dimensions = this.state.dimensions;
                        let dimensionId = this.state.currentDimension;
                        let itemId = this.state.currentItem;
                        let newName = this.state.currentItemText;
                        let items;
                        for (let i = 0; i < dimensions.length; i++) {
                            if (parseInt(dimensions[i].id) === parseInt(dimensionId)) {
                                console.log(dimensions[i].id);
                                console.log(dimensionId);
                                items = dimensions[i].items;
                                for (let x = 0; x < items.length; x++) {
                                    if (parseInt(items[x].id) === parseInt(itemId)) {
                                        dimensions[i].items[x].name = newName;
                                        this.setState({
                                            dimensions: dimensions,
                                            openDialogEditItem: false,
                                            currentDimension: 0,
                                            currentDimensionName: '',
                                            currentItem: 0,
                                            currentItemText: ''
                                        }, () => {
                                            setTimeout(() => {
                                                this.props.handleOpenSnackbar('El rubro se actualizó correctamente', Constants.SNACKBAR_SUCCESS_TYPE)
                                            }, 5);
                                        });
                                        break;
                                    }
                                }
                                break;
                            }
                        }

                    } else {
                        this.props.handleOpenSnackbar(response.message, Constants.SNACKBAR_ERROR_TYPE);
                    }

                })
                .catch(error => console.log(error))
                .finally(() => this.setState({updating: false}))
        }
    };

    // </editor-fold>

    // <editor-fold defaultstate="collapsed" desc="edit dimension">

    handleOpenDialogEditDimension = (dimensionId, dimensionName) => {
        this.setState({
            currentDimension: dimensionId,
            currentDimensionName: dimensionName,
            openDialogEditDimension: true
        });
    };
    handleCloseDialogEditDimension = () => {
        this.setState({
            currentDimension: 0,
            currentDimensionName: '',
            openDialogEditDimension: false
        });
    };

    handleChangeDimensionName = (event) => {
        this.setState({
            currentDimensionName: event.target.value
        });
    };

    handleUpdateDimension = () => {
        if (this.state.currentDimensionName === '') {
            this.props.handleOpenSnackbar('Ingresa el nombre de la dimensión', Constants.SNACKBAR_ERROR_TYPE);
        } else {
            this.setState({
                updating: true
            });
            let url = "/dashboard-settings/dimensions/update";
            let data = {
                dashboardId: this.props.dashboardId,
                dimensionId: this.state.currentDimension,
                dimensionName: this.state.currentDimensionName,
                modifyUsr: this.props.userId
            };
            SettingsService.post(url, data)
                .then(response => {
                    let code = response.status;
                    if (code === 200) {
                        let dimensions = this.state.dimensions;
                        let dimensionId = this.state.currentDimension;
                        let newName = this.state.currentDimensionName;
                        for (let i = 0; i < dimensions.length; i++) {
                            if (parseInt(dimensions[i].id) === parseInt(dimensionId)) {
                                dimensions[i].name = newName;
                                this.setState({
                                    dimensions: dimensions,
                                    openDialogEditDimension: false,
                                    currentDimension: 0,
                                    currentDimensionName: ''
                                }, () => {
                                    setTimeout(() => {
                                        this.props.handleOpenSnackbar('La dimensión se actualizó correctamente', Constants.SNACKBAR_SUCCESS_TYPE)
                                    }, 5);
                                });
                                break;
                            }
                        }

                    } else {
                        this.props.handleOpenSnackbar(response.message, Constants.SNACKBAR_ERROR_TYPE);
                    }

                })
                .catch(error => console.log(error))
                .finally(() => this.setState({updating: false}))
        }
    };

    // </editor-fold>

    render() {
        const {classes} = this.props;
        return (
            <div>
                <div className={"row"}>
                    <div className={'col-md-12'}>
                        <List dense={true}>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar className={classes.primary}>
                                        <FormatListNumberedIcon/>
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary="Dimensiones"
                                    secondary={'Podrás realizar cambio en nombre de dimensiones y rubros'}
                                />

                                <ListItemSecondaryAction>
                                    <CircularProgress color={"primary"} hidden={!this.state.isLoading} size={35}/>
                                </ListItemSecondaryAction>
                            </ListItem>
                        </List>
                    </div>
                </div>
                <div className={'row'}>
                    <div className={'col-md-12'}>
                        {
                            _.orderBy(this.state.dimensions, ['sequence'], ['asc']).map(dimension => {
                                return (
                                    <div className={'row'} key={'row-' + dimension.id}>
                                        <div className={'col-md-10'} key={'colmd10-' + dimension.id}>
                                            <ExpansionPanel key={'ep' + dimension.id}>
                                                <ExpansionPanelSummary
                                                    expandIcon={<ExpandMoreIcon/>}
                                                    aria-controls="panel1a-content"
                                                    id={'eps' + dimension.id}
                                                >
                                                    <Typography> <strong>{dimension.name}</strong></Typography>
                                                </ExpansionPanelSummary>
                                                <ExpansionPanelDetails key={'epd' + dimension.id}>
                                                    <List key={'list-' + dimension.id}>
                                                        {
                                                            _.orderBy(dimension.items, ['sequence'], ['asc']).map(item => {
                                                                return (
                                                                    <div key={'div-lst-' + item.id}
                                                                         className={'row'}>
                                                                        <div key={'div-lst-md12-' + item.id}
                                                                             className={'col-md-12'}>

                                                                            <ListItem
                                                                                key={'listItem-' + item.id}
                                                                                role={undefined}
                                                                                dense button>

                                                                                <ListItemText
                                                                                    id={'listItemText-' + item.id}
                                                                                    primary={"R" + item.sequence + " " + item.name}
                                                                                    secondary={''}
                                                                                />
                                                                                <ListItemSecondaryAction
                                                                                    key={'listItemSecondaryAction' + item.id}>
                                                                                    <IconButton edge="end"
                                                                                                aria-label={'editButton-' + item.id}
                                                                                                onClick={() => this.handleOnclickEditItem(dimension.id, dimension.name, item.id, item.name)}
                                                                                    >
                                                                                        <EditIcon
                                                                                            color={"primary"}/>
                                                                                    </IconButton>

                                                                                </ListItemSecondaryAction>
                                                                            </ListItem>
                                                                        </div>
                                                                    </div>

                                                                );
                                                            })
                                                        }
                                                    </List>
                                                </ExpansionPanelDetails>
                                            </ExpansionPanel>
                                        </div>
                                        <div className={'col-auto'} key={'col-md2-' + dimension.id}>
                                            <IconButton
                                                aria-label={'editButtonDimension-' + dimension.id}
                                                onClick={() => this.handleOpenDialogEditDimension(dimension.id, dimension.name)}
                                            >
                                                <EditIcon color={"primary"}/>
                                            </IconButton>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <Dialog open={this.state.openDialogEditItem} onClose={this.handleCloseDialogEditItem}
                        aria-labelledby="form-dialog-title" maxWidth={"md"} fullWidth={true}>
                    <DialogTitle id="form-dialog-title">Editar rubro</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <strong>Dimensión: </strong>{this.state.currentDimensionName}
                        </DialogContentText>
                        <TextField
                            required
                            id="protocol-txt"
                            name={'item-text'}
                            label="Rubro"
                            variant="outlined"
                            value={this.state.currentItemText}
                            fullWidth={true}
                            size={"small"}
                            onChange={this.handleChangeItemText}
                            multiline={true}
                            rowsMax={4}
                            inputProps={{maxLength: 500}}
                            disabled={this.state.updating}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseDialogEditItem} color="primary" disabled={this.state.updating}>
                            Cancelar
                        </Button>
                        <div className={classes.wrapper}>
                            <Button onClick={this.handleUpdateItem} color="primary" disabled={this.state.updating}>
                                Actualizar
                            </Button>
                            {this.state.updating && <CircularProgress size={30} className={classes.buttonProgress}/>}
                        </div>
                    </DialogActions>
                </Dialog>

                <Dialog open={this.state.openDialogEditDimension} onClose={this.handleCloseDialogEditDimension}
                        aria-labelledby="form-dialog-title" maxWidth={"md"} fullWidth={true}>
                    <DialogTitle id="form-dialog-title">Editar dimensión</DialogTitle>
                    <DialogContent>
                        <TextField
                            required
                            id="dimension-txt"
                            name={'dimension-text'}
                            label="Dimensión"
                            variant="outlined"
                            value={this.state.currentDimensionName}
                            fullWidth={true}
                            size={"small"}
                            onChange={this.handleChangeDimensionName}
                            multiline={true}
                            rowsMax={4}
                            inputProps={{maxLength: 500}}
                            disabled={this.state.updating}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseDialogEditDimension} color="primary"
                                disabled={this.state.updating}>
                            Cancelar
                        </Button>
                        <div className={classes.wrapper}>
                            <Button onClick={this.handleUpdateDimension} color="primary" disabled={this.state.updating}>
                                Actualizar
                            </Button>
                            {this.state.updating && <CircularProgress size={30} className={classes.buttonProgress}/>}
                        </div>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }


}

export default withStyles(useStyles)(Dimensions);