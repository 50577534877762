import React from 'react';
import HeaderSection from "../../common/HeaderSection";
import ColorLensIcon from '@material-ui/icons/ColorLens';
import {TwitterPicker} from 'react-color';
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Typography from "@material-ui/core/Typography";
import ImageUploader from "react-images-upload";
import BrokenImageIcon from '@material-ui/icons/BrokenImage';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Tooltip from "@material-ui/core/Tooltip";
import Collapse from "@material-ui/core/Collapse";

export default function Appearance({
                                       dashboardName, colors, handleChangeColor, logo, logoBase64,
                                       handleChangeDashboardName,
                                       handleRestoreDashboardName,
                                       handleRestoreTheme,
                                       handleChangeFile,
                                       handleChangeSwitchLogo
                                   }) {

    return (
        <section>
            <div className={'row'}>
                <div className={'col-md-12'}>
                    <HeaderSection icon={<ColorLensIcon/>}
                                   title={'Apariencia'}
                                   subtitle={'Configura la personalidad de tu dashboard'}/>
                </div>
            </div>
            <div className={'row'}>
                <div className={'col-md-12'}>
                    <FormControl variant="outlined" size={'small'} fullWidth={true} required={true}>
                        <InputLabel htmlFor="dashboard-name">Nombre</InputLabel>
                        <OutlinedInput
                            id="dashboard-name"
                            type={'text'}
                            value={dashboardName}
                            onChange={handleChangeDashboardName}
                            endAdornment={
                                <InputAdornment position="end">
                                    <Tooltip title="Restablecer" aria-label="restore">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleRestoreDashboardName}
                                            edge="end"
                                        >
                                            {<RotateLeftIcon color={"primary"}/>}
                                        </IconButton>
                                    </Tooltip>
                                </InputAdornment>
                            }
                            labelWidth={70}
                            inputProps={{maxLength: 150}}
                        />
                    </FormControl>
                </div>
            </div>
            <div className={'row'}>
                <div className={'col-md-6'}>
                    <div className={'row'}>
                        <div className={'col-md-12'}>
                            <List dense={true}>
                                <ListItem>
                                    <ListItemText
                                        primary={'Color de primario'}
                                        secondary={'Selecciona el color que se aplicará a tu dashboard'}
                                    />
                                    <ListItemSecondaryAction>
                                        <Tooltip title="Restablecer color principal" aria-label="restore-primary-color">
                                            <IconButton
                                                aria-label="restore-primary-color"
                                                onClick={() => handleRestoreTheme(1)}
                                            >
                                                {<RotateLeftIcon color={"primary"}/>}
                                            </IconButton>
                                        </Tooltip>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            </List>
                        </div>
                    </div>
                    <div className={'row'}>
                        <div className={'col-md-3'}/>
                        <div className={'col-md-6'}>
                            <TwitterPicker color={colors.table_header}
                                           onChangeComplete={color => handleChangeColor(1, color.hex)}
                            />
                        </div>
                        <div className={'col-md-3'}/>
                    </div>
                </div>
                <div className={'col-md-6'}>
                    <div className={'row'}>
                        <div className={'col-md-12'}>
                            <List dense={true}>
                                <ListItem>
                                    <ListItemText
                                        primary={'Color secundario'}
                                        secondary={'Selecciona el color secundario que se aplicará a tu dashboard'}
                                    />
                                    <Tooltip title="Restablecer color secundario" aria-label="restore-font-color">
                                        <IconButton
                                            aria-label="restore-font-color"
                                            onClick={() => handleRestoreTheme(2)}
                                        >
                                            {<RotateLeftIcon color={"primary"}/>}
                                        </IconButton>
                                    </Tooltip>
                                </ListItem>
                            </List>
                        </div>
                    </div>
                    <div className={'row'}>
                        <div className={'col-md-3'}/>
                        <div className={'col-md-6'}>
                            <TwitterPicker color={colors.table_font_header}
                                           onChangeComplete={color => handleChangeColor(2, color.hex)}
                            />
                        </div>
                        <div className={'col-md-3'}/>
                    </div>
                </div>
            </div>

            <div className={'row pt-4'}>
                <div className={'col-md-12'}>
                    <FormControlLabel
                        control={<Switch checked={logo.visible}
                                         color={'primary'}
                                         name={'logoSwitch'}
                                         onChange={handleChangeSwitchLogo}
                        />
                        }
                        label="Logo"
                    />
                </div>
            </div>
            <Collapse in={logo.visible}>
                <div className={'row'}>
                    <div className={'col-md-4 text-center'} style={{borderRight: '2px solid #f7f7f7'}}>
                        <Typography variant="subtitle2" gutterBottom>
                            Actual
                        </Typography>
                        <div>
                            {
                                logo.url === '' ? <BrokenImageIcon fontSize={"large"} style={{paddingTop: '50px'}}/> :
                                    <img style={{height: '100px', width: 'auto'}}
                                         src={logo.url}/>
                            }
                        </div>
                    </div>
                    <div className={'col-md-4'} style={{borderRight: '2px solid #f7f7f7'}}>
                        <ImageUploader
                            withIcon={true}
                            buttonText='Seleccionar logo'
                            onChange={handleChangeFile}
                            imgExtension={['.jpg', '.png']}
                            maxFileSize={5242880}
                            withPreview={false}
                            singleImage={true}
                            label={'Tamaño máximo 5mb'}
                            buttonStyles={{backgroundColor: colors.table_header}}
                            fileSizeError={'El archivo excede el tamaño permitido'}
                            fileTypeError={'Archivo no permitido'}
                            fileContainerStyle={{boxShadow: 'none'}}
                        />
                    </div>
                    <div className={'col-md-4 text-center'}>
                        <Typography variant="subtitle2" gutterBottom>
                            Seleccionado
                        </Typography>
                        <div>
                            {
                                logoBase64 === '' ? <BrokenImageIcon fontSize={"large"} style={{paddingTop: '50px'}}/> :
                                    <img style={{height: '100px', width: 'auto'}}
                                         src={logoBase64}/>
                            }
                        </div>
                    </div>
                </div>
            </Collapse>
        </section>
    )

}
