import React from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import Util from "../../../../common/Util";

am4core.useTheme(am4themes_animated);

class GroupedColumnChart extends React.Component {
    componentDidMount() {
        let htmlElement = "grouped-column-chart-div-" + this.props.chartId;
        let chart = am4core.create(htmlElement, am4charts.XYChart);
        chart.responsive.enabled = true;

// some extra padding for range labels
        chart.paddingBottom = 50;

        chart.cursor = new am4charts.XYCursor();
        chart.cursor.behavior = "zoomX";

// will use this to store colors of the same items

        let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "category";
        categoryAxis.renderer.minGridDistance = 60;
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.dataItems.template.text = "";

        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.tooltip.disabled = true;
        valueAxis.min = 0;
        valueAxis.max = 110;
        valueAxis.strictMinMax = true;

// single column series for all data
        let columnSeries = chart.series.push(new am4charts.ColumnSeries());
        columnSeries.columns.template.width = am4core.percent(80);
        columnSeries.dataFields.categoryX = "category";
        columnSeries.dataFields.valueY = "value";

// second value axis for quantity
        let valueAxis2 = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis2.renderer.opposite = true;
        valueAxis2.syncWithAxis = valueAxis;
        valueAxis2.tooltip.disabled = true;

// fill adapter, here we save color value to colors object so that each time the item has the same name, the same color is used
        let semaphore;
        let typeCalculation;
        let settings = this.props.settings;
        let color;
        columnSeries.columns.template.adapter.add("fill", function (fill, target) {
            let name = target.dataItem.dataContext.realName;
            semaphore = settings[name].body.semaforo;
            typeCalculation = settings[name].metrica.tipo_calculo;
            color = Util.getColorFromSemaphore(target.dataItem.valueY, semaphore, typeCalculation);
            target.stroke = color;
            return color;
        })

        let labelBullet = columnSeries.bullets.push(new am4charts.LabelBullet());
        labelBullet.label.text = "{valueY}" + this.props.symbol;
        labelBullet.label.fontSize = 12;
        labelBullet.label.dx = 8;
        labelBullet.label.truncate = false;
        labelBullet.label.hideOversized = false;
        labelBullet.label.verticalCenter = "bottom";


        let rangeTemplate = categoryAxis.axisRanges.template;
        rangeTemplate.tick.disabled = false;
        rangeTemplate.tick.location = 0;
        rangeTemplate.tick.strokeOpacity = 0.6;
        rangeTemplate.tick.length = 60;
        rangeTemplate.grid.strokeOpacity = 0.5;
        rangeTemplate.label.tooltip = new am4core.Tooltip();
        rangeTemplate.label.tooltip.dy = -10;
        rangeTemplate.label.cloneTooltip = false;

///// DATA
        let chartData = [];
        let realData = {};
        let comparativeName;
        let item;
        for (let i = 0; i < this.props.chart.data.length; i++) {
            comparativeName = this.props.chart.data[i].comparativeName;
            item = this.props.chart.data[i];
            delete item['comparativeName'];
            delete item['comparativeId'];
            realData[comparativeName] = item;
        }

        let data = realData;

// process data ant prepare it for the chart
        for (let providerName in data) {
            let providerData = data[providerName];

            // add data of one provider to temp array
            let tempArray = [];
            // add items
            for (let itemName in providerData) {
                if (itemName !== "quantity") {
                    // we generate unique category for each column (providerName + "_" + itemName) and store realName
                    tempArray.push({
                        category: providerName + "_" + itemName,
                        realName: itemName,
                        value: providerData[itemName],
                        provider: providerName
                    })
                }
            }
            // sort temp array
            tempArray.sort(function (a, b) {
                if (a.value > b.value) {
                    return 1;
                } else if (a.value < b.value) {
                    return -1
                } else {
                    return 0;
                }
            })
            // push to the final data
            am4core.array.each(tempArray, function (item) {
                chartData.push(item);
            })

            // create range (the additional label at the bottom)
            let range = categoryAxis.axisRanges.create();
            range.category = tempArray[0].category;
            range.endCategory = tempArray[tempArray.length - 1].category;
            range.label.text = tempArray[0].provider;
            range.label.dy = 30;
            range.label.truncate = true;
            range.label.fontWeight = "bold";
            range.label.tooltipText = tempArray[0].provider;

            range.label.adapter.add("maxWidth", function (maxWidth, target) {
                let range = target.dataItem;
                let startPosition = categoryAxis.categoryToPosition(range.category, 0);
                let endPosition = categoryAxis.categoryToPosition(range.endCategory, 1);
                let startX = categoryAxis.positionToCoordinate(startPosition);
                let endX = categoryAxis.positionToCoordinate(endPosition);
                return endX - startX;
            })
        }

        chart.data = chartData;


// last tick
        let range = categoryAxis.axisRanges.create();
        range.category = chart.data[chart.data.length - 1].category;
        range.label.disabled = true;
        range.tick.location = 1;
        range.grid.location = 1;

        this.chart = chart;
    }

    componentWillUnmount() {
        if (this.chart) {
            this.chart.dispose();
        }
    }

    render() {
        return (
            <div id={"grouped-column-chart-div-" + this.props.chartId}
                 style={{height: this.props.height, width: "100%"}}
                 className={"graph"}/>
        );
    }

}

export default GroupedColumnChart;
