import React from 'react';
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import {withStyles} from "@material-ui/core/styles";
import LoaderFullscreen from "../components/common/loader/Fullscreen";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {DashboardService} from "../api/service";
import TablePagination from "@material-ui/core/TablePagination";
import IconButton from "@material-ui/core/IconButton";
import VisibilityIcon from '@material-ui/icons/Visibility';
import TextField from "@material-ui/core/TextField";
import * as _ from "lodash";
import {createMuiTheme, MuiThemeProvider} from "@material-ui/core";
import UndoIcon from '@material-ui/icons/Undo';
import HomeIcon from '@material-ui/icons/Home';
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = theme => ({
    primary: {
        color: '#ffffff',
        backgroundColor: '#ff995b'
    },
    appBar: {
        position: 'relative',
    },
    grow: {
        flexGrow: 1,
    },
    title: {
        [theme.breakpoints.up('sm')]: {
            fontSize: '18px',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px'
        },
    },
    menuButton: {
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(2),
    },
});

class DashboardList extends React.Component {
    constructor(props) {
        super(props);
        let userId = atob(this.props.match.params.userId);
        this.state = {
            loading: true,
            originalDashboardList: [],
            dashboardList: [],
            url: '',
            userId: userId,
            page: 0,
            rowsPerPage: 5,
            search: ''
        };
    }

    getDashboardList = () => {
        let url = "dashboard/involved";

        let data = {
            userId: this.state.userId
        };
        DashboardService.post(url, data)
            .then(response => {
                this.setState(
                    {
                        dashboardList: response.body.data.dashboardList,
                        originalDashboardList: response.body.data.dashboardList,
                        url: response.body.data.url
                    }
                );
            })
            .catch(error => console.log(error))
            .finally(() => this.setState({loading: false}))
    };

    componentDidMount() {
        this.getDashboardList();
    }

    handleChangePage = (event, newPage) => {
        this.setState({
            page: newPage
        });
    };

    handleChangeRowsPerPage = (event) => {
        let rows = parseInt(event.target.value, 10);
        this.setState({
            page: 0,
            rowsPerPage: rows
        });
    };

    handleChangeSearch = (event) => {
        let value = event.target.value;
        let original = this.state.originalDashboardList;
        if (value === '') {
            this.setState({
                search: value,
                dashboardList: original
            });
        } else {
            let filtered = _.filter(original, function (o) {
                let name = o.name.toLowerCase();
                return name.indexOf(value.toLowerCase()) > -1;
            });
            this.setState({
                search: value,
                dashboardList: filtered
            });
        }
    };

    render() {
        const {classes} = this.props;
        const theme = createMuiTheme({
            palette: {
                primary: {
                    main: '#ff995b',
                    contrastText: '#ffffff'
                }
            }
        });
        const userBase64 = this.props.match.params.userId;
        return (
            <MuiThemeProvider theme={theme}>
                <LoaderFullscreen active={this.state.loading}
                                  text={""}
                                  child={
                                      <React.Fragment>
                                          <CssBaseline/>
                                          <Container maxWidth="xl">
                                              <div className={"row"}>
                                                  <div className={"col-md-12"}>
                                                      <AppBar position="fixed" color={"primary"}>
                                                          <Toolbar>
                                                              <Tooltip title="Inicio">
                                                                  <IconButton edge="start"
                                                                              className={classes.menuButton}
                                                                              color="inherit" aria-label="menu"
                                                                              href={'https://administrador.upaxer.com/index.jsp'}
                                                                              target={'_self'}
                                                                  >
                                                                      <HomeIcon/>
                                                                  </IconButton>
                                                              </Tooltip>

                                                              <Typography variant="h6" component={'div'}
                                                                          className={classes.title}>
                                                                  {'Dashboards'}
                                                              </Typography>

                                                              <div className={classes.grow}/>
                                                              <Tooltip title="Versión anterior">
                                                                  <IconButton color="inherit" aria-label="old-version"
                                                                              href={'https://administrador.upaxer.com/DashboardFranquiciasGuardado.jsp'}
                                                                              target={'_self'}
                                                                  >
                                                                      <UndoIcon/>
                                                                  </IconButton>
                                                              </Tooltip>
                                                          </Toolbar>
                                                      </AppBar>
                                                  </div>
                                              </div>
                                              <div className={"row pt-5"}>
                                                  <div className={"col-md-12 pt-4"}>
                                                      <TextField
                                                          id="outlined-buscar"
                                                          label="Buscar"
                                                          variant="outlined"
                                                          fullWidth={true}
                                                          size={"small"}
                                                          value={this.state.search}
                                                          onChange={this.handleChangeSearch}
                                                      />
                                                  </div>
                                              </div>
                                              <div className={"row pt-2"}>
                                                  <div className={"col-md-12"}>
                                                      <TableContainer component={Paper}>
                                                          <Table className={classes.table} size="small"
                                                                 aria-label="a dense table">
                                                              <TableHead>
                                                                  <TableRow>
                                                                      <TableCell>Nombre</TableCell>
                                                                      <TableCell align="center">Ver</TableCell>
                                                                      <TableCell align="center">Ejecutivo</TableCell>
                                                                  </TableRow>
                                                              </TableHead>
                                                              <TableBody>
                                                                  {this.state.dashboardList
                                                                      .slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                                                                      .map((dashboard) => (
                                                                          <TableRow key={dashboard.id}>
                                                                              <TableCell component="th" scope="row">
                                                                                  {dashboard.name}
                                                                              </TableCell>
                                                                              <TableCell align="center">
                                                                                  <IconButton color="primary"
                                                                                              aria-label="view"
                                                                                              href={'/dashboard/' + btoa(dashboard.id + '') + '/' + userBase64 + '/2'}
                                                                                              target={'_self'}
                                                                                  >
                                                                                      <VisibilityIcon/>
                                                                                  </IconButton>
                                                                              </TableCell>
                                                                              <TableCell align="center">
                                                                                  <IconButton color="primary"
                                                                                              aria-label="view"
                                                                                              href={'https://executive-dashboard.upaxer.com/executivedashboard/' + btoa(dashboard.id + '') + '/' + userBase64 + '/2'}
                                                                                              target={'_self'}
                                                                                  >
                                                                                      <VisibilityIcon/>
                                                                                  </IconButton>
                                                                              </TableCell>
                                                                          </TableRow>
                                                                      ))}
                                                              </TableBody>
                                                          </Table>
                                                      </TableContainer>
                                                      <TablePagination
                                                          rowsPerPageOptions={[5, 10, 20, 30]}
                                                          component="div"
                                                          count={this.state.dashboardList.length}
                                                          rowsPerPage={this.state.rowsPerPage}
                                                          page={this.state.page}
                                                          onChangePage={this.handleChangePage}
                                                          onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                                          labelRowsPerPage={'Filas'}
                                                          labelDisplayedRows={({from, to, count}) => `${from}-${to} de ${count}`}
                                                      />
                                                  </div>
                                              </div>
                                              <div className={'float-right p-2'}>
                                                  <label style={{
                                                      fontWeight: '600',
                                                      fontSize: '10px',
                                                      padding: '5px'
                                                  }}>Powered by </label>
                                                  <img style={{width: "100px"}}
                                                       src={'https://elasticbeanstalk-us-east-1-460503004594.s3.amazonaws.com/upaxer/administrator/resources/logoUpaxer.png'}
                                                       alt={"Logo"}/>

                                              </div>
                                          </Container>
                                      </React.Fragment>
                                  }
                />
            </MuiThemeProvider>
        )
    }

}

export default withStyles(useStyles)(DashboardList);