import React from 'react'
import SimpleCard from "../common/SimpleCard";
import {DashboardService} from "../../api/service";
import {Grid, GridColumn as Column, GridDetailRow} from '@progress/kendo-react-grid';
import * as _ from "lodash";
import SimpleColumnChart from "../common/Chart/SimpleColumn";
import Constants from "../../common/Constants";
import CircularProgress from "@material-ui/core/CircularProgress";
import Util from "../../common/Util";
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';

class Dimension extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            title: this.props.dimension.name,
            subheader: this.props.dimension.alias,
            periodsGroupYear: [],
            data: [],
            columns: [],
            screenWidth: null,
            screenHeight: null
        };
    }

    getScoresDimension = () => {
        let url = "dashboard/score/dimension";
        let data = {
            dashboardId: this.props.dashboardId,
            periods: this.props.periodsString,
            dimensionId: this.props.dimension.id,
            dimensionType: this.props.dimension.type,
            semaphore: this.props.semaphore,
            appliedFilters: this.props.appliedFilters,
            decimals: this.props.decimals,
            typeCalculation: this.props.typeCalculation,
            userId: this.props.userId,
            locationsStr: this.props.locationsStr
        };
        DashboardService.post(url, data)
            .then(response => {
                let orderData = _.orderBy(response.body.data, ['itemId'], ['asc']);
                let periodsGroupYear = _.chain(this.props.periods)
                    .groupBy("year")
                    .map((value, key) => ({year: key, periods: value}))
                    .value();
                periodsGroupYear = _.orderBy(periodsGroupYear, ['year'], ['asc']);
                let type = this.props.dimension.type;
                let colorTheme = this.props.colorTheme;
                for (let i = 0; i < orderData.length; i++) {
                    orderData[i]['dimensionType'] = type;
                    orderData[i]['colorTheme'] = colorTheme;
                }
                this.setState({
                    periodsGroupYear: periodsGroupYear,
                    data: orderData
                });
            })
            .catch(error => console.log(error))
            .finally(() => this.setState({isLoading: false}))
    };

    componentDidMount() {
        this.getScoresDimension();
        this.handleResize();
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = () => {
        this.setState({
            screenWidth: window.innerWidth,
            screenHeight: window.innerHeight
        });
    };

    expandChange = (event) => {
        event.dataItem.expanded = !event.dataItem.expanded;
        this.forceUpdate();
    };

    setWidth = () => {
        let screenWidth = this.state.screenWidth;
        return (screenWidth * 70) / 100;
    };

    render() {
        let dimensionType = this.props.dimension.type;
        let symbol = (this.props.typeCalculation === Constants.TYPE_CALCULATION_DECIMAL || dimensionType === 3) ? "" : "%";
        let typeCalculation = this.props.typeCalculation;
        let data = this.state.data;
        let decimals = this.props.decimals;
        let isByLocation = this.props.isByLocation;
        let weights = [];
        let scores = [];
        return (
            !this.state.isLoading && this.state.data.length === Constants.ZERO ? '' :
                <SimpleCard
                    cardHeader={
                        {
                            title: this.state.title,
                            subheader: '',
                            action: (
                                <CircularProgress color={"primary"} hidden={!this.state.isLoading}/>
                            )
                        }
                    }
                    cardContent={
                        this.state.isLoading ? "" :
                            <div>
                                <Grid
                                    data={this.state.data}
                                    detail={ItemChart}
                                    style={{height: "auto"}}
                                    expandField="expanded"
                                    onExpandChange={this.expandChange}
                                >
                                    {
                                        this.props.withWeight && this.state.screenWidth > Constants.RESPONSIVE_SIZE &&
                                        <Column field="weight" title="Ponderación" width="150px"
                                                className={"text-center"}/>
                                    }

                                    {
                                        this.state.screenWidth > Constants.RESPONSIVE_SIZE ?
                                            <Column field="item" title="Criterio de medición"/> :
                                            <Column field="item" title="Criterio de medición" width={this.setWidth()}/>
                                    }

                                    {
                                        this.state.periodsGroupYear.map(function (year) {
                                            return (
                                                <Column title={year.year + ""} key={year.year + ""}>
                                                    {
                                                        _.orderBy(year.periods, ['id'], ['asc']).map(function (period) {
                                                            return (
                                                                <Column cell={
                                                                    props => {
                                                                        let item = props.dataItem;
                                                                        let value = "-";
                                                                        let currentYear = _.find(item.years, function (y) {
                                                                            return Number.parseInt(y.year, 10) === Number.parseInt(year.year, 10);
                                                                        });
                                                                        if (currentYear !== undefined) {
                                                                            let currentPeriod = _.find(currentYear.periods, function (p) {
                                                                                return Number.parseInt(p.id, 10) === Number.parseInt(period.id, 10);
                                                                            });
                                                                            if (currentPeriod !== undefined) {
                                                                                weights.push(item.weight);
                                                                                value = currentPeriod.score;
                                                                                if (value > Constants.ZERO) {
                                                                                    scores.push(item.weight);
                                                                                }
                                                                            }
                                                                        }

                                                                        if (isByLocation && typeCalculation === Constants.TYPE_CALCULATION_AVERAGE) {
                                                                            let icon = value;
                                                                            if (value !== '-') {
                                                                                let numericValue = Number.parseFloat(value);
                                                                                icon = numericValue > Constants.ZERO ?
                                                                                    <CheckIcon color={"primary"}/> :
                                                                                    <ClearIcon color={"secondary"}/>;
                                                                                return (
                                                                                    <td className="k-command-cell text-center">
                                                                                        {icon}
                                                                                    </td>
                                                                                )
                                                                            } else {
                                                                                return (
                                                                                    <td className="k-command-cell text-center">
                                                                                        {value}
                                                                                    </td>
                                                                                )
                                                                            }
                                                                        } else {
                                                                            let finalValue = value === '-' ? value : value + symbol;
                                                                            return (
                                                                                <td className="k-command-cell text-center">
                                                                                    {finalValue}
                                                                                </td>
                                                                            )
                                                                        }

                                                                    }
                                                                }
                                                                        footerCell={
                                                                            () => {
                                                                                let sumScores = 0;
                                                                                let totalScores = 0;
                                                                                _.forEach(data, function (item) {
                                                                                    let currentYear = _.find(item.years, function (y) {
                                                                                        return Number.parseInt(y.year, 10) === Number.parseInt(year.year, 10);
                                                                                    });

                                                                                    if (currentYear !== undefined) {
                                                                                        let currentPeriod = _.find(currentYear.periods, function (p) {
                                                                                            return Number.parseInt(p.id, 10) === Number.parseInt(period.id, 10);
                                                                                        });
                                                                                        if (currentPeriod !== undefined) {
                                                                                            sumScores = sumScores + currentPeriod.score;
                                                                                            totalScores = totalScores + 1;
                                                                                        }
                                                                                    }
                                                                                });
                                                                                if (isByLocation && typeCalculation === Constants.TYPE_CALCULATION_AVERAGE) {
                                                                                    let average;
                                                                                    let possiblePoints = _.sum(weights);
                                                                                    let pointsObtained = _.sum(scores);
                                                                                    average = (pointsObtained * 100) / possiblePoints;
                                                                                    return (
                                                                                        <td className="k-command-cell text-center">
                                                                                            {average.toFixed(decimals) + '' + symbol}
                                                                                        </td>
                                                                                    );
                                                                                } else {
                                                                                    let result = sumScores;
                                                                                    if (typeCalculation !== Constants.TYPE_CALCULATION_DECIMAL && dimensionType !== 3) {
                                                                                        result = totalScores > Constants.ZERO ? sumScores / totalScores : '-';
                                                                                    }

                                                                                    if (result !== '-') {
                                                                                        result = (Math.round(result * 100) / 100).toFixed(decimals) + '' + symbol
                                                                                    }

                                                                                    return (
                                                                                        <td className="k-command-cell text-center">
                                                                                            {result}
                                                                                        </td>
                                                                                    );
                                                                                }
                                                                            }}
                                                                        key={period.id} title={period.name}
                                                                        width="150px" className={"text-center"}
                                                                />
                                                            )
                                                        })
                                                    }
                                                </Column>
                                            )
                                        })
                                    }
                                </Grid>
                            </div>
                    }
                />
        );
    }
}

class ItemChart extends GridDetailRow {

    render() {
        let dataItem = this.props.dataItem;
        let data = [];
        let colors = [];
        let years = _.orderBy(dataItem.years, ['year'], ['asc']);
        let max = Constants.ZERO;
        let dimensionType = dataItem.dimensionType;
        let colorTheme = dataItem.colorTheme;
        for (let i = 0; i < years.length; i++) {
            let periods = years[i].periods;
            periods = _.orderBy(periods, ['id'], ['asc']);
            for (let x = 0; x < periods.length; x++) {
                if (typeof periods[x] === 'object') {
                    if (periods[x].score > max) {
                        max = periods[x].score;
                    }
                    let item = {
                        period: years[i].year + ' | ' + periods[x].name,
                        score: periods[x].score
                    };
                    data.push(item);
                    let color = dimensionType === 3 ? colorTheme : periods[x].color;
                    colors.push(color);
                }
            }
        }
        let chart = {
            data: data,
            category: 'period',
            value: 'score',
            colors: colors
        };
        let symbol = dimensionType === 3 ? '' : Util.getSymbol(dataItem.typeCalculation);
        let limitRange = dimensionType === 3 ? max + 10 : 110;
        return (
            <section>
                <div className={"row"}>
                    <div className={'col-md-2'}/>
                    <div className={'col-md-6'}>
                        <SimpleColumnChart
                            chartId={"rs-item-" + dataItem.itemId}
                            chart={chart}
                            height={"350px"}
                            symbol={symbol}
                            limitRange={limitRange}
                        />
                    </div>
                    <div className={'col-md-2'}/>
                </div>
            </section>
        );
    }
}

export default Dimension;
