import React from 'react'
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {makeStyles} from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import Checkbox from "@material-ui/core/Checkbox";
import Divider from "@material-ui/core/Divider";
import DoneAllIcon from '@material-ui/icons/DoneAll';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    gridList: {
        width: 900,
        height: 445,
    },
    icon: {
        color: 'rgba(255, 255, 255, 0.54)',
    },
    clearButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
    },
    allButton: {
        position: 'absolute',
        right: theme.spacing(7),
        top: theme.spacing(1),
    },
    backdrop: {
        zIndex: 999999999999,
        color: '#fff',
    }
}));

function Zip({isOpenDialogZip, handleCloseDialogZip, items, handleSelectFile, handleCreateZip, handleSelectedAll, handleDeselectAll, success, isLoading, type}) {
    const classes = useStyles();

    return (
        <div>
            <Dialog open={isOpenDialogZip}
                    fullWidth={true}
                    maxWidth={"xl"}
                    onClose={event => event.preventDefault()}
                    aria-labelledby="form-dialog-title" style={{zIndex: 99999}}>
                <DialogTitle id="form-dialog-title">
                    Selecciona los archivos para descargar
                    <IconButton color="primary"
                                className={classes.allButton}
                                onClick={() => handleSelectedAll()}
                                disabled={isLoading}
                                hidden={isLoading}
                    >
                        <DoneAllIcon/>
                    </IconButton>
                    <IconButton color="primary" className={classes.clearButton}
                                onClick={() => handleDeselectAll()}
                                disabled={isLoading}
                                hidden={isLoading}
                    >
                        <ClearAllIcon/>
                    </IconButton>

                    <CircularProgress color="primary" hidden={!isLoading} className={classes.clearButton}/>

                </DialogTitle>
                <Divider variant={"middle"}/>
                <DialogContent>
                    <div className="alert alert-danger" role="alert" hidden={success}>
                        Selecciona al menos un archivo
                    </div>
                    <div className={classes.root}>
                        <GridList cellHeight={180} className={classes.gridList} cols={6}>
                            {items.map((item) => (
                                <GridListTile key={item.id}>
                                    {type === 1 ? <img src={item.url} alt={item.name}/> : <video width="320" height="240" controls>
                                        <source src={item.url}/>
                                    </video>}
                                    <GridListTileBar
                                        title={item.name}
                                        actionIcon={
                                            <Checkbox checked={item.selected} onChange={handleSelectFile}
                                                      name={item.name} value={item.id} color={"primary"}/>
                                        }
                                    />
                                </GridListTile>
                            ))}
                        </GridList>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Divider orientation={"vertical"}/>
                    <Button onClick={handleCloseDialogZip} color="default" disabled={isLoading}>
                        Cancelar
                    </Button>
                    <Button onClick={handleCreateZip} color="primary" disabled={isLoading}>
                        Aceptar
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default Zip;