const Constants = {
    DEFAULT_VALUE_FILTER: '',
    ZERO: 0,
    TYPE_CALCULATION_DECIMAL: 'decimal',
    EMPTY_STRING: '',
    SNACKBAR_ERROR_TYPE: "error",
    SNACKBAR_SUCCESS_TYPE: "success",
    TYPE_CALCULATION_AVERAGE: 'promedio',
    TYPES_CALCULATION: [
        {
            value: 'decimal',
            name: 'Decimal'
        },
        {
            value: 'cumplimiento',
            name: 'Cumplimiento'
        },
        {
            value: 'promedio',
            name: 'Promedio'
        },
        {
            value: 'porcentaje',
            name: 'Porcentaje'
        }
        ],
    RESPONSIVE_SIZE: 1000,
    TOTAL_PERIOD: 9000000000,
    TOTAL_DIMENSION: 5000000001
};

export default Constants;
