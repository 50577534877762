import React from 'react';
import {Grid, GridDetailRow} from "@progress/kendo-react-grid";
import {GridColumn as Column} from "@progress/kendo-react-grid/dist/npm/GridColumn";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText"
import AddIcon from '@material-ui/icons/Add';
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import {withStyles} from "@material-ui/core";
import {DashboardService} from "../../../../api/service";
import CircularProgress from "@material-ui/core/CircularProgress";
import Constants from "../../../../common/Constants";
import { IntlProvider, load, LocalizationProvider, loadMessages } from '@progress/kendo-react-intl';
import esMessages from '../../../../globalization/es.json';
import likelySubtags from 'cldr-core/supplemental/likelySubtags.json';
import currencyData from 'cldr-core/supplemental/currencyData.json';
import weekData from 'cldr-core/supplemental/weekData.json';

import numbers from 'cldr-numbers-full/main/es/numbers.json';
import currencies from 'cldr-numbers-full/main/es/currencies.json';
import caGregorian from 'cldr-dates-full/main/es/ca-gregorian.json';
import dateFields from 'cldr-dates-full/main/es/dateFields.json';
import timeZoneNames from 'cldr-dates-full/main/es/timeZoneNames.json';

load(
    likelySubtags,
    currencyData,
    weekData,
    numbers,
    currencies,
    caGregorian,
    dateFields,
    timeZoneNames
);


loadMessages(esMessages, 'es-ES');

const useStyles = theme => ({
    primary: {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main
    }
});

class ComparativeList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            data: []
        };
    }

    expandChange = (event) => {
        event.dataItem.expanded = !event.dataItem.expanded;
        this.forceUpdate();
    };

    getComparativeList = () => {
        this.setState({
            isLoading: true
        });
        let url = "/dashboard/comparative/list";
        let data = {
            dashboardId: this.props.dashboardId
        };
        DashboardService.post(url, data)
            .then(response => {

                this.setState({
                    data: response.body.data
                });
            })
            .catch(error => console.log(error))
            .finally(() => this.setState({isLoading: false}))
    };

    componentDidMount() {
        this.getComparativeList();
    }

    deleteComparative = (comparativeId) => {
        this.setState({
            isLoading: true
        });
        let url = "/dashboard/comparative/delete";
        let data = {
            comparativeId: comparativeId,
            modifyUser: this.props.userId
        };
        DashboardService.post(url, data)
            .then(response => {
                let code = response.status;
                if (code === 200) {
                    this.getComparativeList();
                    this.props.handleOpenSnackbar('La comparativa fue eliminada correctamente', Constants.SNACKBAR_SUCCESS_TYPE);
                } else {
                    this.props.handleOpenSnackbar(response.message, Constants.SNACKBAR_ERROR_TYPE);
                }
            })
            .catch(error => console.log(error))
            .finally(() => this.setState({isLoading: false}))
    };

    render() {
        const {classes} = this.props;
        return (
            <div>
                <div className={"row"}>
                    <div className={'col-md-12'}>
                        <List dense={true}>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar className={classes.primary}>
                                        <CompareArrowsIcon/>
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary="Comparativas"
                                    secondary={'Lista de comparativas con otros dashboard'}
                                />

                                <ListItemSecondaryAction>
                                    <CircularProgress color={"primary"} hidden={!this.state.isLoading} size={35}/>
                                    <Tooltip title={'Nueva comparativa'} hidden={this.state.isLoading}>
                                        <IconButton edge="end" aria-label="delete"
                                                    color={'primary'}
                                                    onClick={() => this.props.handleOpenFullScreenDialog(1, this.getComparativeList, null)}
                                        >
                                            <AddIcon fontSize={"large"}/>
                                        </IconButton>
                                    </Tooltip>
                                </ListItemSecondaryAction>
                            </ListItem>
                        </List>
                    </div>
                </div>
                <div className={'row'}>
                    <div className={'col-md-12'}>
                        <LocalizationProvider language="es-ES">
                            <IntlProvider locale="es">
                                <Grid
                                    data={this.state.data}
                                    detail={ComparativeDetail}
                                    style={{height: "auto"}}
                                    expandField="expanded"
                                    onExpandChange={this.expandChange}
                                >
                                    <Column field="sequence" title="Número" width="150px" className={"text-center"}/>
                                    <Column field="comparativeName" title="Nombre"/>
                                    <Column cell={
                                        props => {
                                            let item = props.dataItem;
                                            return (
                                                <td className="k-command-cell text-center">
                                                    <IconButton aria-label="edit" color={'primary'}
                                                                onClick={() => this.props.handleOpenFullScreenDialog(2, this.getComparativeList, item)}
                                                    >
                                                        <EditIcon/>
                                                    </IconButton>
                                                </td>
                                            )
                                        }
                                    } title="Editar" width="150px"/>
                                    <Column cell={
                                        props => {
                                            let item = props.dataItem;
                                            return (
                                                <td className="k-command-cell text-center">
                                                    <IconButton aria-label="edit" color={'secondary'}
                                                                onClick={() => {
                                                                    this.deleteComparative(item.comparativeId)
                                                                }}>
                                                        <DeleteIcon/>
                                                    </IconButton>
                                                </td>
                                            )
                                        }
                                    } title="Eliminar" width="150px"/>
                                </Grid>
                            </IntlProvider>
                        </LocalizationProvider>
                    </div>
                </div>

            </div>
        );
    }

}

class ComparativeDetail extends GridDetailRow {

    render() {
        let dataItem = this.props.dataItem;
        let items = dataItem.periods;
        return (
            <section>
                <div className={"row"}>
                    {
                        items.map((item, index) => {
                            return (
                                <div className={'col-md-5'} key={'div-cont-' + index}>
                                    <ListItem key={'element-desc-' + index}>
                                        <ListItemText primary={item.dashboardName} secondary={item.periodName}/>
                                    </ListItem>
                                </div>
                            )
                        })
                    }
                </div>
            </section>
        );
    }

}

export default withStyles(useStyles)(ComparativeList);