import {API, API_SETTINGS} from './config';

export const DashboardService = {
    post: (url, data) => new Promise(
        (resolve, reject) => {
            API.post(url, data)
                .then(
                    res => res.data
                )
                .then(
                    data => resolve(data)
                )
                .catch(
                    err => reject(err)
                )
        }
    ),
    postFile: (url, data) => new Promise(
        (resolve, reject) => {
            API.post(url, data, {responseType: 'blob'})
                .then(
                    res => res.data
                )
                .then(
                    data => resolve(data)
                )
                .catch(
                    err => reject(err)
                )
        }
    )
};

export const SettingsService = {
    post: (url, data) => new Promise(
        (resolve, reject) => {
            API_SETTINGS.post(url, data)
                .then(
                    res => res.data
                )
                .then(
                    data => resolve(data)
                )
                .catch(
                    err => reject(err)
                )
        }
    ), postFile: (url, formData) => new Promise(
        (resolve, reject) => {
            API_SETTINGS.post(url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(
                    res => res.data
                )
                .then(
                    data => resolve(data)
                )
                .catch(
                    err => reject(err)
                )
        }
    )
};

export default {DashboardService, SettingsService};
