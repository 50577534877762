import React from 'react'
import SimpleCard from "../../common/SimpleCard";
import ColumnChart from "../../common/Chart/Column";
import {DashboardService} from "../../../api/service";
import Constants from "../../../common/Constants";
import CircularProgress from "@material-ui/core/CircularProgress";

class ResultsPerStep extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            chart: {
                data: [],
                category: '',
                series: []
            }
        };
    }

    getScoreByStep = () => {
        let url = "dashboard/score/step";
        let data = {
            dashboardId: this.props.dashboardId,
            periods: this.props.periodsString,
            appliedFilters: this.props.appliedFilters,
            decimals: parseInt(this.props.decimals),
            typeCalculation: this.props.typeCalculation,
            userId: this.props.userId,
            locationsStr: this.props.locationsStr
        };
        DashboardService.post(url, data)
            .then(response => {
                this.setState({
                    chart: {
                        data: response.body.data.data,
                        series: response.body.data.periods,
                        category: 'stepName'
                    }
                });
            })
            .catch(error => console.log(error))
            .finally(() => this.setState({isLoading: false}))
    };

    componentDidMount() {
        this.getScoreByStep();
    }

    render() {
        let symbol = this.props.typeCalculation === Constants.TYPE_CALCULATION_DECIMAL ? "" : "%";
        return (
            <SimpleCard
                cardHeader={
                    {
                        title: "Resultado por paso",
                        subheader: "Muestra la calificación por paso de cada período",
                        action: (
                            <CircularProgress color={"primary"} hidden={!this.state.isLoading}/>
                        )
                    }
                }
                cardContent={
                    this.state.isLoading ? <div style={{height: "370px"}}/> :
                        <ColumnChart
                            chartId={"rs-step"}
                            chart={this.state.chart}
                            height={"370px"}
                            symbol={symbol}
                            semaphore={this.props.semaphore}
                            typeCalculation={this.props.typeCalculation}
                            decimals={this.props.decimals}
                            isPrinting={this.props.isPrinting}
                        />
                }
            />
        );
    }
}

export default ResultsPerStep;
