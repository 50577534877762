import React from 'react';
import CssBaseline from "@material-ui/core/CssBaseline/index";
import Container from "@material-ui/core/Container/index";
import GeneralResults from "../components/Results/General";
import TitleBar from "../components/TitleBar";
import SectionFinalScore from "../components/sections/FinalScore";
import {DashboardService} from "../api/service";
import LoaderFullscreen from "../components/common/loader/Fullscreen";
import ResultsPerStep from "../components/Results/Step";
import Progress from "../components/Progress";
import ResultsPerRank from "../components/Results/Rank";
import Dimensions from "../components/Dimensions";
import Filters from "../components/Filters";
import Constants from "../common/Constants";
import RankingGraph from "../components/Ranking/Graph";
import RankingTable from "../components/Ranking/Table";
import {createMuiTheme, MuiThemeProvider} from "@material-ui/core";
import * as _ from "lodash";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContentWrapper from "../components/common/SnackBar";
import ReactToPrint from "react-to-print";
import ErrorPage from "../components/common/ErrorPage";
import Scored from "../components/Scored";
import ScoredByFilter from "../components/ScoreByFilter";

class Dashboard extends React.PureComponent {
    constructor(props) {
        super(props);
        let dashboardId = atob(this.props.match.params.dashboardId);
        let userId = atob(this.props.match.params.userId);
        this.state = {
            dashboardId: dashboardId,
            userId: userId,
            periods: [],
            allPeriods: [],
            periodsString: '',
            settings: {},
            dimensions: [],
            activeLoader: true,
            isByLocation: false,
            openFilters: false,
            appliedFilters: [],
            valuesFilters: [],
            readyValue: true,
            dialogConfirm: {
                open: false,
                title: '',
                description: '',
                textConfirmBtn: '',
                textCancelBtn: ''
            },
            snackbar: {
                open: false,
                type: 'error',
                message: ''
            },
            isPrinting: false,
            activeLocation: 0,
            user: {},
            locationsStr: '',
            lastModification: '',
            isLoadingFilters: true
        };
    }

    // <editor-fold defaultstate="collapsed" desc="Init">
    getSettings = (periods) => {
        let url = "dashboard/settings";

        let data = {
            dashboardId: this.state.dashboardId,
            userId: this.state.userId,
            periods: periods.toString()
        };
        DashboardService.post(url, data)
            .then(response => {
                let periodsArray = [];
                for (let i = 0; i < response.body.data.periods.length; i++) {
                    periodsArray.push(response.body.data.periods[i].id);
                }
                this.setState(
                    {
                        periodsString: periodsArray.toString(),
                        periods: response.body.data.periods,
                        settings: response.body.data.settings,
                        dimensions: response.body.data.dimensions,
                        allPeriods: response.body.data.allPeriods,
                        filterLabels: response.body.data.filterLabels,
                        user: response.body.data.user,
                        locationsStr: response.body.data.locations,
                        lastModification: response.body.data.lastModification
                    }
                );
                if (this.state.valuesFilters.length === Constants.ZERO) {
                    this.handleFiltersReset()
                }
            })
            .catch(error => console.log(error))
            .finally(() => this.setState({activeLoader: false}))
    };

    componentDidMount() {
        this.getSettings([]);
    }

    // </editor-fold>

    // <editor-fold defaultstate="collapsed" desc="Filters">
    handleOpenFilters = () => {
        this.setState({
            openFilters: true
        });
    };

    handleCloseFilters = () => {
        this.setState({
            openFilters: false
        });
    };

    handleApplyFilters = (selectedPeriods, appliedFilters, valuesFilters, locationId) => {
        if (selectedPeriods.length === Constants.ZERO) {
            this.handleOpenSnackbar('Selecciona al menos un período', Constants.SNACKBAR_ERROR_TYPE);
        } else {
            let isByLocation;
            if (valuesFilters.length > 0) {
                isByLocation = valuesFilters[valuesFilters.length - 1].value !== Constants.EMPTY_STRING;
            } else {
                isByLocation = false;
            }
            this.setState({
                activeLoader: true,
                openFilters: false,
                isLoadingFilters: true
            }, () => {
                /* ir por los filtros de los períodos seleccionados*/
                let url = "dashboard/filter";
                let data = {
                    dashboardId: this.state.dashboardId,
                    periods: selectedPeriods.toString(),
                    userId: this.state.userId
                };
                DashboardService.post(url, data)
                    .then(response => {
                        let filters = response.body.data;
                        for (let i = 0; i < appliedFilters.length; i++) {
                            let fieldName = 'filter' + appliedFilters[i].id;
                            let value = appliedFilters[i].value;
                            filters = _.filter(filters, function (o) {
                                return o[fieldName] === value;
                            });
                        }
                        let locationsStr = '';
                        _.forEach(filters, function (filtered) {
                            locationsStr = locationsStr + filtered.location + ','
                        });
                        let periods = [];
                        selectedPeriods.map(id => {
                            let period = _.find(this.state.allPeriods, function (o) {
                                return o.id === id;
                            });
                            periods.push(period)
                        });
                        this.setState({
                            isByLocation: isByLocation,
                            activeLocation: locationId,
                            periods: periods,
                            periodsString: selectedPeriods.toString(),
                            appliedFilters: appliedFilters,
                            valuesFilters: valuesFilters,
                            activeLoader: false,
                            locationsStr: locationsStr,
                        });
                    })
                    .catch(error => console.log(error))
                    .finally(() => this.setState({activeLoader: false}))
                /**/
            });
        }
    };

    handleFiltersReset = () => {
        let valuesFilters = [];
        let labels = this.state.filterLabels;
        for (let y = Constants.ZERO; y < labels.length; y++) {
            let filterValue = {
                id: labels[y].sequence,
                value: Constants.DEFAULT_VALUE_FILTER
            };
            valuesFilters.push(filterValue);
        }
        this.setState({
            valuesFilters: valuesFilters,
            activeLocation: 0,
            isByLocation: false,
            appliedFilters: []
        });

    };

    setValue = (id, value) => {
        let currentValues = this.state.valuesFilters;
        for (let i = 0; i < currentValues.length; i++) {
            if (Number.parseInt(currentValues[i].id) === Number.parseInt(id)) {
                currentValues[i].value = value;
                this.setState({
                    valuesFilters: currentValues
                });
                break;
            }
        }
    };

    // </editor-fold>

    // <editor-fold defaultstate="collapsed" desc="Snackbar">
    handleCloseSnackbar = () => {
        this.setState({
            snackbar: {
                open: false,
                type: 'error',
                message: ''
            }
        });
    };

    handleOpenSnackbar = (message, type) => {
        this.setState({
            snackbar: {
                open: true,
                type: type,
                message: message
            }
        });
    };

    // </editor-fold>

    handlePrinting = () => {
        this.setState({
            isPrinting: true
        }, () => setTimeout(() => {
            document.getElementById("btnToPDF").click()
        }, 2000));
    };

    handleFinishLoadingFilters = () => {
        this.setState({
            isLoadingFilters: false
        });
    };

    render() {
        let colorTheme = '#000000';
        let colorFont = '#fff';
        if (!this.state.isLoading && this.state.settings.colors !== undefined) {
            colorTheme = this.state.settings.colors.table_header;
            colorFont = this.state.settings.colors.table_font_header;
        }

        const defaultTheme = createMuiTheme({
            palette: {
                primary: {
                    main: colorTheme,
                    contrastText: colorFont
                }
            }
        });
        const {breakpoints, typography: {pxToRem}} = defaultTheme;
        const theme = {
            ...defaultTheme,
            overrides: {
                MuiTypography: {
                    h5: {
                        fontSize: "18px",
                        [breakpoints.down("md")]: {
                            fontSize: "14px"
                        }
                    },
                    h6: {
                        fontSize: "15px",
                        [breakpoints.down("md")]: {
                            fontSize: "12px"
                        }
                    },
                    body1: {
                        fontSize: "14px",
                        [breakpoints.down("md")]: {
                            fontSize: "12px"
                        }
                    }
                }
            }
        };
        return (
            <MuiThemeProvider theme={theme}>
                <LoaderFullscreen active={this.state.activeLoader}
                                  text={"Cargando..."}
                                  child={
                                      <div>
                                          {
                                              this.state.activeLoader ? "" : this.state.allPeriods.length === 0 ?
                                                  <ErrorPage message={'Sin información para mostrar'}/> :
                                                  (
                                                      <div>
                                                          <ReactToPrint
                                                              trigger={() => <button id={'btnToPDF'}/>}
                                                              content={() => this.componentRef}
                                                              pageStyle={'@page { size: auto portrait; margin: 5mm; } @media print { body { -webkit-print-color-adjust: exact; padding: 40px !important; } .graph {  page-break-inside: avoid; width: 50%!important;} .row {page-break-after: auto !important; page-break-inside: avoid !important;} .card {page-break-after: auto !important; page-break-inside: avoid !important;} .pagebreak {page-break-after: always;}}'}
                                                              onAfterPrint={() => {
                                                                  this.setState({isPrinting: false});
                                                              }}
                                                          />
                                                          <React.Fragment>
                                                              <CssBaseline/>
                                                              <Container maxWidth="xl"
                                                                         ref={el => (this.componentRef = el)}>
                                                                  <div className={"row"}>
                                                                      <div className={"col-md-12"}>
                                                                          <TitleBar
                                                                              handleOpenFilters={this.handleOpenFilters}
                                                                              dashboardId={this.state.dashboardId}
                                                                              logo={this.state.settings.logo}
                                                                              actions={this.state.settings.actions}
                                                                              appliedFilters={this.state.appliedFilters}
                                                                              colorFont={colorFont}
                                                                              filterLabels={this.state.filterLabels}
                                                                              periods={this.state.periods}
                                                                              semaphore={this.state.settings.semaphore}
                                                                              decimals={this.state.settings.decimals}
                                                                              typeCalculation={this.state.settings.typeCalculation}
                                                                              handleOpenSnackbar={this.handleOpenSnackbar}
                                                                              handlePrinting={this.handlePrinting}
                                                                              userId={this.state.userId}
                                                                              isPrinting={this.state.isPrinting}
                                                                              isByLocation={this.state.isByLocation}
                                                                              locationId={this.state.activeLocation}
                                                                              blacklist={this.state.settings.blacklist}
                                                                              user={this.state.user}
                                                                              locationsStr={this.state.locationsStr}
                                                                              allPeriods={this.state.allPeriods}
                                                                              type={this.props.match.params.type}
                                                                              userBase64={this.props.match.params.userId}
                                                                              isLoadingFilters={this.state.isLoadingFilters}
                                                                          />
                                                                      </div>
                                                                  </div>
                                                                  <div className={"row pt-5"}>
                                                                      <div
                                                                          className={this.state.isPrinting ? "page-letter-size pt-3" : "col-md-12 pt-3"}>
                                                                          <GeneralResults
                                                                              dashboardId={this.state.dashboardId}
                                                                              periods={this.state.periods}
                                                                              periodsString={this.state.periodsString}
                                                                              semaphore={this.state.settings.semaphore}
                                                                              appliedFilters={this.state.appliedFilters}
                                                                              decimals={this.state.settings.decimals}
                                                                              typeCalculation={this.state.settings.typeCalculation}
                                                                              userId={this.state.userId}
                                                                              locationsStr={this.state.locationsStr}
                                                                              lastModification={this.state.lastModification}
                                                                              isPrinting={this.state.isPrinting}
                                                                          />
                                                                      </div>
                                                                  </div>
                                                                  <div className={"row"}>
                                                                      <div
                                                                          className={this.state.isPrinting ? "page-letter-size" : "col-md-6"}>
                                                                          <Progress
                                                                              dashboardId={this.state.dashboardId}
                                                                              periods={this.state.periods}
                                                                              periodsString={this.state.periodsString}
                                                                              userId={this.state.userId}
                                                                              colorTheme={colorTheme}
                                                                              isPrinting={this.state.isPrinting}
                                                                          />
                                                                      </div>
                                                                      <div
                                                                          className={this.state.isPrinting ? "page-letter-size" : "col-md-6"}>
                                                                          <ResultsPerRank
                                                                              dashboardId={this.state.dashboardId}
                                                                              periods={this.state.periods}
                                                                              semaphore={this.state.settings.semaphore}
                                                                              decimals={this.state.settings.decimals}
                                                                              typeCalculation={this.state.settings.typeCalculation}
                                                                              userId={this.state.userId}
                                                                          />
                                                                      </div>
                                                                  </div>
                                                                  <div className={"row"}>
                                                                      <div
                                                                          className={this.state.isPrinting ? "page-letter-size" : "col-md-12"}>
                                                                          <ResultsPerStep
                                                                              dashboardId={this.state.dashboardId}
                                                                              periods={this.state.periods}
                                                                              periodsString={this.state.periodsString}
                                                                              semaphore={this.state.settings.semaphore}
                                                                              appliedFilters={this.state.appliedFilters}
                                                                              decimals={this.state.settings.decimals}
                                                                              typeCalculation={this.state.settings.typeCalculation}
                                                                              userId={this.state.userId}
                                                                              locationsStr={this.state.locationsStr}
                                                                              isPrinting={this.state.isPrinting}
                                                                          />
                                                                      </div>
                                                                  </div>
                                                                  <div>
                                                                      {
                                                                          this.state.isByLocation ? "" :
                                                                              <RankingGraph
                                                                                  dashboardId={this.state.dashboardId}
                                                                                  periods={this.state.periods}
                                                                                  semaphore={this.state.settings.semaphore}
                                                                                  appliedFilters={this.state.appliedFilters}
                                                                                  decimals={this.state.settings.decimals}
                                                                                  typeCalculation={this.state.settings.typeCalculation}
                                                                                  userId={this.state.userId}
                                                                                  locationsStr={this.state.locationsStr}
                                                                                  isPrinting={this.state.isPrinting}
                                                                              />
                                                                      }

                                                                  </div>
                                                                  <div className={"row"}>
                                                                      < div
                                                                          className={this.state.isPrinting ? "page-letter-size" : "col-md-12"}>
                                                                          <Scored
                                                                              dashboardId={this.state.dashboardId}
                                                                              periods={this.state.periods}
                                                                              appliedFilters={this.state.appliedFilters}
                                                                              userId={this.state.userId}
                                                                              periodsString={this.state.periodsString}
                                                                              locationsStr={this.state.locationsStr}
                                                                              colorTheme={colorTheme}
                                                                          />
                                                                      </div>
                                                                  </div>
                                                                  <div className={"row"}>
                                                                      < div className={this.state.isPrinting ? "page-letter-size" : "col-md-12"}>
                                                                          {
                                                                              this.state.settings.footer.showFilterGraphs ? (
                                                                                  <ScoredByFilter
                                                                                      dashboardId={this.state.dashboardId}
                                                                                      periods={this.state.periods}
                                                                                      appliedFilters={this.state.appliedFilters}
                                                                                      userId={this.state.userId}
                                                                                      periodsString={this.state.periodsString}
                                                                                      locationsStr={this.state.locationsStr}
                                                                                      colorTheme={colorTheme}
                                                                                      decimals={this.state.settings.decimals}
                                                                                      typeCalculation={this.state.settings.typeCalculation}
                                                                                      semaphore={this.state.settings.semaphore}
                                                                                  />
                                                                              ) : ''
                                                                          }
                                                                      </div>
                                                                  </div>
                                                                  <div className={"row"}>
                                                                      <div
                                                                          className={this.state.isPrinting ? "page-letter-size" : "col-md-12"}>
                                                                          <Dimensions
                                                                              dashboardId={this.state.dashboardId}
                                                                              periods={this.state.periods}
                                                                              periodsString={this.state.periodsString}
                                                                              dimensions={this.state.dimensions}
                                                                              semaphore={this.state.settings.semaphore}
                                                                              appliedFilters={this.state.appliedFilters}
                                                                              decimals={this.state.settings.decimals}
                                                                              typeCalculation={this.state.settings.typeCalculation}
                                                                              userId={this.state.userId}
                                                                              locationsStr={this.state.locationsStr}
                                                                              withWeight={this.state.settings.withWeight}
                                                                              isByLocation={this.state.isByLocation}
                                                                              colorTheme={colorTheme}
                                                                          />
                                                                      </div>
                                                                  </div>
                                                                  <div className={"row"}>
                                                                      <div
                                                                          className={this.state.isPrinting ? "page-letter-size" : "col-md-12"}>
                                                                          <SectionFinalScore
                                                                              dashboardId={this.state.dashboardId}
                                                                              periods={this.state.periods}
                                                                              periodsString={this.state.periodsString}
                                                                              semaphore={this.state.settings.semaphore}
                                                                              appliedFilters={this.state.appliedFilters}
                                                                              decimals={this.state.settings.decimals}
                                                                              typeCalculation={this.state.settings.typeCalculation}
                                                                              userId={this.state.userId}
                                                                              locationsStr={this.state.locationsStr}
                                                                          />
                                                                      </div>
                                                                  </div>
                                                                  {
                                                                      this.state.settings.footer.ranking ? (
                                                                          <div>
                                                                              <div className={"row"}>
                                                                                  <div className={"col-md-12"}
                                                                                       style={{display: this.state.isPrinting ? 'none' : 'block'}}
                                                                                  >
                                                                                      <RankingTable
                                                                                          dashboardId={this.state.dashboardId}
                                                                                          periods={this.state.periods}
                                                                                          periodsString={this.state.periodsString}
                                                                                          appliedFilters={this.state.appliedFilters}
                                                                                          decimals={this.state.settings.decimals}
                                                                                          typeCalculation={this.state.settings.typeCalculation}
                                                                                          userId={this.state.userId}
                                                                                          locationsStr={this.state.locationsStr}
                                                                                          handleOpenSnackbar={this.handleOpenSnackbar}
                                                                                      />
                                                                                  </div>
                                                                              </div>
                                                                          </div>
                                                                      ) : ''
                                                                  }
                                                                  <div className={"row"}>
                                                                      <div className={"col-md-12"}>
                                                                          <Filters
                                                                              openFilters={this.state.openFilters}
                                                                              dashboardId={this.state.dashboardId}
                                                                              handleCloseFilters={this.handleCloseFilters}
                                                                              periods={this.state.periods}
                                                                              allPeriods={this.state.allPeriods}
                                                                              periodsString={this.state.periodsString}
                                                                              handleApplyFilters={this.handleApplyFilters}
                                                                              filterLabels={this.state.filterLabels}
                                                                              valuesFilters={this.state.valuesFilters}
                                                                              handleFiltersReset={this.handleFiltersReset}
                                                                              setValue={this.setValue}
                                                                              userId={this.state.userId}
                                                                              prevAppliedFilters={this.state.appliedFilters}
                                                                              handleFinishLoadingFilters={this.handleFinishLoadingFilters}
                                                                          />
                                                                      </div>
                                                                  </div>
                                                                  <div className={'float-right p-2'}>
                                                                      <label style={{
                                                                          fontWeight: '600',
                                                                          fontSize: '10px',
                                                                          padding: '5px'
                                                                      }}>Powered by </label>
                                                                      <img style={{width: "100px"}}
                                                                           src={'https://elasticbeanstalk-us-east-1-460503004594.s3.amazonaws.com/upaxer/administrator/resources/logoUpaxer.png'}
                                                                           alt={"Logo"}/>

                                                                  </div>
                                                              </Container>
                                                          </React.Fragment>
                                                      </div>
                                                  )
                                          }
                                      </div>
                                  }
                />
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={this.state.snackbar.open}
                    autoHideDuration={6000}
                    onClose={this.handleCloseSnackbar}
                >
                    <SnackbarContentWrapper
                        onClose={this.handleCloseSnackbar}
                        variant={this.state.snackbar.type}
                        message={this.state.snackbar.message}
                    />
                </Snackbar>
            </MuiThemeProvider>

        );
    }
}

export default Dashboard;
