import React from 'react';
import {Page, Text, View, Document, StyleSheet, Image} from '@react-pdf/renderer';
import LogoUpaxer from '../../img/logoUpaxer.png'

const styles = StyleSheet.create({
    page: {
        padding: 40
    },
    row: {
        width: '100%',
        marginBottom: 15
    },
    survey: {
        fontSize: 12,
        fontWeight: 'bold'
    },
    date: {
        fontSize: 10
    },
    question: {
        fontSize: 12,
        fontWeight: 'bold',
        flexWrap: 'wrap'
    },
    answer: {
        fontSize: 10,
        flexWrap: 'wrap'
    },
    image: {
        width: 100,
        height: 'auto'
    },
    title: {
        fontSize: 14,
        fontWeight: 'bold',
        flexWrap: 'wrap'
    },
    subTitle: {
        fontSize: 12,
        fontWeight: 'bold',
        flexWrap: 'wrap'
    },
    currentDate: {
        position: 'absolute',
        top: 20,
        left: 0,
        right: 0,
        textAlign: 'right',
        fontSize: 8,
        paddingRight: 20
    }
});

function PDFSurvey({dashboardName, locationName, periodName, questionnaires, logoClient}) {
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = today.getFullYear();
    let hh = String(today.getHours()).padStart(2, '0');
    let mms = String(today.getMinutes()).padStart(2, '0');
    let ss = String(today.getSeconds()).padStart(2, '0');
    let currentDate = mm + '/' + dd + '/' + yyyy + ' ' + hh + ':' + mms + ':' + ss;
    return (
        <Document>
            {
                questionnaires.map((survey, index) => {
                    return (
                        <Page size="letter" style={styles.page} key={'page-' + index}>
                            <View style={[styles.row, {flexDirection: 'row', alignItems: 'center'}]} key={'header-' + index}>
                                <View style={{width: '25%'}} key={'header-lc' + index}>
                                    <Image style={styles.image} source={{ uri: logoClient, method: "GET", headers: { "Cache-Control": "no-cache" } }} cache={false}/>
                                </View>
                                <View style={{width: '50%', alignItems: 'center'}} key={'header-title' + index}>
                                    <Text style={styles.title}>{dashboardName}</Text>
                                    <Text style={styles.subTitle}>{locationName}</Text>
                                    <Text style={styles.subTitle}>{periodName}</Text>
                                </View>
                                <View style={{width: '25%', alignItems: 'center'}} key={'header-lu' + index}>
                                    <Image style={styles.image} src={LogoUpaxer}/>
                                </View>
                            </View>
                            <View style={styles.row} key={'survey-' + index}>
                                <Text style={styles.survey}>{survey.name}</Text>
                                <Text style={styles.date}>{survey.fullDate}</Text>
                            </View>
                            {
                                survey.questions.map((question, i) => {
                                    let answer = question.fileName === "" ?
                                        <Text style={styles.answer}>{question.answer}</Text> :
                                        <Image style={styles.image} src={question.answer}/>
                                    return (
                                        <View style={styles.row} wrap={false} key={'question-' + index + '-' + i}>
                                            <Text style={styles.question}>{question.text}</Text>
                                            {answer}
                                        </View>
                                    )
                                })
                            }
                            <Text style={styles.currentDate} fixed>{currentDate}</Text>
                        </Page>
                    )
                })
            }

        </Document>
    )
}

export default PDFSurvey;