import React from 'react';
import * as _ from "lodash";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {withStyles} from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import InputLabel from "@material-ui/core/InputLabel";
import {Combobox, Multiselect} from "react-widgets";
import CircularProgress from "@material-ui/core/CircularProgress";
import SimpleCard from "../../common/SimpleCard";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import AppBar from "@material-ui/core/AppBar";
import "react-image-gallery/styles/css/image-gallery.css";
import "video-react/dist/video-react.css";
import Photographs from "../Photographs";
import Videos from "../Videos";
import {DashboardService} from "../../../api/service";
import SearchIcon from '@material-ui/icons/Search';
import Button from "@material-ui/core/Button";
import Constants from "../../../common/Constants";
import SnackbarContentWrapper from "../../common/SnackBar";
import Snackbar from "@material-ui/core/Snackbar";
import Score from "../Score/Score";

const useStyles = theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper
    },
    primary: {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
    },
    button: {
        margin: theme.spacing(1),
        [theme.breakpoints.down("sm")]: {
            minWidth: 32,
            paddingLeft: 8,
            paddingRight: 8,
            "& .MuiButton-startIcon": {
                margin: 0
            }
        }
    },
    buttonText: {
        [theme.breakpoints.down("sm")]: {
            display: "none"
        }
    }
});

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

function filterLocation(location, value) {
    let filterLocation = location.name.toLowerCase()
    let search = value.toLowerCase();

    return filterLocation.indexOf(search) >= 0;
}

class Grill extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            locations: [],
            selectedLocation: {
                id: 0,
                name: ''
            },
            selectedPeriods: this.props.periods,
            periodId: 0,
            media: [],
            snackbar: {
                open: false,
                type: 'error',
                message: ''
            },
            defaultLocation: this.props.locationName
        }

    }

    getGrillLocations = () => {
        let url = "/dashboard/grill/locations";
        let periods = [];
        _.forEach(this.props.periods, function (period) {
            periods.push(period.id)
        });
        let data = {
            dashboardId: this.props.dashboardId,
            periods: periods.toString(),
            userId: this.props.userId
        };
        DashboardService.post(url, data)
            .then(response => {
                let code = response.status;
                if (code === 200) {
                    let processLocations = [];
                    if (this.props.appliedFilters.length > 0) {
                        let locationsFiltered = this.props.locationsStr.split(',');
                        let locationId;
                        let location;
                        for (let i = 0; i < locationsFiltered.length; i++){
                            locationId = locationsFiltered[i];
                            if (locationId !== ''){
                                locationId = parseInt(locationId);
                                location = _.find(response.body.data, function(obj) {
                                    return obj.id === locationId;
                                });
                                if (location !== undefined){
                                    processLocations.push(location);
                                }
                            }

                        }
                    }else {
                        processLocations = response.body.data;
                    }

                    this.setState({
                        locations: processLocations
                    }, () => setTimeout(() => {
                        if (this.props.isByLocation){
                            let locationName = this.props.locationName;
                            const loc = this.props.activeLocation;
                            let locationObj = _.find(response.body.data, function(obj) {
                                return obj.id === loc;
                            });

                            if (locationObj !== undefined){
                                this.setState({
                                    selectedLocation: locationObj
                                }, () => setTimeout(() => {
                                    let periodId = _.maxBy(this.state.selectedPeriods, 'id').id;
                                    this.setState(
                                        {
                                            media: [],
                                            periodId: periodId
                                        });
                                    this.getFilesByLocation(periodId);
                                }, 50));
                            }
                        }
                    }, 50));
                }

            })
            .catch(error => console.log(error))
            .finally(() => {
                if (!this.props.isByLocation){
                    this.setState({isLoading: false});
                }
            })
    };

    componentDidMount() {
        this.getGrillLocations();
    }

    handleChangePeriods = value => {
        this.setState({
            selectedPeriods: value
        });
    };

    handleChangeLocation = value => {
        this.setState({
            selectedLocation: value
        });
    };

    handleOnClickSearch = () => {
        if (this.state.selectedPeriods.length === 0) {
            this.handleOpenSnackbar('Selecciona al menos un período', Constants.SNACKBAR_ERROR_TYPE)
        } else if (this.state.selectedLocation.id === 0) {
            this.handleOpenSnackbar('Selecciona una ubicación', Constants.SNACKBAR_ERROR_TYPE)
        } else {
            let periodId = _.maxBy(this.state.selectedPeriods, 'id').id;
            this.setState(
                {
                    isLoading: true,
                    media: [],
                    periodId: periodId
                });
            this.getFilesByLocation(periodId);
        }
    };

    // <editor-fold defaultstate="collapsed" desc="Snackbar">
    handleCloseSnackbar = () => {
        this.setState({
            snackbar: {
                open: false,
                type: 'error',
                message: ''
            }
        });
    };

    handleOpenSnackbar = (message, type) => {
        this.setState({
            snackbar: {
                open: true,
                type: type,
                message: message
            }
        });
    };

    // </editor-fold>

    handleChange = (event, newValue) => {
        this.setState({
            isLoading: true,
            media: {
                photos: [],
                videos: []
            },
            periodId: newValue
        });
        this.getFilesByLocation(newValue);
    };

    getFilesByLocation = (periodId) => {
        let url = "/dashboard/location/files";

        let data = {
            dashboardId: this.props.dashboardId,
            periodId: periodId,
            locationId: this.state.selectedLocation.id,
            blacklist: this.props.blacklist.toString(),
            decimals: this.props.decimals,
            typeCalculation: this.props.typeCalculation,
            semaphore: this.props.semaphore
        };
        DashboardService.post(url, data)
            .then(response => {
                let code = response.status;
                if (code === 200) {
                    this.setState({
                        media: response.body.data
                    });
                }

            })
            .catch(error => console.log(error))
            .finally(() => this.setState({isLoading: false}))
    };


    render() {
        const {classes} = this.props;
        let TagPeriod = ({item}) => (
            <span>{item.year + ' | ' + item.name}</span>
        );
        return (
            <div className={"pt-5"}>
                <React.Fragment>
                    <CssBaseline/>
                    <Container fixed maxWidth="xl">
                        <SimpleCard
                            cardHeader={
                                {
                                    title: "Multimedia",
                                    subheader: "Muestra la multimedia por ubicación",
                                    action: (
                                        <CircularProgress color={"primary"} hidden={!this.state.isLoading}/>
                                    )
                                }
                            }
                            cardContent={
                                <div>
                                    <div className={"row"}>
                                        <div className={"col-md-4"}>
                                            <div className={"pb-2"} key={"div-filer-period-location"}>
                                                <InputLabel htmlFor="periods" shrink={true} style={{fontWeight: "bold"}}
                                                            required={true}>Períodos</InputLabel>
                                                <Multiselect
                                                    id={"periods"}
                                                    data={this.props.allPeriods}
                                                    valueField='id'
                                                    textField='name'
                                                    groupBy='year'
                                                    tagComponent={TagPeriod}
                                                    defaultValue={this.props.periods}
                                                    onChange={value => this.handleChangePeriods(value)}
                                                    messages={{
                                                        emptyList: 'Sin opciones para seleccionar'
                                                    }}
                                                    disabled={this.state.isLoading}
                                                />
                                            </div>
                                        </div>
                                        <div className={"col-md-6"}>
                                            <div className={"pb-2"} key={"div-filer-location"}>
                                                <InputLabel htmlFor={"filter-location"} shrink={true}
                                                            style={{fontWeight: "bold"}}
                                                            required={true}>{'Ubicación'}</InputLabel>
                                                <Combobox
                                                    key={"filter-location"}
                                                    data={this.state.locations}
                                                    valueField='id'
                                                    textField='name'
                                                    defaultValue={this.state.defaultLocation}
                                                    caseSensitive={false}
                                                    minLength={3}
                                                    filter={filterLocation}
                                                    disabled={this.state.isLoading}
                                                    messages={{
                                                        emptyList: 'No hay datos'
                                                    }}
                                                    onSelect={value => this.handleChangeLocation(value)}
                                                />
                                            </div>
                                        </div>
                                        <div className={'col-md-2 text-center mt-4 mb-4'}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                className={classes.button}
                                                disabled={this.state.isLoading}
                                                startIcon={<SearchIcon/>}
                                                onClick={() => this.handleOnClickSearch()}
                                            >
                                                <span className={classes.buttonText}>{'Buscar'}</span>
                                            </Button>
                                        </div>
                                    </div>
                                    {
                                        this.state.isLoading || this.state.media.length === 0 ? "" :
                                            (
                                                <div className={classes.root}>
                                                    <AppBar position="static" color={"default"}>
                                                        <Tabs value={this.state.periodId} onChange={this.handleChange}
                                                              aria-label="periods tabs"
                                                              indicatorColor={"primary"}
                                                              textColor={"primary"}
                                                        >
                                                            {
                                                                _.orderBy(this.state.selectedPeriods, ['id'], ['desc']).map(period => {
                                                                    return <Tab
                                                                        key={period.id}
                                                                        value={period.id}
                                                                        label={period.year + ' | ' + period.name}
                                                                        {...a11yProps(period.id)} />
                                                                })
                                                            }
                                                        </Tabs>
                                                    </AppBar>
                                                    {
                                                        _.orderBy(this.state.selectedPeriods, ['id'], ['desc']).map(period => {
                                                            if (period.id === this.state.periodId) {
                                                                return <TabPanel
                                                                    key={'p-' + period.id}
                                                                    value={this.state.periodId}
                                                                    index={this.state.periodId}>
                                                                    <Score
                                                                        score={this.state.media.score}
                                                                        color={this.state.media.color}
                                                                        period={period}
                                                                        semaphore={this.props.semaphore}
                                                                        typeCalculation={this.props.typeCalculation}
                                                                    />
                                                                    <Photographs
                                                                        photos={this.state.media.photos}
                                                                        locationName={this.state.selectedLocation}
                                                                    />
                                                                    <Videos
                                                                        videos={this.state.media.videos}
                                                                        locationName={this.state.selectedLocation}
                                                                    />
                                                                </TabPanel>
                                                            }
                                                        })
                                                    }
                                                </div>
                                            )
                                    }


                                </div>
                            }
                        />
                    </Container>
                </React.Fragment>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={this.state.snackbar.open}
                    autoHideDuration={6000}
                    onClose={this.handleCloseSnackbar}
                >
                    <SnackbarContentWrapper
                        onClose={this.handleCloseSnackbar}
                        variant={this.state.snackbar.type}
                        message={this.state.snackbar.message}
                    />
                </Snackbar>
            </div>
        );
    }
}

export default withStyles(useStyles)(Grill);
