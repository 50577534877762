import React from 'react'
import {DashboardService} from "../../../api/service";
import * as _ from "lodash";
import SimpleCard from "../../common/SimpleCard";
import CircularProgress from '@material-ui/core/CircularProgress';
import {Grid, GridColumn as Column} from "@progress/kendo-react-grid";
import Constants from "../../../common/Constants";
import {IntlProvider, load, LocalizationProvider, loadMessages} from '@progress/kendo-react-intl';
import esMessages from '../../../globalization/es.json';
import likelySubtags from 'cldr-core/supplemental/likelySubtags.json';
import currencyData from 'cldr-core/supplemental/currencyData.json';
import weekData from 'cldr-core/supplemental/weekData.json';

import numbers from 'cldr-numbers-full/main/es/numbers.json';
import currencies from 'cldr-numbers-full/main/es/currencies.json';
import caGregorian from 'cldr-dates-full/main/es/ca-gregorian.json';
import dateFields from 'cldr-dates-full/main/es/dateFields.json';
import timeZoneNames from 'cldr-dates-full/main/es/timeZoneNames.json';
import Fab from "@material-ui/core/Fab";
import {withStyles} from "@material-ui/core";
import DescriptionIcon from '@material-ui/icons/Description';


load(
    likelySubtags,
    currencyData,
    weekData,
    numbers,
    currencies,
    caGregorian,
    dateFields,
    timeZoneNames
);


loadMessages(esMessages, 'es-ES');

const useStyles = theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    fabProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: -5,
        left: -5,
        zIndex: 1,
    }
});

class RankingTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            title: 'Ranking',
            subheader: 'Clasificación por ubicación',
            data: [],
            originalData: [],
            dimensions: [],
            skip: 0,
            take: 20,
            filter: {
                logic: "and",
                filters: [
                    {field: "name", operator: "contains", value: ""}
                ]
            },
            isDownloading: false
        };
    }

    pageChange = (event) => {
        this.setState({
            skip: event.page.skip,
            take: event.page.take
        });
    };

    getDataRankingTable = () => {
        let url = "dashboard/ranking/table";
        let data = {
            dashboardId: this.props.dashboardId,
            periods: this.props.periodsString,
            appliedFilters: this.props.appliedFilters,
            decimals: this.props.decimals,
            typeCalculation: this.props.typeCalculation,
            userId: this.props.userId,
            locationsStr: this.props.locationsStr
        };
        DashboardService.post(url, data)
            .then(response => {
                let dimensions = [];
                _.forEach(response.body.data.dimensions, function (dimension) {
                    if (dimension.type !== 4) {
                        dimensions.push(dimension);
                    }
                });
                this.setState({
                    data: response.body.data.locations,
                    originalData: response.body.data.locations,
                    dimensions: dimensions
                });
            })
            .catch(error => console.log(error))
            .finally(() => this.setState({isLoading: false}))
    };

    componentDidMount() {
        this.getDataRankingTable();
    }

    handleDownloadingExcel = (symbol) => {
        this.setState({
            isDownloading: true
        });
        let url = "dashboard/ranking/report";
        let data = {
            dashboardId: this.props.dashboardId,
            periods: _.orderBy(this.props.periods, ['id'], ['asc']),
            dimensions: _.orderBy(this.state.dimensions, ['sequence'], ['asc']),
            symbol: symbol,
            userId: this.props.userId
        };
        DashboardService.post(url, data)
            .then(response => {
                let code = response.status;
                if (code === 200) {
                    const link = document.createElement('a');
                    link.href = response.body.data.url;
                    link.target = '_blank';
                    link.click();
                    link.remove();
                } else {
                    this.props.handleOpenSnackbar('Error: ' + response.message, Constants.SNACKBAR_ERROR_TYPE)
                }
            })
            .catch(error => console.log(error))
            .finally(() => this.setState({isDownloading: false}))
    };

    render() {
        let symbol = this.props.typeCalculation === Constants.TYPE_CALCULATION_DECIMAL ? "" : "%";
        const {classes} = this.props;
        let periods = this.props.periods;
        return (
            <SimpleCard
                cardHeader={
                    {
                        title: this.state.title,
                        subheader: this.state.subheader,
                        action: (
                            <div>
                                <CircularProgress color={"primary"} hidden={!this.state.isLoading}/>
                                <div className={classes.wrapper} hidden={this.state.isLoading}>
                                    <Fab
                                        aria-label="save"
                                        color="default"
                                        size={"small"}
                                        onClick={() => this.handleDownloadingExcel(symbol)}
                                        disabled={this.state.isDownloading}
                                    >
                                        {<DescriptionIcon color={'primary'}/>}
                                    </Fab>
                                    {this.state.isDownloading &&
                                    <CircularProgress size={50} className={classes.fabProgress}/>}
                                </div>
                            </div>
                        )
                    }
                }
                cardContent={
                    this.state.isLoading ? "" :
                        <LocalizationProvider language="es-ES">
                            <IntlProvider locale="es">
                                <Grid
                                    data={this.state.data.slice(this.state.skip, this.state.take + this.state.skip)}
                                    skip={this.state.skip}
                                    take={this.state.take}
                                    total={this.state.data.length}
                                    style={{height: "auto"}}
                                    pageable={true}
                                    onPageChange={this.pageChange}
                                    filterable
                                    filter={this.state.filter}
                                    onFilterChange={(e) => {
                                        let filterValue = '';
                                        if (e.filter !== null) {
                                            filterValue = e.filter.filters[0].value;
                                        }
                                        if (filterValue === '') {
                                            let originalData = this.state.originalData;
                                            this.setState({
                                                data: originalData,
                                                filter: e.filter
                                            });
                                        } else {
                                            let filteredData = _.filter(this.state.originalData, function (o) {
                                                let text = o.name.toLowerCase();
                                                return text.indexOf(filterValue.toLowerCase()) > -1;
                                            });
                                            this.setState({
                                                data: filteredData,
                                                filter: e.filter
                                            });
                                        }
                                    }}
                                >
                                    <Column field="name" title="Ubicación" width={"400px"} locked={true}/>
                                    {
                                        _.orderBy(this.state.dimensions, ['sequence'], ['asc']).map(dimension => {
                                            return (
                                                <Column title={dimension.name} key={dimension.id + ""}
                                                        filterable={false}>
                                                    {
                                                        dimension.id === Constants.TOTAL_DIMENSION ?
                                                            <Column key={Constants.TOTAL_PERIOD}
                                                                    title={' '}
                                                                    width="200px"
                                                                    className={"text-center"}
                                                                    filterable={false}
                                                                    cell={
                                                                        props => {
                                                                            let item = props.dataItem;
                                                                            let finalValue = '';
                                                                            let value = "-";
                                                                            let currentDimension = _.find(item.dimensions, function (d) {
                                                                                return Number.parseInt(d.id, 10) === Constants.TOTAL_DIMENSION
                                                                            });

                                                                            if (currentDimension !== undefined) {
                                                                                let currentPeriod = _.find(currentDimension.periods, function (p) {
                                                                                    return Number.parseInt(p.id, 10) === Constants.TOTAL_PERIOD;
                                                                                });
                                                                                if (currentPeriod !== undefined) {
                                                                                    value = currentPeriod.score;
                                                                                    finalValue = value + '' + symbol
                                                                                } else {
                                                                                    finalValue = value;
                                                                                }
                                                                            } else {
                                                                                finalValue = value;
                                                                            }
                                                                            return (
                                                                                <td className="k-command-cell text-center">
                                                                                    {finalValue}
                                                                                </td>
                                                                            )
                                                                        }
                                                                    }
                                                            /> :
                                                            _.orderBy(periods, ['id'], ['asc']).map(period => {
                                                                return (

                                                                    <Column key={period.id}
                                                                            title={period.year + ' | ' + period.name}
                                                                            width="200px"
                                                                            className={"text-center"}
                                                                            filterable={false}
                                                                            cell={
                                                                                props => {
                                                                                    let item = props.dataItem;
                                                                                    let finalValue = '';
                                                                                    let value = "-";
                                                                                    let currentDimension = _.find(item.dimensions, function (d) {
                                                                                        return Number.parseInt(d.id, 10) === Number.parseInt(dimension.id, 10);
                                                                                    });

                                                                                    if (currentDimension !== undefined) {
                                                                                        let currentPeriod = _.find(currentDimension.periods, function (p) {
                                                                                            return Number.parseInt(p.id, 10) === Number.parseInt(period.id, 10);
                                                                                        });
                                                                                        if (currentPeriod !== undefined) {
                                                                                            value = currentPeriod.score;
                                                                                            finalValue = value + '' + symbol
                                                                                        } else {
                                                                                            finalValue = value;
                                                                                        }
                                                                                    } else {
                                                                                        finalValue = value;
                                                                                    }
                                                                                    return (
                                                                                        <td className="k-command-cell text-center">
                                                                                            {finalValue}
                                                                                        </td>
                                                                                    )
                                                                                }
                                                                            }
                                                                    />
                                                                )
                                                            })
                                                    }
                                                </Column>
                                            )
                                        })
                                    }
                                </Grid>
                            </IntlProvider>
                        </LocalizationProvider>
                }
            />
        );
    }

}

export default withStyles(useStyles)(RankingTable);
