import React from 'react';
import InputLabel from "@material-ui/core/InputLabel";
import {Combobox} from "react-widgets";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";
import SimpleCard from "../../../common/SimpleCard";
import Button from "@material-ui/core/Button";
import SaveIcon from '@material-ui/icons/Save';
import {DashboardService} from "../../../../api/service";
import Constants from "../../../../common/Constants";
import SnackbarContentWrapper from "../../../common/SnackBar";
import Snackbar from "@material-ui/core/Snackbar";
import TextField from "@material-ui/core/TextField";

class AddComparative extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            comparativeName: this.props.comparativeName,
            comparativeDesc: '',
            dashboards: [],
            periodsMaster: [],
            periodsSlave: [],
            selectedDashboard: this.props.selectedDashboard,
            selectedPeriodMaster: this.props.selectedPeriodMaster,
            selectedPeriodSlave: this.props.selectedPeriodSlave,
            defaultDashboard: this.props.defaultDashboard,
            disabledSlave: true,
            snackbar: {
                open: false,
                type: 'error',
                message: ''
            },
            vds: this.props.dashboardSlave,
            vpm: this.props.periodMaster,
            vps: this.props.periodSlave
        };

    }

    // <editor-fold defaultstate="collapsed" desc="Snackbar">
    handleCloseSnackbar = () => {
        this.setState({
            snackbar: {
                open: false,
                type: 'error',
                message: ''
            }
        });
    };

    handleOpenSnackbar = (message, type) => {
        this.setState({
            snackbar: {
                open: true,
                type: type,
                message: message
            }
        });
    };

    // </editor-fold>

    handleChange = (value, type) => {
        switch (type) {
            case 1:
                this.setState({
                    vds: value
                });
                break;
            case 2:
                this.setState({
                    vpm: value
                });
                break;
            case 3:
                this.setState({
                    vps: value
                });
                break;
            default:
                break;
        }

    };

    handleSelectedDashboard = dashboard => {
        this.setState({
            selectedDashboard: dashboard.id,
            disabledSlave: true,
            periodsSlave: []
        }, () => {
            setTimeout(() => {
                this.getPeriodsByDashboard(dashboard.id);
            }, 100);
        });
    };

    handleChangePeriod = (period, type) => {
        if (type === 1) {
            this.setState({
                selectedPeriodMaster: period.id
            });
        } else {
            this.setState({
                selectedPeriodSlave: period.id
            });
        }

    };

    getMyDashboardList = () => {
        let url = "/dashboard/me";
        let data = {
            userId: this.props.userId,
            dashboardId: this.props.dashboardId
        };
        DashboardService.post(url, data)
            .then(response => {
                this.setState({
                    dashboards: response.body.data.dashboards,
                    periodsMaster: response.body.data.periods
                });
            })
            .catch(error => console.log(error))
            .finally(() => this.setState({isLoading: false}))
    };

    componentDidMount() {
        this.getMyDashboardList();
    }

    getPeriodsByDashboard = (dashboardId) => {
        this.setState({
            isLoading: true,
            vps: '',
            selectedPeriodSlave: 0
        });
        let url = "/dashboard/periods";
        let data = {
            userId: this.props.userId,
            dashboardId: dashboardId
        };
        DashboardService.post(url, data)
            .then(response => {
                this.setState({
                    periodsSlave: response.body.data,
                    disabledSlave: false
                });
            })
            .catch(error => console.log(error))
            .finally(() => this.setState({isLoading: false}))
    };

    handleAddComparative = () => {
        if (this.state.comparativeName === '') {
            this.handleOpenSnackbar('Ingresa el nombre de la comparativa', Constants.SNACKBAR_ERROR_TYPE);
        } else if (this.state.selectedPeriodMaster === 0) {
            this.handleOpenSnackbar('Selecciona el primer período', Constants.SNACKBAR_ERROR_TYPE);
        } else if (this.state.selectedDashboard === 0) {
            this.handleOpenSnackbar('Selecciona el segundo dashboard', Constants.SNACKBAR_ERROR_TYPE);
        } else if (this.state.selectedPeriodSlave === 0) {
            this.handleOpenSnackbar('Selecciona el segundo período', Constants.SNACKBAR_ERROR_TYPE);
        } else {
            this.setState({
                isLoading: true,
                disabledSlave: true
            });
            let url;
            if (this.props.isUpdate) {
                url = "/dashboard/comparative/update";
            } else {
                url = "/dashboard/comparative/add";
            }

            let data = {
                comparativeId: this.props.comparativeId,
                comparativeName: this.state.comparativeName,
                comparativeDesc: this.state.comparativeDesc,
                modifyUser: this.props.userId,
                comparableList: [
                    {
                        dashboardId: parseInt(this.props.dashboardId),
                        periodId: this.state.selectedPeriodMaster

                    },
                    {
                        dashboardId: this.state.selectedDashboard,
                        periodId: this.state.selectedPeriodSlave
                    }

                ]
            };
            DashboardService.post(url, data)
                .then(response => {
                    let code = response.status;
                    if (code === 200) {
                        let message;
                        if (this.props.isUpdate) {
                            message = 'Los datos fueron modificacdos correctamente';
                        } else {
                            message = 'Los datos fueron almacenados correctamente';
                            this.setState({
                                comparativeName: '',
                                comparativeDesc: '',
                                periodsSlave: [],
                                selectedDashboard: 0,
                                selectedPeriodMaster: 0,
                                selectedPeriodSlave: 0,
                                vds: '',
                                vpm: '',
                                vps: '',
                                disabledSlave: true
                            });
                        }
                        this.handleOpenSnackbar(message, Constants.SNACKBAR_SUCCESS_TYPE);
                    } else {
                        this.handleOpenSnackbar(response.message, Constants.SNACKBAR_ERROR_TYPE);
                    }
                })
                .catch(error => console.log(error))
                .finally(() => this.setState({isLoading: false}))
        }
    }
    ;

    handleChangeComparativeName = (event) => {
        this.setState({
            comparativeName: event.target.value
        });
    };

    render() {
        return (
            <React.Fragment>
                <CssBaseline/>
                <Container maxWidth="xl">
                    <div className={'row pt-4'}>
                        <div className={'col-md-12 pt-3'}>
                            <SimpleCard
                                cardHeader={
                                    {
                                        title: this.props.cardTitle,
                                        subheader: 'Selecciona los períodos a comparar',
                                        action: (
                                            <CircularProgress color={"primary"} hidden={!this.state.isLoading}/>
                                        )
                                    }
                                }
                                cardContent={
                                    <div>
                                        <div className={'row pb-4'}>
                                            <div className={'col-md-12'}>
                                                <InputLabel htmlFor={"comparative-name"} shrink={true}
                                                            style={{fontWeight: "bold"}}
                                                            required={true}
                                                >
                                                    {'Nombre'}
                                                </InputLabel>
                                                <TextField
                                                    id="comparative-name"
                                                    value={this.state.comparativeName}
                                                    size={"small"}
                                                    fullWidth={true}
                                                    onChange={this.handleChangeComparativeName}
                                                    variant={"outlined"}
                                                    disabled={this.state.isLoading}
                                                />
                                            </div>
                                            <br/>
                                        </div>
                                        <div className={'row'}>
                                            <div className={'col-md-6'}>
                                                <InputLabel htmlFor={"dashboard-master"} shrink={true}
                                                            style={{fontWeight: "bold"}}
                                                            required={true}
                                                >
                                                    {'Dashboard'}
                                                </InputLabel>
                                                <Combobox
                                                    key={"dashboard-master"}
                                                    data={this.state.dashboards}
                                                    valueField='id'
                                                    textField='name'
                                                    defaultValue={this.state.defaultDashboard}
                                                    readOnly={true}
                                                    messages={{
                                                        emptyList: 'No hay datos',
                                                        emptyFilter: 'El filtro no retorno datos',
                                                        openCombobox: 'Desplegar'
                                                    }}
                                                />
                                            </div>
                                            <div className={'col-md-6'}>
                                                <InputLabel htmlFor={"dashboard-slave"} shrink={true}
                                                            style={{fontWeight: "bold"}}
                                                            required={true}
                                                >
                                                    {'Dashboard'}
                                                </InputLabel>
                                                <Combobox
                                                    key={"dashboard-slave"}
                                                    data={this.state.dashboards}
                                                    valueField='id'
                                                    textField='name'
                                                    caseSensitive={false}
                                                    minLength={3}
                                                    filter={filterDashboard}
                                                    readOnly={this.state.isLoading}
                                                    messages={{
                                                        emptyList: 'No hay datos',
                                                        emptyFilter: 'El filtro no retorno datos',
                                                        openCombobox: 'Desplegar'
                                                    }}
                                                    value={this.state.vds}
                                                    onSelect={dashboard => this.handleSelectedDashboard(dashboard)}
                                                    onChange={value => this.handleChange(value, 1)}
                                                />
                                            </div>
                                        </div>
                                        <div className={'row pt-4'}>
                                            <div className={'col-md-6'}>
                                                <InputLabel htmlFor={"period-master"} shrink={true}
                                                            style={{fontWeight: "bold"}}
                                                            required={true}
                                                >
                                                    {'Período'}
                                                </InputLabel>
                                                <Combobox
                                                    key={"period-master"}
                                                    data={this.state.periodsMaster}
                                                    valueField='id'
                                                    textField='name'
                                                    caseSensitive={false}
                                                    minLength={3}
                                                    filter={filterPeriod}
                                                    readOnly={this.state.isLoading}
                                                    messages={{
                                                        emptyList: 'No hay datos',
                                                        emptyFilter: 'El filtro no retorno datos',
                                                        openCombobox: 'Desplegar'
                                                    }}
                                                    value={this.state.vpm}
                                                    onSelect={period => this.handleChangePeriod(period, 1)}
                                                    onChange={value => this.handleChange(value, 2)}
                                                    groupBy='year'
                                                    defaultValue={this.props.defaultPeriodMaster}
                                                />
                                            </div>
                                            <div className={'col-md-6'}>
                                                <InputLabel htmlFor={"period-slave"} shrink={true}
                                                            style={{fontWeight: "bold"}}
                                                            required={true}
                                                >
                                                    {'Período'}
                                                </InputLabel>
                                                <Combobox
                                                    key={"period-slave"}
                                                    data={this.state.periodsSlave}
                                                    valueField='id'
                                                    textField='name'
                                                    caseSensitive={false}
                                                    minLength={3}
                                                    filter={filterDashboard}
                                                    readOnly={this.state.disabledSlave}
                                                    messages={{
                                                        emptyList: 'No hay datos',
                                                        emptyFilter: 'El filtro no retorno datos',
                                                        openCombobox: 'Desplegar'
                                                    }}
                                                    value={this.state.vps}
                                                    onSelect={period => this.handleChangePeriod(period, 2)}
                                                    onChange={value => this.handleChange(value, 3)}
                                                    groupBy='year'
                                                    defaultValue={this.props.defaultPeriodSlave}
                                                />
                                            </div>
                                        </div>
                                        <div className={"pt-4 float-right"}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="small"
                                                startIcon={<SaveIcon/>}
                                                disabled={this.state.isLoading}
                                                onClick={() => this.handleAddComparative()}
                                            >
                                                Guardar
                                            </Button>
                                        </div>
                                    </div>
                                }
                            />
                        </div>
                    </div>
                    <Snackbar
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        open={this.state.snackbar.open}
                        autoHideDuration={6000}
                        onClose={this.handleCloseSnackbar}
                    >
                        <SnackbarContentWrapper
                            onClose={this.handleCloseSnackbar}
                            variant={this.state.snackbar.type}
                            message={this.state.snackbar.message}
                        />
                    </Snackbar>
                </Container>
            </React.Fragment>
        );
    }
}

function

filterDashboard(dashboard, value) {
    let filterDashboard = dashboard.name.toLowerCase()
    let search = value.toLowerCase();

    return filterDashboard.indexOf(search) >= 0;
}

function

filterPeriod(period, value) {
    let filterPeriod = period.name.toLowerCase()
    let search = value.toLowerCase();

    return filterPeriod.indexOf(search) >= 0;
}

export default AddComparative;