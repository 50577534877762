import React from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import Util from "../../../../common/Util";
import Constants from "../../../../common/Constants";

am4core.useTheme(am4themes_animated);

class BarChart extends React.Component {

    componentDidMount() {
        let chart = am4core.create("bar-chart-div-" + this.props.chartId, am4charts.XYChart);
        chart.data = this.props.chart.data;

//create category axis for years
        let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = this.props.chart.categoryAxis;
        categoryAxis.renderer.inversed = true;
        categoryAxis.renderer.grid.template.location = 0;

//create value axis for income and expenses
        let valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
        valueAxis.renderer.opposite = true;
        valueAxis.min = 0;
        valueAxis.max = 120;
        valueAxis.renderer.minGridDistance = 20;
        valueAxis.strictMinMax = true;
        let semaphore = this.props.semaphore;
        let typeCalculation = this.props.typeCalculation;
//create columns
        let series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.categoryY = this.props.chart.categoryAxis;
        series.dataFields.valueX = this.props.chart.value;
        series.name = "";
        series.columns.template.fillOpacity = 0.9;
        series.columns.template.strokeOpacity = 0;
        series.tooltipText = "{valueX.value}" + this.props.symbol;

        series.columns.template.events.once("inited", function (event) {
            event.target.fill = Util.getColorFromSemaphore(event.target.dataItem.valueX, semaphore, typeCalculation);
        });
        //series.filters.push(new am4core.DropShadowFilter());

        let labelBullet = series.bullets.push(new am4charts.LabelBullet());
        labelBullet.label.text = "{valueX.value}" + this.props.symbol;
        labelBullet.label.dx = 10;
        labelBullet.label.fontSize = 12;
        labelBullet.label.truncate = false;
        labelBullet.label.hideOversized = false;
        labelBullet.label.horizontalCenter = "left";
//add legend
        if (this.props.withLegend) {
            chart.legend = new am4charts.Legend();
        }

        chart.responsive.enabled = true;
        chart.responsive.useDefault = false;

        chart.responsive.rules.push({
            relevant: function (target) {
                return target.pixelWidth <= Constants.RESPONSIVE_SIZE;
            },
            state: function (target, stateId) {

                if (target instanceof am4charts.Chart) {
                    let state = target.states.create(stateId);
                    state.properties.paddingTop = 0;
                    state.properties.paddingRight = 15;
                    state.properties.paddingBottom = 5;
                    state.properties.paddingLeft = 15;
                    return state;
                }

                if (target instanceof am4charts.AxisRendererY) {
                    let state = target.states.create(stateId);
                    state.properties.inside = true;
                    state.properties.maxLabelPosition = 0.99;
                    return state;
                }

                if ((target instanceof am4charts.AxisLabel) && (target.parent instanceof am4charts.AxisRendererY)) {
                    let state = target.states.create(stateId);
                    state.properties.dy = -15;
                    state.properties.paddingTop = 3;
                    state.properties.paddingRight = 5;
                    state.properties.paddingBottom = 3;
                    state.properties.paddingLeft = 5;

                    return state;
                }

                return null;
            }
        });
        this.chart = chart;
    }

    componentWillUnmount() {
        if (this.chart) {
            this.chart.dispose();
        }
    }

    render() {
        return (
            <div id={"bar-chart-div-" + this.props.chartId}
                 style={{height: this.props.height, width: this.props.width}}/>
        );
    }
}

export default BarChart;
