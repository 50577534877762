import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import TrafficIcon from '@material-ui/icons/Traffic';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import Tooltip from "@material-ui/core/Tooltip";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Constants from "../../../../common/Constants";

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 650,
    },
    primary: {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main
    }
}));

export default function Ranges({ranges, metric, handleOpenSnackbar, handleRemoveRange, openAddRange}) {
    const classes = useStyles();
    const validationAddRange = () => {
        if (ranges.length > 0) {
            let maxRange = ranges[ranges.length - 1];
            let maxValue;
            let nextValue;
            if (metric.type !== 'decimal') {
                maxValue = (maxRange.max * 100);
                if (maxValue < 100) {
                    let complement;
                    complement = 0.1;
                    for (let x = 0; x <= metric.decimals; x++) {
                        complement = complement / 10;
                    }

                    nextValue = (maxRange.max + complement) * 100;
                    openAddRange(nextValue);
                } else {
                    handleOpenSnackbar('Has llegado al valor máximo para los rangos', Constants.SNACKBAR_ERROR_TYPE);
                }
            } else {
                maxValue = maxRange.max;
                nextValue = maxValue
                openAddRange(nextValue);
            }
        } else {
            openAddRange(Constants.ZERO);
        }

    };
    return (
        <section>
            <div className={'row'}>
                <div className={'col-md-12'}>
                    <List dense={true} className={'pb-2'}>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar className={classes.primary}>
                                    <TrafficIcon/>
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    <strong>
                                        {'Semáforo'}
                                    </strong>
                                }
                                secondary={'Configura el semáforo de colores que será utilizado para los colores de las gráficas'}
                            />
                        </ListItem>
                        <ListItemSecondaryAction>
                            <Tooltip title="Agregar rango" aria-label="restore">
                                <IconButton color="primary" aria-label="add range"
                                            onClick={() => validationAddRange()}
                                >
                                    <AddIcon/>
                                </IconButton>
                            </Tooltip>
                        </ListItemSecondaryAction>
                    </List>
                </div>
            </div>

            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="ranges">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center"><strong>Orden</strong></TableCell>
                            <TableCell align="center"><strong>Nombre</strong></TableCell>
                            <TableCell align="center"><strong>Mínimo</strong></TableCell>
                            <TableCell align="center"><strong>Máximo</strong></TableCell>
                            <TableCell align="center"><strong>Color</strong></TableCell>
                            <TableCell align="center"><strong>Eliminar</strong></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {ranges.map((range) => (
                            <TableRow key={range.orden}>
                                <TableCell align="center" component="th" scope="row">
                                    {range.orden}
                                </TableCell>
                                <TableCell align="right">{range.name}</TableCell>
                                <TableCell
                                    align="center">{metric.type === 'decimal' ? parseFloat(range.min).toFixed(metric.decimals) : (parseFloat(range.min) * 100).toFixed(metric.decimals)}</TableCell>
                                <TableCell
                                    align="center">{metric.type === 'decimal' ? parseFloat(range.max).toFixed(metric.decimals) : (parseFloat(range.max) * 100).toFixed(metric.decimals)}</TableCell>
                                <TableCell align="center">{<FiberManualRecordIcon
                                    style={{color: range.color_background}} fontSize={"large"}/>}</TableCell>
                                <TableCell align="center">
                                    {
                                        <IconButton aria-label="delete"
                                                    disabled={ranges.length !== range.orden}
                                                    onClick={() => handleRemoveRange(range.orden)}
                                        >
                                            <DeleteIcon fontSize="small"
                                                        color={ranges.length !== range.orden ? "disabled" : "secondary"}/>
                                        </IconButton>
                                    }
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </section>
    );
}