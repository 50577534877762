import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Typography from "@material-ui/core/Typography";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import HeaderSection from "../../common/HeaderSection";
import {List} from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import ClearAllIcon from '@material-ui/icons/ClearAll';
import TextField from "@material-ui/core/TextField";
import 'react-widgets/dist/css/react-widgets.css';
import {Multiselect} from 'react-widgets'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    column: {
        flexBasis: '100%',
    },
}));

export default function Blacklist({surveys, blacklist, handleToggleBlacklist, search, handleOnChangeSearch, handleClearSearch}) {
    const classes = useStyles();
    return (
        <div>
            <div className={'row'}>
                <div className={'col-md-12'}>
                    <HeaderSection icon={<ClearAllIcon/>}
                                   title={'Lista negra'}
                                   subtitle={'Selecciona la o las preguntas que no se mostrarán al consultar la información de la encuesta'}
                    />
                </div>
            </div>
            <div className={'row'}>
                <div className={'col-md-12'}>
                    <div className={classes.root}>
                        {
                            surveys.map(survey => {
                                return (
                                    <ExpansionPanel key={survey.id} onChange={handleClearSearch}>
                                        <ExpansionPanelSummary
                                            expandIcon={<ExpandMoreIcon/>}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography className={classes.heading}>{survey.name}</Typography>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>

                                            <div className={classes.column} key={'div-row-list-' + survey.id}>
                                                <TextField
                                                    required
                                                    name={"search-" + survey.id}
                                                    id={"search-" + survey.id}
                                                    label="Buscar"
                                                    variant="outlined"
                                                    value={search}
                                                    fullWidth={true}
                                                    size={"small"}
                                                    className={classes.column}
                                                    onChange={ (event) => handleOnChangeSearch(event, survey.id)}
                                                />
                                                <List>
                                                    {
                                                        survey.questions.map(question => {
                                                            const labelId = `checkbox-list-label-${question.id}`;
                                                            return (
                                                                <ListItem key={question.id} role={undefined} dense
                                                                          button
                                                                          onClick={() => handleToggleBlacklist(question.id)}>
                                                                    <ListItemIcon>
                                                                        <Checkbox
                                                                            edge="start"
                                                                            checked={blacklist.indexOf(question.id) !== -1}
                                                                            tabIndex={-1}
                                                                            disableRipple
                                                                            inputProps={{'aria-labelledby': labelId}}
                                                                            color={"primary"}
                                                                        />
                                                                    </ListItemIcon>
                                                                    <ListItemText id={labelId}
                                                                                  primary={question.text}/>
                                                                </ListItem>
                                                            )

                                                        })
                                                    }
                                                </List>
                                            </div>
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                )
                            })
                        }

                    </div>
                </div>
            </div>
        </div>

    );
}
