import React from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import Util from "../../../../common/Util";

am4core.useTheme(am4themes_animated);

class ClusteredBarChart extends React.Component {

    componentDidMount() {
        let chart = am4core.create("clustered-bar-chart-div-" + this.props.chartId, am4charts.XYChart);
        chart.responsive.enabled = true;
        let symbol = this.props.symbol;

// Add data
        chart.data = this.props.chart.data;

// Create axes
        let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "period";
        categoryAxis.renderer.inversed = true;
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.cellStartLocation = 0.1;
        categoryAxis.renderer.cellEndLocation = 0.9;

        let valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
        valueAxis.renderer.opposite = true;
        valueAxis.min = 0;
        valueAxis.max = 120;
        valueAxis.renderer.minGridDistance = 20;
        valueAxis.strictMinMax = true;

// Create series
        let semaphore = this.props.semaphore;
        let typeCalculation = this.props.typeCalculation;

        function createSeries(field, name) {
            let series = chart.series.push(new am4charts.ColumnSeries());
            series.dataFields.valueX = field;
            series.dataFields.categoryY = "period";
            series.name = name;
            series.columns.template.tooltipText = "{name}: [bold]{valueX}[/]";
            series.columns.template.height = am4core.percent(100);
            series.sequencedInterpolation = true;
            series.columns.template.stroke = am4core.color("#fff");
            series.columns.template.fillOpacity = 0.9;
            series.columns.template.strokeOpacity = 0.9;
            series.columns.template.events.once("inited", function (event) {
                event.target.fill = Util.getColorFromSemaphore(event.target.dataItem.valueX, semaphore, typeCalculation);
            });

            let valueLabel = series.bullets.push(new am4charts.LabelBullet());
            valueLabel.label.text = "{valueX}" + symbol;
            valueLabel.label.horizontalCenter = "left";
            valueLabel.label.dx = 10;
            valueLabel.label.fontSize = 12;
            valueLabel.label.hideOversized = false;
            valueLabel.label.truncate = false;

            let categoryLabel = series.bullets.push(new am4charts.LabelBullet());
            categoryLabel.label.text = "{name}";
            categoryLabel.label.horizontalCenter = "right";
            categoryLabel.label.dx = -10;
            categoryLabel.label.fill = am4core.color("#fff");
            categoryLabel.label.hideOversized = false;
            categoryLabel.label.truncate = false;
        }

        createSeries("withoutUnforgivable", "Sin imperdonable");
        createSeries("withUnforgivable", "Con imperdonable");
        this.chart = chart;
    }

    componentWillUnmount() {
        if (this.chart) {
            this.chart.dispose();
        }
    }

    render() {
        return (
            <div id={"clustered-bar-chart-div-" + this.props.chartId}
                 style={{height: this.props.height, width: this.props.width}}/>
        );
    }

}

export default ClusteredBarChart;
