import React from 'react';
import AppBar from "@material-ui/core/AppBar/AppBar";
import Toolbar from "@material-ui/core/Toolbar/index";
import IconButton from "@material-ui/core/IconButton/index";
import CloseIcon from '@material-ui/icons/Close';
import Typography from "@material-ui/core/Typography/index";
import Dialog from "@material-ui/core/Dialog/index";
import Slide from "@material-ui/core/Slide/index";
import fullScreenDialogStyles from "./styles";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import CircularProgress from '@material-ui/core/CircularProgress';
import {PDFDownloadLink} from '@react-pdf/renderer';
import PDFSurvey from "../../../PDFSurvey";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const FullScreenDialog = (props) => {
    const classes = fullScreenDialogStyles();
    let period = {name: "", year: ""}
    if (props.periods !== undefined && props.periods.length > 0) {
        period = props.periods[props.periods.length - 1]
    }
    return (
        <Dialog fullScreen open={props.open}
                TransitionComponent={Transition}
                style={{zIndex: 9999}}
        >
            <AppBar position="fixed" color={"primary"}>
                <Toolbar>
                    <div className={"pl-2"}>
                        <Typography variant="h6" component={'div'}
                                    className={classes.title}>
                            {props.title}
                        </Typography>
                        <Breadcrumbs separator={<NavigateNextIcon fontSize="small"/>} aria-label="breadcrumb"
                                     style={{color: props.colorFont}}
                                     maxItems={2}
                        >
                            {
                                props.appliedFilters.map(function (filter, index) {
                                    return (
                                        <Typography key={'mpq-f' + index} className={classes.breadcrumbs}>
                                            {filter.value}
                                        </Typography>
                                    );
                                })
                            }
                        </Breadcrumbs>
                    </div>
                    <div className={classes.grow}/>
                    <div hidden={!props.isQuestionnaire || !props.isReadyQuestionnaire} className={'pr-2'}>
                        {
                            props.isQuestionnaire ? <PDFDownloadLink document={<PDFSurvey
                                dashboardName={props.title}
                                locationName={props.locationName}
                                periodName={period.year + " | " + period.name}
                                questionnaires={props.questionnaires}
                                logoClient={props.logoClient}
                            />
                            } fileName={props.title + "_" + props.locationName + ".pdf"} style={{color: '#ffffff'}}>
                                {({blob, url, loading, error}) => (loading && !blob ? <CircularProgress color={"inherit"}/> :
                                    <PictureAsPdfIcon/>)
                                }
                            </PDFDownloadLink> : ''
                        }

                    </div>
                    <div className={"float-right"}>
                        <IconButton color="inherit" component="span"
                                    onClick={props.handleCloseFullScreenDialog}
                        >
                            <CloseIcon/>
                        </IconButton>
                    </div>

                </Toolbar>
            </AppBar>
            <div className={"pt-4"}>
                {props.children}
            </div>
        </Dialog>
    );
};

export default FullScreenDialog;
