import React from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);

class SimpleColumnChart extends React.Component {
    componentDidMount() {
        let chart = am4core.create("simple-column-chart-div-" + this.props.chartId, am4charts.XYChart);
        // Add data
        chart.data = this.props.chart.data;
        let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = this.props.chart.category;
        categoryAxis.renderer.grid.template.location = 0;

        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.min = 0;
        valueAxis.max = this.props.limitRange;
        valueAxis.renderer.minGridDistance = 20;
        valueAxis.strictMinMax = true;

        chart.colors.list = this.props.chart.colors.map(function (color) {
            return new am4core.color(color);
        });
        // Create series
        let series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.valueY = this.props.chart.value;
        series.dataFields.categoryX = this.props.chart.category;
        series.name = "";
        series.columns.template.tooltipText = "{categoryX}: [bold]{valueY}[/]";
        series.columns.template.fillOpacity = 0.9;
        series.columns.template.strokeOpacity = 0;
        series.columns.template.events.once("inited", function (event) {
            event.target.fill = chart.colors.getIndex(event.target.dataItem.index);
        });


        let labelBullet = series.bullets.push(new am4charts.LabelBullet());
        labelBullet.label.text = "{valueY}" + this.props.symbol;
        labelBullet.label.fontSize = 12;
        labelBullet.label.dx = 8;
        labelBullet.label.truncate = false;
        labelBullet.label.hideOversized = false;
        labelBullet.label.verticalCenter = "bottom";
        this.chart = chart;
    }

    componentWillUnmount() {
        if (this.chart) {
            this.chart.dispose();
        }
    }

    render() {
        return (
            <div id={"simple-column-chart-div-" + this.props.chartId}
                 style={{height: this.props.height, width: "100%"}}/>
        );
    }
}

export default SimpleColumnChart;
