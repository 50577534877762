import React from 'react';
import HeaderSection from "../../common/HeaderSection";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import AddToQueueIcon from '@material-ui/icons/AddToQueue';

export default function Complement({ranking, filterGraphs, handleChangeComplements}) {
    return (
        <div>
            <div className={'row'}>
                <div className={'col-md-12'}>
                    <HeaderSection icon={<AddToQueueIcon/>}
                                   title={'Complementos'}
                                   subtitle={'Selecciona los complementos que deseas se muestren en tu dashboard'}/>
                </div>
            </div>
            <div className={'row'}>
                <div className={'col-md-6'}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={ranking}
                                name="ranking"
                                color="primary"
                                onChange={handleChangeComplements}
                            />
                        }
                        label="Tabla de ranking"
                    />
                </div>
                <div className={'col-md-6'}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={filterGraphs}
                                name="filterGraphs"
                                color="primary"
                                onChange={handleChangeComplements}
                            />
                        }
                        label="Gráficas por filtro"
                    />
                </div>
            </div>
        </div>
    );
}