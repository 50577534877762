import React from 'react'
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.addLicense("CH200331904277124");
am4core.useTheme(am4themes_animated);

class DonutChart extends React.Component {

    componentDidMount() {
        let chart = am4core.create("donut-chart-div-" + this.props.chartId, am4charts.PieChart);
        chart.responsive.enabled = true;

        let title = chart.titles.create();
        title.text = this.props.title;
        title.fontSize = 15;
        title.marginBottom = 10;
// Add data
        chart.data = this.props.data;

// Set inner radius
        chart.innerRadius = am4core.percent(50);

        let label = chart.seriesContainer.createChild(am4core.Label);
        label.text = this.props.label.text;
        label.horizontalCenter = "middle";
        label.verticalCenter = "middle";
        label.fontSize = this.props.label.fontSize;

        let colorSet = new am4core.ColorSet();
        colorSet.list = this.props.colors.map(function (color, index) {
            if (index === 1) {
                return new am4core.color(color, 0.2);
            } else {
                return new am4core.color(color);
            }
        });

// Add and configure Series
        let pieSeries = chart.series.push(new am4charts.PieSeries());
        pieSeries.ticks.template.disabled = true;
        pieSeries.labels.template.disabled = true;
        pieSeries.dataFields.category = "category";
        pieSeries.dataFields.value = "value";
        pieSeries.slices.template.stroke = am4core.color("#fff");
        pieSeries.slices.template.strokeWidth = 0;
        pieSeries.slices.template.strokeOpacity = 1;
        pieSeries.slices.template.tooltipText = this.props.tooltipText;
        pieSeries.colors = colorSet;

// This creates initial animation
        pieSeries.hiddenState.properties.opacity = 1;
        pieSeries.hiddenState.properties.endAngle = -90;
        pieSeries.hiddenState.properties.startAngle = -90;
        pieSeries.filters.push(new am4core.DropShadowFilter());
        this.chart = chart;
    }

    componentWillUnmount() {
        if (this.chart) {
            this.chart.dispose();
        }
    }

    render() {
        let isPriting = this.props.isPrinting;
        let width = isPriting ? "200px" : "250px";
        return (
            <div id={"donut-chart-div-" + this.props.chartId}
                 style={{height: this.props.height, width: width, display: "inline-block"}}/>
        );
    }
}

export default DonutChart;
