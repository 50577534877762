import React from 'react'
import Top from "../../Top";
import Bottom from "../../Bottom";
import {DashboardService} from "../../../api/service";
import * as _ from "lodash";
import Constants from "../../../common/Constants";

class RankingGraph extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            topChart: {},
            bottomChart: {}
        };

    }

    getDataRankingGraph = () => {
        let maxPeriod = _.maxBy(this.props.periods, 'id');
        let url = "dashboard/ranking/graph";
        let data = {
            dashboardId: this.props.dashboardId,
            period: maxPeriod.id,
            limit: 5,
            semaphore: this.props.semaphore,
            typeCalculation: this.props.typeCalculation,
            appliedFilters: this.props.appliedFilters,
            decimals: this.props.decimals,
            userId: this.props.userId,
            locationsStr: this.props.locationsStr
        };
        DashboardService.post(url, data)
            .then(response => {
                this.setState(
                    {
                        topChart: {
                            data: response.body.data.top,
                            categoryAxis: "name",
                            value: "score"
                        },
                        bottomChart: {
                            data: response.body.data.bottom,
                            categoryAxis: "name",
                            value: "score"
                        }
                    }
                );
            })
            .catch(error => console.log(error))
            .finally(() => this.setState({isLoading: false}))
    };
    componentDidMount() {
        this.getDataRankingGraph();
    }

    render() {
        let symbol = this.props.typeCalculation === Constants.TYPE_CALCULATION_DECIMAL ? "" : "%";
        return (
            <div className={"row"}>
                <div className={this.props.isPrinting ? "page-letter-size" : "col-md-6"}>
                    <Top
                        isLoading={this.state.isLoading}
                        chart={this.state.topChart}
                        semaphore={this.props.semaphore}
                        typeCalculation={this.props.typeCalculation}
                        symbol={symbol}
                    />
                </div>
                <div className={this.props.isPrinting ? "page-letter-size" : "col-md-6"}>
                    <Bottom
                        isLoading={this.state.isLoading}
                        chart={this.state.bottomChart}
                        semaphore={this.props.semaphore}
                        typeCalculation={this.props.typeCalculation}
                        symbol={symbol}
                    />
                </div>
            </div>
        );
    }
}

export default RankingGraph;
