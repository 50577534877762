import React from 'react';
import HeaderSection from "../../common/HeaderSection";
import TouchAppIcon from '@material-ui/icons/TouchApp';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";

export default function Actions({protocol, excel, base, repository, grill, handleChangeSwitch, handleChangeText}) {
    return (
        <div className={'pb-4'}>
            <div className={'row'}>
                <div className={'col-md-12'}>
                    <HeaderSection icon={<TouchAppIcon/>}
                                   title={'Acciones'}
                                   subtitle={'Configura las acciones que se podrán realizar en el dashboard'}/>
                </div>
            </div>
            <div className={'row pb-4'}>
                <div className={'col-md-6'}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={protocol.visible}
                                name="protocol"
                                color="primary"
                                onChange={handleChangeSwitch}
                            />
                        }
                        label="Protocolo"
                    />
                    <TextField
                        required
                        id="protocol-txt"
                        name={'protocol'}
                        label="Texto a mostrar"
                        variant="outlined"
                        value={protocol.text}
                        fullWidth={true}
                        size={"small"}
                        disabled={!protocol.visible}
                        onChange={handleChangeText}
                        inputProps={{ maxLength: 30 }}
                    />
                </div>
                <div className={'col-md-6'}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={excel.visible}
                                name="excel"
                                color="primary"
                                onChange={handleChangeSwitch}
                            />
                        }
                        label="Base excel"
                    />
                    <TextField
                        required
                        name="excel"
                        id="download-excel-txt"
                        label="Texto a mostrar"
                        variant="outlined"
                        value={excel.text}
                        fullWidth={true}
                        size={"small"}
                        disabled={!excel.visible}
                        onChange={handleChangeText}
                        inputProps={{ maxLength: 30 }}
                    />
                </div>
            </div>
            <div className={'row'}>
                <div className={'col-md-6'}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={base.visible}
                                name="base"
                                color="primary"
                                onChange={handleChangeSwitch}
                            />
                        }
                        label="Base de dashboard"
                    />
                    <TextField
                        required
                        id="download-base-txt"
                        name="base"
                        label="Texto a mostrar"
                        variant="outlined"
                        value={base.text}
                        fullWidth={true}
                        size={"small"}
                        disabled={!base.visible}
                        onChange={handleChangeText}
                        inputProps={{ maxLength: 30 }}
                    />
                </div>
                <div className={'col-md-6'}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={grill.visible}
                                name="grill"
                                color="primary"
                                onChange={handleChangeSwitch}
                            />
                        }
                        label="Parrilla multimedia"
                    />
                    <TextField
                        required
                        id="grill-txt"
                        name="grill"
                        label="Texto a mostrar"
                        variant="outlined"
                        value={grill.text}
                        fullWidth={true}
                        size={"small"}
                        disabled={!grill.visible}
                        onChange={handleChangeText}
                        inputProps={{ maxLength: 30 }}
                    />
                </div>
            </div>

        </div>
    );
}