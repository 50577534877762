import React from 'react';
import PDFViewer from "pdf-viewer-reactjs";
import CircularProgress from "@material-ui/core/CircularProgress";

function Protocol({url}) {

    return (
        <PDFViewer
            loader={<div className={"pt-5 text-center"}><CircularProgress color={"primary"}/></div>}
            document={{
                url: url,
            }}
            navbarOnTop={true}
        />
    );

}

export default Protocol;
