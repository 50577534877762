import React from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);

class LayeredColumnChart extends React.Component {

    componentDidMount() {
        let category = this.props.chart.category;
        let htmlElement = "layered-chart-div" + this.props.chartId;
        let chart = am4core.create(htmlElement, am4charts.XYChart);
        chart.responsive.enabled = true;
        // Add data
        chart.data = this.props.chart.data;

        // Create axes
        let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = category;
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.minGridDistance = 30;

        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.min = 0;
        chart.colors.list = this.props.chart.colors.map(function (color) {
            return new am4core.color(color);
        });

        // Create series
        let tooltip = this.props.chart.tooltip;
        this.props.chart.series.map(function (serieName, index) {
            let series = chart.series.push(new am4charts.ColumnSeries());
            series.dataFields.valueY = serieName;
            series.dataFields.categoryX = category;
            series.clustered = false;
            if (index === 1) {
                series.columns.template.width = am4core.percent(50);
            }
            series.columns.template.events.once("inited", function (event) {
                event.target.fill = chart.colors.getIndex(index);
                if (index === 0) {
                    event.target.fillOpacity = 0.2;
                    event.target.strokeOpacity = 0.2;
                }
            });
            series.tooltipText = tooltip[index] + ": [bold]{valueY}[/]";
        });

        chart.cursor = new am4charts.XYCursor();
        chart.cursor.lineX.disabled = true;
        chart.cursor.lineY.disabled = true;
        this.chart = chart;
    }

    componentWillUnmount() {
        if (this.chart) {
            this.chart.dispose();
        }
    }

    render() {
        return (
            <div id={"layered-chart-div" + this.props.chartId} style={{height: this.props.height, width: "100%"}}/>
        );
    }

}

export default LayeredColumnChart;
