import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import TheatersIcon from "@material-ui/icons/Theaters";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import GetAppIcon from "@material-ui/icons/GetApp";
import Divider from "@material-ui/core/Divider";
import {Player} from "video-react";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import VideocamIcon from '@material-ui/icons/Videocam';
import Tooltip from "@material-ui/core/Tooltip";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import AssignmentReturnedIcon from "@material-ui/icons/AssignmentReturned";
import JSZip from "jszip";
import Zip from "../Zip";
import FileSaver from 'file-saver';

const useStyles = theme => ({
    primary: {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main
    },
    table: {
        minWidth: 300,
    }
});

class Videos extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            videos: [],
            playerSource: '',
            isOpenDialogZip: false,
            filesAvailable: [],
            isLoading: false,
            success: true
        };
    }

    componentDidMount() {
        let filesAvailable = [];
        let videos = this.props.videos;
        for (let i = 0; i < videos.length; i++) {
            let file = videos[i];
            file["selected"] = false;
            filesAvailable.push(file);
        }
        this.setState({
            filesAvailable
        });
    }

    handleDownloadVideo = (url) => {
        const link = document.createElement('a');
        link.download = 'true';
        link.href = url;
        link.target = '_blank';
        link.click();
        link.remove();
    };

    handlePlayVideo = (url) => {
        this.setState({
            playerSource: url
        });
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.playerSource !== prevState.playerSource) {
            this.player.load();
            this.player.play();
        }
    }

    handleOpenDialogZip = () => {
        this.setState({
            isOpenDialogZip: true
        });
    };

    handleCloseDialogZip = () => {
        let filesAvailable = this.setStateAllItems(false);
        this.setState({
            isOpenDialogZip: false,
            success: true,
            isLoading:false,
            filesAvailable
        });
    };

    setStateAllItems = (state) => {
        let filesAvailable = this.state.filesAvailable;
        for (let i = 0; i < filesAvailable.length; i++) {
            filesAvailable[i].selected = state;
        }
        return filesAvailable;
    };

    handleSelectFile = (event) => {
        let isChecked = event.target.checked;
        let filesAvailable = this.state.filesAvailable;
        let id = parseInt(event.target.value);
        for (let i = 0; i < filesAvailable.length; i++) {
            if (parseInt(filesAvailable[i].id) === id) {
                filesAvailable[i].selected = isChecked;
            }
        }
        this.setState({
            filesAvailable
        });
    };

    handleSelectedAll = () => {
        let filesAvailable = this.setStateAllItems(true);
        this.setState({
            filesAvailable
        });
    };

    handleDeselectAll = () => {
        let filesAvailable = this.setStateAllItems(false);
        this.setState({
            filesAvailable
        });
    };

    handleCreateZip = () => {
        let filesAvailable = this.state.filesAvailable;
        let success = false;
        for (let x = 0; x < filesAvailable.length; x++) {
            if (filesAvailable[x].selected) {
                success = true;
                break;
            }
        }
        this.setState({
            success: success
        });

        if (success) {
            this.setState({
                isLoading: true
            });
            this.createZip();
        }

    };

    createZip = async () => {
        let filesAvailable = this.state.filesAvailable;
        let locationName = this.props.locationName.name;
        const zip = new JSZip();
        const list = filesAvailable.map(async (item, index) => {
            if (item.selected) {
                const response = await fetch(item.url);
                const data = await response.blob();
                zip.file(item.name, data);
                return data;
            }

        })

        Promise.all(list)
            .then( () => {
                zip.generateAsync({type: "blob"})
                    .then( (content) => {
                        FileSaver.saveAs(content, "videos_" + locationName + ".zip");
                        this.handleCloseDialogZip();
                    });
            });
    };


    render() {
        const {classes} = this.props;
        const rows = this.props.videos;
        return (
            <div>
                <Zip
                    isOpenDialogZip={this.state.isOpenDialogZip}
                    handleCloseDialogZip={this.handleCloseDialogZip}
                    items={this.state.filesAvailable}
                    handleSelectFile={this.handleSelectFile}
                    handleSelectedAll={this.handleSelectedAll}
                    handleDeselectAll={this.handleDeselectAll}
                    handleCreateZip={this.handleCreateZip}
                    success={this.state.success}
                    isLoading={this.state.isLoading}
                    type={2}
                />
                <br/><br/><br/><br/><br/><br/><br/><br/>
                <Divider variant="fullWidth"/>
                <List dense={true}>
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar className={classes.primary}>
                                <TheatersIcon/>
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary="Vídeos"
                            secondary={'Vídeos de la experiencia'}
                        />
                        <ListItemSecondaryAction>
                            <IconButton color="primary"
                                        className={'image-gallery-custom-action float-right'}
                                        aria-label="download zip"
                                        component="span"
                                        disabled={this.state.filesAvailable.length <= 0}
                                        onClick={() => this.handleOpenDialogZip()}
                            >
                                <AssignmentReturnedIcon/>
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                </List>
                {
                    this.props.videos.length === 0 ? (
                        <div className={'mt-4'} style={{height: '20vh'}}>
                            <p>No hay vídeos para mostrar</p>
                        </div>
                    ) : (
                        <div className={"row"}>
                            <div className={"col-md-5"}>
                                <TableContainer component={Paper}>
                                    <Table className={classes.table} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center" style={{fontWeight: "bold"}}>{''}</TableCell>
                                                <TableCell align="center"
                                                           style={{fontWeight: "bold"}}>Archivo</TableCell>
                                                <TableCell align="center" style={{fontWeight: "bold"}}>{''}</TableCell>
                                                <TableCell align={"center"}
                                                           style={{fontWeight: "bold"}}>Tipo</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {rows.map((row) => (
                                                <TableRow key={row.id}>
                                                    <TableCell align="center">
                                                        {
                                                            <IconButton color="primary"
                                                                        aria-label="play video"
                                                                        component="span"
                                                                        onClick={() => this.handlePlayVideo(row.url)}
                                                            >
                                                                <PlayArrowIcon/>
                                                            </IconButton>
                                                        }
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {row.name}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {
                                                            <IconButton color="primary"
                                                                        aria-label="download video"
                                                                        component="span"
                                                                        onClick={() => this.handleDownloadVideo(row.url)}
                                                            >
                                                                <GetAppIcon/>
                                                            </IconButton>
                                                        }
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {
                                                            row.type === 4 ? (<Tooltip title="Mystery">
                                                                    <IconButton aria-label="mystery">
                                                                        <VideocamIcon/>
                                                                    </IconButton>
                                                                </Tooltip>) :
                                                                (<Tooltip title="Vídeo">
                                                                    <IconButton aria-label="video">
                                                                        <TheatersIcon/>
                                                                    </IconButton>
                                                                </Tooltip>)
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                            <div className={"col-md-6 m-2"}>
                                <Player
                                    ref={player => {
                                        this.player = player;
                                    }}
                                    fluid={false}
                                    width={'auto'}
                                    height={300}
                                    playsInline
                                >
                                    <source src={this.state.playerSource}/>
                                </Player>
                            </div>
                        </div>
                    )
                }

            </div>
        );
    }

}

export default withStyles(useStyles)(Videos);
