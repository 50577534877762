import React from 'react';
import {DashboardService} from "../../api/service";
import CircularProgress from "@material-ui/core/CircularProgress";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import ListItemText from "@material-ui/core/ListItemText";
import SimpleCard from "../common/SimpleCard";
import Typography from "@material-ui/core/Typography";
import Constants from "../../common/Constants";
import * as _ from "lodash";

class Questionnaire extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            questionnaires: [],
            isError: false
        }
    }

    getQuestionnaire = () => {
        let url = "/dashboard/questionnaire";
        let locationsArray = this.props.locationsStr.split(',');
        let locationId = _.uniq(locationsArray)[Constants.ZERO];
        let data = {
            period: this.props.period,
            locationId: locationId,
            blacklist: this.props.blacklist.toString(),
            locationsStr: this.props.locationsStr
        };
        DashboardService.post(url, data)
            .then(response => {
                let code = response.status;
                if (code === 200){
                    this.setState(
                        {
                            questionnaires: response.body.data,
                            isError: false
                        }
                    );
                    this.props.handleIsReadyQuestionnaire(response.body.data);
                }else {
                    this.setState(
                        {
                            isError: true
                        }
                    );
                }

            })
            .catch(error => {
                this.setState(
                    {
                        isError: true
                    }
                );
            })
            .finally(() => this.setState({isLoading: false}))
    };

    componentDidMount() {
        this.getQuestionnaire();
    }

    render() {
        return (
            <div className={"pt-5"}>
                <div className={"text-center"}>
                    <CircularProgress color={"primary"} hidden={!this.state.isLoading}/>
                </div>
                <React.Fragment>
                    <CssBaseline/>
                    <Container fixed hidden={this.state.isLoading}>

                        {
                            this.state.isError ? <h3>Ocurrió un eror al cargar el cuestionario</h3>:
                            this.state.questionnaires.map((q, index) => {
                                return (
                                    <SimpleCard
                                        key={'sc' + index}
                                        cardHeader={
                                            {
                                                title: q.name,
                                                subheader: q.fullDate
                                            }
                                        }
                                        cardContent={
                                            q.questions.map((p, index) => {
                                                if (p.type === 23 || p.type === 40 || p.type === 51) {
                                                    return <div key={'div-q-' + index}>
                                                        <ListItemText primary={<Typography
                                                            style={{fontWeight: 'bold'}}>{p.text}</Typography>}
                                                                      key={'p' + index}/>
                                                        <div className={"text-center"} key={'div-qimg-' + index}>
                                                            <img style={{width: "150px", height: "auto"}} src={p.answer}
                                                                 alt={"Logo"}/>
                                                        </div>
                                                    </div>
                                                } else {
                                                    return <ListItemText primary={<Typography
                                                        style={{fontWeight: 'bold'}}>{p.text}</Typography>}
                                                                         secondary={p.answer} key={'p' + index}/>
                                                }
                                            })
                                        }
                                    />
                                )
                            })
                        }
                    </Container>
                </React.Fragment>
            </div>
        );
    }
}

export default Questionnaire;
