import React from 'react';
import DonutChart from "../../common/Chart/Donut";
import Constants from "../../../common/Constants";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import {withStyles} from "@material-ui/core/styles";
import DonutSmallIcon from '@material-ui/icons/DonutSmall';

const useStyles = theme => ({
    primary: {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main
    }
});

class Score extends React.Component{

    render() {
        const {classes} = this.props;
        let symbol = this.props.typeCalculation === Constants.TYPE_CALCULATION_DECIMAL ? "" : "%";
        let maxValue = this.props.semaphore[this.props.semaphore.length - 1].max;
        maxValue = this.props.typeCalculation === Constants.TYPE_CALCULATION_DECIMAL ? maxValue: maxValue * 100;
        const chart = {
            label: {
                text: this.props.score + symbol,
                fontSize: 15
            },
            data: [
                {
                    "category": this.props.period.name,
                    "value": this.props.score
                },
                {
                    "category": "",
                    "value": maxValue - this.props.score
                }
            ],
            colors: [this.props.color, this.props.color]
        }
        return (
            <div>
                <List dense={true}>
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar className={classes.primary}>
                                <DonutSmallIcon/>
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary="Calificación"
                            secondary={'Calificación de la ubicación'}
                        />
                    </ListItem>
                </List>
                <div className={'text-center'}>
                    <DonutChart
                        chartId={'pm-' + this.props.period.id}
                        data={chart.data}
                        label={chart.label}
                        colors={chart.colors}
                        tooltipText={""}
                        height={"200px"}
                        title={''}
                    />
                </div>

            </div>

        );
    }

}

export default withStyles(useStyles)(Score);