import React from 'react'
import {DashboardService} from "../../api/service";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import SimpleCard from "../common/SimpleCard";
import ColumnChart from "../common/Chart/Column";
import Constants from "../../common/Constants";
import * as _ from "lodash";

class ScoredByFilter extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            data: []
        };
    }

    getScoreByFilter = () => {
        let url = "dashboard/score/filter";
        let data = {
            dashboardId: this.props.dashboardId,
            periodsObj: this.props.periods,
            appliedFilters: this.props.appliedFilters,
            userId: this.props.userId,
            locationsStr: this.props.locationsStr,
            periods: this.props.periodsString,
            decimals: this.props.decimals,
            typeCalculation: this.props.typeCalculation
        };
        DashboardService.post(url, data)
            .then(response => {
                let data = response.body.data;
                for (let i = 0; i < data.length; i++) {
                    let tmpData = data[i].chart.data;
                    data[i].chart.data =  _.orderBy(tmpData, ['valueName'], ['asc']);
                    for (let x = 0; x < data[i].chart.series.length; x++){
                        data[i].chart.series[x].name = data[i].chart.series[x].year + ' | ' + data[i].chart.series[x].name.trim() ;
                    }
                    data[i].chart.series =  _.orderBy(data[i].chart.series, ['id'], ['asc']);
                }
                this.setState({
                    data: data
                });
            })
            .catch(error => console.log(error))
            .finally(() => this.setState({isLoading: false}))
    };

    componentDidMount() {
        this.getScoreByFilter();
    }

    render() {
        let symbol = this.props.typeCalculation === Constants.TYPE_CALCULATION_DECIMAL ? "" : "%";
        return (
            <SimpleCard
                cardHeader={
                    {
                        title: 'Calificaciones por filtro',
                        subheader: '',
                        action: (
                            <CircularProgress color={"primary"} hidden={!this.state.isLoading}/>
                        )
                    }
                }
                cardContent={
                    this.state.isLoading ? "" :
                        <div>
                            {
                                this.state.data.map(d => {
                                    return (
                                        <div key={'div-f' + d.sequence}>
                                            <Typography variant="h6" gutterBottom className={'text-center'}>
                                                {d.filterName}
                                            </Typography>
                                            <div>
                                                <ColumnChart
                                                    chartId={"rf-" + d.sequence}
                                                    chart={d.chart}
                                                    height={"370px"}
                                                    symbol={symbol}
                                                    semaphore={this.props.semaphore}
                                                    typeCalculation={this.props.typeCalculation}
                                                />
                                            </div>
                                            <br/>
                                        </div>

                                    );
                                })
                            }
                        </div>
                }
            />
        )
    }

}

export default ScoredByFilter;
