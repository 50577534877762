import React from 'react';
import SimpleCard from "../common/SimpleCard";
import BarChart from "../common/Chart/Bar";
import CircularProgress from "@material-ui/core/CircularProgress";

class Top extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: 'Top 5',
            subheader: 'Las 5 ubicaciones mejor calificadas'
        };
    }

    render() {
        return (
            <SimpleCard
                cardHeader={
                    {
                        title: this.state.title,
                        subheader: this.state.subheader,
                        action: (
                            <CircularProgress color={"primary"} hidden={!this.props.isLoading}/>
                        )
                    }
                }
                cardContent={
                    this.props.isLoading ? <div style={{height:"350px"}}/> :
                    <BarChart
                        chartId={"top"}
                        height={"350px"}
                        width={"100%"}
                        chart={this.props.chart}
                        withLegend={false}
                        symbol={this.props.symbol}
                        semaphore={this.props.semaphore}
                        typeCalculation={this.props.typeCalculation}
                    />
                }
            />
        );
    }

}

export default Top;
