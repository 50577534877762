import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import Tooltip from "@material-ui/core/Tooltip";
import {DashboardService} from "../../api/service";
import FilterListIcon from '@material-ui/icons/FilterList';
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Constants from "../../common/Constants";
import AssignmentIcon from "@material-ui/icons/Assignment";
import DescriptionIcon from "@material-ui/icons/Description";
import StorageIcon from "@material-ui/icons/Storage";
import PermMediaIcon from "@material-ui/icons/PermMedia";
import PieChartIcon from "@material-ui/icons/PieChart";
import DialogConfirm from "../common/Dialog/Confirm";
import * as _ from "lodash";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FullScreenDialog from "../common/Dialog/FullScreen";
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined';
import Protocol from "../Protocol";
import Questionnaire from "../Questionnaire";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import Grill from "../Grill/Container";
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import Comparative from "../Comparative";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreIcon from '@material-ui/icons/MoreVert';

const useStyles = theme => ({
    primary: {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main
    },
    grow: {
        flexGrow: 1,
    },
    title: {
        [theme.breakpoints.up('sm')]: {
            fontSize: '18px',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px'
        },
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'none',
        [theme.breakpoints.down('md')]: {
            display: 'flex',
        },
    },
    breadcrumbs: {
        [theme.breakpoints.up('sm')]: {
            fontSize: '14px',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '10px'
        },
    },
    menuText: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '12px'
        },
    },
});

class TitleBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: '',
            logo: this.props.logo,
            dialogExcel: {
                open: false,
                isLoading: false,
                title: '',
                description: '',
                textConfirmBtn: '',
                textCancelBtn: ''
            },
            dialogBase: {
                open: false,
                isLoading: false,
                title: ''
            },
            datatype: '',
            withFiltersBase: false,
            fullScreenDialog: {
                open: false,
                title: '',
                content: null
            },
            isQuestionnaire: false,
            mobileMoreAnchorEl: null,
            period: 0,
            locationId: 0,
            blacklist: 0,
            locationsStr: 0,
            isReadyQuestionnaire: false,
            questionnaires: []
        }
    }

    componentDidMount() {
        this.getHeaderInformation();
    }

    getHeaderInformation = () => {
        let url = "dashboard/header";
        let data = {
            dashboardId: this.props.dashboardId
        };
        DashboardService.post(url, data)
            .then(response => {
                this.setState(
                    {
                        title: response.body.data.dashboardName
                    }
                );
            })
            .catch(error => console.log(error))
    };

    // <editor-fold defaultstate="collapsed" desc="Dialog excel">
    handleOpenDialogExcel = () => {
        if (this.props.filterLabels.length > Constants.ZERO) {
            this.setState({
                dialogExcel: {
                    open: true,
                    isLoading: false,
                    title: 'Descargar base de datos excel',
                    description: '¿Deseas agregar los filtros del dashboard a la descarga?',
                    textConfirmBtn: 'Sí',
                    textCancelBtn: 'No'
                }
            });
        } else {
            this.setState({
                dialogExcel: {
                    open: true,
                    isLoading: true,
                    title: 'Descargar base de datos excel',
                    description: '¿Deseas agregar los filtros del dashboard a la descarga?',
                    textConfirmBtn: 'Sí',
                    textCancelBtn: 'No'
                }
            }, () => setTimeout(() => {
                this.handleSelectNo();
            }, 100));
        }
    };

    handleCloseDialogExcel = () => {
        this.setState({
            dialogExcel: {
                open: false,
                isLoading: false,
                title: '',
                description: '',
                textConfirmBtn: '',
                textCancelBtn: ''
            }
        });
    };

    handleSelectYes = () => {
        let prev = this.state.dialogExcel;
        prev.isLoading = true;
        this.setState({
            dialogExcel: prev
        });
        this.getExcelReport(true, Constants.EMPTY_STRING);
    };

    handleSelectNo = () => {
        let prev = this.state.dialogExcel;
        prev.isLoading = true;
        this.setState({
            dialogExcel: prev
        });
        this.getExcelReport(false, Constants.EMPTY_STRING);
    };
    // </editor-fold>

    // <editor-fold defaultstate="collapsed" desc="Dialog base de dashboard">
    handleOpenDialogBase = () => {
        this.setState({
            dialogBase: {
                open: true,
                isLoading: false,
                title: 'Descargar base del dashboard'
            }
        });
    };

    handleCloseDialogBase = () => {
        this.setState({
            dialogBase: {
                open: false,
                isLoading: false,
                title: ''
            },
            datatype: '',
            withFiltersBase: false
        });
    };

    handleChangeDatatype = event => {
        this.setState({
            datatype: event.target.value
        });
    };

    handleChangeWithFiltersBase = event => {
        this.setState({
            withFiltersBase: event.target.checked
        });
    };

    handleCancel = () => {
        this.handleCloseDialogBase();
    };
    handleDownload = () => {
        if (this.state.datatype === Constants.EMPTY_STRING) {
            this.props.handleOpenSnackbar('Selecciona el tipo de dato', Constants.SNACKBAR_ERROR_TYPE);
        } else {
            let prev = this.state.dialogBase;
            prev.isLoading = true;
            this.setState({
                dialogBase: prev
            });
            this.getExcelReport(this.state.withFiltersBase, this.state.datatype);
        }
    };
    // </editor-fold>

    handleIsReadyQuestionnaire = (questionnaires) => {
        this.setState({
            isReadyQuestionnaire: true,
            questionnaires: questionnaires
        });
    };
    // <editor-fold defaultstate="collapsed" desc="Dialog fullscreen">
    handleOpenFullScreenDialog = (type) => {
        let title;
        let content;

        console.log(type);

        switch (type) {
            case 1:
                title = 'Protocolo';
                this.setState({
                    isQuestionnaire: false
                }, () => {
                    setTimeout(() => {
                        content = <Protocol url={this.props.actions[Constants.ZERO].url}/>;
                        this.setState({
                            fullScreenDialog: {
                                open: true,
                                title: title,
                                content: content
                            }
                        });
                    }, 100);
                });
                break;
            case 2:
                let period = _.maxBy(this.props.periods, 'id').id;
                let locationId = this.props.locationId;
                let blacklist = this.props.blacklist;
                let locationsStr = this.props.locationsStr;
                title = this.state.title;
                this.setState({
                    isQuestionnaire: true,
                    period: period,
                    locationId: locationId,
                    blacklist: blacklist,
                    locationsStr: locationsStr,
                    isReadyQuestionnaire: false
                }, () => {
                    setTimeout(() => {
                        content = <Questionnaire locationId={locationId}
                                                 period={period}
                                                 blacklist={blacklist}
                                                 locationsStr={locationsStr}
                                                 handleIsReadyQuestionnaire={this.handleIsReadyQuestionnaire}
                        />;
                        this.setState({
                            fullScreenDialog: {
                                open: true,
                                title: title,
                                content: content
                            }
                        });
                    }, 100);
                });
                break;
            case 3:
                title = this.state.title;
                let locationName = '';
                if (this.props.isByLocation) {
                    locationName = _.maxBy(this.props.appliedFilters, 'id').value;
                }
                console.log(this.props.locationName);
                this.setState({
                    isQuestionnaire: false
                }, () => {
                    setTimeout(() => {
                        content = <Grill dashboardId={this.props.dashboardId}
                                         allPeriods={this.props.allPeriods}
                                         periods={this.props.periods}
                                         blacklist={this.props.blacklist}
                                         userId={this.props.userId}
                                         handleOpenSnackbar={this.props.handleOpenSnackbar}
                                         decimals={this.props.decimals}
                                         typeCalculation={this.props.typeCalculation}
                                         semaphore={this.props.semaphore}
                                         isByLocation={this.props.isByLocation}
                                         locationName={locationName}
                                         activeLocation={this.props.locationId}
                                         locationsStr={this.props.locationsStr}
                                         appliedFilters={this.props.appliedFilters}
                        />;
                        this.setState({
                            fullScreenDialog: {
                                open: true,
                                title: title,
                                content: content
                            }
                        });
                        console.log(this.state);
                    }, 100);
                });

                break;
            case 4:
                title = this.state.title;
                let locationNameComparative = '';
                if (this.props.isByLocation) {
                    locationNameComparative = _.maxBy(this.props.appliedFilters, 'id').value;
                }
                this.setState({
                    isQuestionnaire: false
                }, () => {
                    setTimeout(() => {
                        content = <Comparative
                            dashboardId={this.props.dashboardId}
                            locationsStr={this.props.locationsStr}
                            decimals={this.props.decimals}
                            typeCalculation={this.props.typeCalculation}
                            semaphore={this.props.semaphore}
                            locationName={locationNameComparative}
                            userId={this.props.userId}
                        />;
                        this.setState({
                            fullScreenDialog: {
                                open: true,
                                title: title,
                                content: content
                            }
                        });
                    }, 100);
                });
                break;
            default:
                title = 'ST';
                content = null;
                break;
        }
    };

    handleCloseFullScreenDialog = () => {
        this.setState({
            fullScreenDialog: {
                open: false,
                title: '',
                content: null,
                isQuestionnaire: false
            }
        });
    };
    // </editor-fold>

    getExcelReport = (withFilters, datatype) => {
        let maxPeriod = _.maxBy(this.props.periods, 'id');
        let url = "dashboard/report";
        let data = {
            dashboardId: this.props.dashboardId,
            dashboardName: this.state.title,
            periodId: maxPeriod.id,
            withFilters: withFilters,
            filters: this.props.filterLabels,
            appliedFilters: this.props.appliedFilters,
            decimals: this.props.decimals,
            typeCalculation: this.props.typeCalculation,
            datatype: datatype,
            userId: this.props.userId,
            locationsStr: this.props.locationsStr
        };
        DashboardService.post(url, data)
            .then(response => {
                let code = response.status;
                if (code === 200) {
                    const link = document.createElement('a');
                    link.href = response.body.data;
                    link.target = '_blank';
                    link.click();
                    link.remove();
                } else {
                    this.props.handleOpenSnackbar('Ocurrió un error al descargar el archivo', Constants.SNACKBAR_ERROR_TYPE)
                }
            })
            .catch(error => this.props.handleOpenSnackbar(error.message, Constants.SNACKBAR_ERROR_TYPE))
            .finally(() => {
                if (datatype === Constants.EMPTY_STRING) {
                    this.handleCloseDialogExcel();
                } else {
                    this.handleCloseDialogBase();
                }

            });
    };

    handleMobileMenuOpen = event => {
        this.setState({mobileMoreAnchorEl: event.currentTarget});
    };

    handleMobileMenuClose = () => {
        this.setState({mobileMoreAnchorEl: null});
    };

    render() {
        const {classes} = this.props;
        const isMobileMenuOpen = Boolean(this.state.mobileMoreAnchorEl);
        const actions = this.props.actions;
        let content = this.state.fullScreenDialog.content;
        const renderMobileMenu = (
            <Menu
                anchorEl={this.state.mobileMoreAnchorEl}
                anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                transformOrigin={{vertical: 'top', horizontal: 'right'}}
                open={isMobileMenuOpen}
                onClose={this.handleMobileMenuClose}
            >
                {
                    actions[Constants.ZERO].visible &&
                    <MenuItem onClick={this.handleMobileMenuClose}>
                        <IconButton aria-label=""
                                    color="inherit"
                                    onClick={() => {
                                        this.handleOpenFullScreenDialog(1);
                                    }}
                        >
                            <AssignmentIcon/>
                            <p className={classes.menuText}>{actions[Constants.ZERO].name}</p>
                        </IconButton>
                    </MenuItem>
                }

                {
                    actions[1].visible &&
                    <MenuItem onClick={this.handleMobileMenuClose}>
                        <IconButton aria-label=""
                                    color="inherit"
                                    onClick={this.handleOpenDialogExcel}
                        >
                            <DescriptionIcon/>
                            <p className={classes.menuText}>{actions[1].name}</p>
                        </IconButton>
                    </MenuItem>

                }

                {
                    actions[2].visible &&
                    <MenuItem onClick={this.handleMobileMenuClose}>
                        <IconButton aria-label=""
                                    color="inherit"
                                    onClick={this.handleOpenDialogBase}
                        >
                            <StorageIcon/>
                            <p className={classes.menuText}>{actions[2].name}</p>
                        </IconButton>
                    </MenuItem>

                }

                {
                    actions[4].visible &&
                    <MenuItem onClick={this.handleMobileMenuClose}>
                        <IconButton aria-label=""
                                    color="inherit"
                                    onClick={() => {
                                        this.handleOpenFullScreenDialog(3);
                                    }}
                        >
                            <PermMediaIcon/>
                            <p className={classes.menuText}>{actions[4].name}</p>
                        </IconButton>
                    </MenuItem>

                }

                <MenuItem onClick={this.handleMobileMenuClose}>
                    <IconButton aria-label="" color="inherit" onClick={() => {
                        this.props.handlePrinting();
                    }}>
                        <PictureAsPdfIcon/>
                        <p className={classes.menuText}>{'Exportar a pdf'}</p>
                    </IconButton>
                </MenuItem>

                {
                    this.props.isByLocation &&
                    <MenuItem onClick={this.handleMobileMenuClose}>
                        <IconButton aria-label="" color="inherit"
                                    onClick={() => {
                                        this.handleOpenFullScreenDialog(2);
                                    }}
                        >
                            <ListAltOutlinedIcon/>
                            <p className={classes.menuText}>{'Ver cuestionario'}</p>
                        </IconButton>
                    </MenuItem>
                }
                {
                    this.props.isByLocation &&
                    <MenuItem onClick={this.handleMobileMenuClose}>
                        <IconButton aria-label="" color="inherit"
                                    onClick={() => {
                                        this.handleOpenFullScreenDialog(4);
                                    }}
                        >
                            <CompareArrowsIcon/>
                            <p className={classes.menuText}>{'Comparativo de períodos'}</p>
                        </IconButton>
                    </MenuItem>
                }

                <MenuItem onClick={this.handleMobileMenuClose}>
                    <IconButton aria-label="" color="inherit" onClick={this.props.handleOpenFilters}>
                        <FilterListIcon/>
                        <p className={classes.menuText}>{'Filtros'}</p>
                    </IconButton>
                </MenuItem>

            </Menu>
        );
        let locationName = this.props.appliedFilters.length > 0 ? this.props.appliedFilters[this.props.appliedFilters.length - 1].value: "";
        return (
            <div className={classes.grow}>
                <AppBar position="fixed" color={"primary"}>
                    <Toolbar>

                        <div className={classes.sectionDesktop}>
                            <div className={"float-left"}
                                 style={{height: '64px', paddingTop: '7px', position: 'absolute'}}
                                 hidden={this.props.isPrinting}>
                                <Tooltip title={"Regresar"}>
                                    <IconButton aria-label="regresar"
                                                href={parseInt(this.props.type) === 1 ? process.env.REACT_APP_ADMIN_URL + '/DashboardFranquiciasGuardado.jsp' : '/dashboard-list/' + this.props.userBase64}
                                                target={'_self'}>
                                        <ArrowBackIosOutlinedIcon/>
                                    </IconButton>
                                </Tooltip>
                            </div>
                            <div style={{
                                borderRadius: '0px 0px 60px 0px',
                                backgroundColor: '#fff',
                                width: '260px',
                                height: '64px',
                                marginTop: '-1px',
                                marginRight: '15px'
                            }} className={"text-center"}>
                                {
                                    this.state.logo.visible ?
                                        <div edge="start" color="inherit" aria-label="Logo"
                                             style={{marginTop: '-13px !important'}}>
                                            <img style={{width: "auto", height: "64px"}} src={this.state.logo.url}
                                                 alt={"Logo"}/>
                                        </div>
                                        : ""
                                }
                            </div>
                        </div>

                        <div className={"pl-2"}>
                            <Typography variant="h6" component={'div'} className={classes.title}>
                                {this.state.title}
                            </Typography>
                            <div>
                                <Breadcrumbs separator={<NavigateNextIcon fontSize="small"/>} aria-label="breadcrumb"
                                             style={{color: this.props.colorFont}}
                                             maxItems={this.props.isPrinting ? 20 : 2}>
                                    {
                                        this.props.appliedFilters.map(function (filter, index) {
                                            return (
                                                <Typography key={'mp-f' + index} className={classes.breadcrumbs}>
                                                    {filter.value}
                                                </Typography>
                                            );
                                        })
                                    }
                                </Breadcrumbs>
                            </div>
                        </div>

                        <div className={classes.grow}/>
                        <div className={classes.sectionDesktop}>
                            <Tooltip title={this.props.actions[Constants.ZERO].name}>
                                <IconButton aria-label=""
                                            color="inherit"
                                            hidden={!this.props.actions[Constants.ZERO].visible}
                                            onClick={() => {
                                                this.handleOpenFullScreenDialog(1);
                                            }}
                                >
                                    <AssignmentIcon/>
                                </IconButton>
                            </Tooltip>

                            <Tooltip title={this.props.actions[1].name}>
                                <IconButton aria-label=""
                                            color="inherit"
                                            hidden={!this.props.actions[1].visible}
                                            onClick={this.handleOpenDialogExcel}
                                >
                                    <DescriptionIcon/>
                                </IconButton>
                            </Tooltip>

                            <Tooltip title={this.props.actions[2].name}>
                                <IconButton aria-label=""
                                            color="inherit"
                                            hidden={!this.props.actions[2].visible}
                                            onClick={this.handleOpenDialogBase}
                                >
                                    <StorageIcon/>
                                </IconButton>
                            </Tooltip>

                            <Tooltip title={this.props.actions[4].name}>
                                <IconButton aria-label=""
                                            color="inherit"
                                            hidden={!this.props.actions[4].visible}
                                            onClick={() => {
                                                this.handleOpenFullScreenDialog(3);
                                            }}
                                >
                                    <PermMediaIcon/>
                                </IconButton>
                            </Tooltip>

                            <Tooltip title={this.props.actions[4].name}>
                                <IconButton aria-label=""
                                            color="inherit"
                                            hidden={true}>
                                    <PieChartIcon/>
                                </IconButton>
                            </Tooltip>

                            <Tooltip title="Exportar a PDF">
                                <IconButton aria-label="" color="inherit" onClick={() => {
                                    this.props.handlePrinting();
                                }}>
                                    <PictureAsPdfIcon/>
                                </IconButton>
                            </Tooltip>

                            <Tooltip title="Ver cuestionario">
                                <IconButton aria-label="" color="inherit" hidden={!this.props.isByLocation}
                                            onClick={() => {
                                                this.handleOpenFullScreenDialog(2);
                                            }}
                                >
                                    <ListAltOutlinedIcon/>
                                </IconButton>
                            </Tooltip>

                            <Tooltip title="Comparativo de períodos">
                                <IconButton aria-label="" color="inherit" hidden={!this.props.isByLocation}
                                            onClick={() => {
                                                this.handleOpenFullScreenDialog(4);
                                            }}
                                >
                                    <CompareArrowsIcon/>
                                </IconButton>
                            </Tooltip>

                            <Tooltip title="Filtros">
                                <IconButton aria-label=""
                                            color="inherit"
                                            onClick={this.props.handleOpenFilters}
                                            disabled={this.props.isLoadingFilters}
                                >
                                    <FilterListIcon/>
                                </IconButton>
                            </Tooltip>
                            <div>
                                <ListItem dense={true} style={{fontSize: '5px'}}>
                                    <ListItemText
                                        primary={<span style={{float: 'right'}}>{this.props.user.fullName}</span>}
                                        secondary={<span style={{
                                            color: this.props.colorFont,
                                            float: 'right'
                                        }}>{this.props.user.user}</span>}/>
                                </ListItem>
                            </div>

                        </div>

                        <div className={classes.sectionMobile}>
                            <IconButton aria-haspopup="true" onClick={this.handleMobileMenuOpen} color="inherit"
                                        hidden={this.props.isPrinting}>
                                <MoreIcon/>
                            </IconButton>
                            {this.props.isPrinting ? '' : renderMobileMenu}
                        </div>
                    </Toolbar>
                </AppBar>
                <DialogConfirm
                    dialogConfirm={this.state.dialogExcel}
                    handleCloseDialogConfirm={this.handleCloseDialogExcel}
                    handleSelectYes={this.handleSelectYes}
                    handleSelectNo={this.handleSelectNo}
                />

                <Dialog open={this.state.dialogBase.open} onClose={this.handleCloseDialogBase}
                        aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">{this.state.dialogBase.title}</DialogTitle>
                    <DialogContent>
                        <DialogContentText component={'div'}>
                            <div hidden={this.state.dialogBase.isLoading}>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend" required={true}>Tipo de dato para la
                                        descarga</FormLabel>
                                    <RadioGroup aria-label="datatype" name="datatype" value={this.state.datatype}
                                                onChange={this.handleChangeDatatype}>
                                        <FormControlLabel value="answers" control={<Radio color={"primary"}/>}
                                                          label="Respuestas"/>
                                        <FormControlLabel value="binary" control={<Radio color={"primary"}/>}
                                                          label="Binario"/>
                                        <FormControlLabel value="weight" control={<Radio color={"primary"}/>}
                                                          label="Ponderación"/>
                                    </RadioGroup>
                                </FormControl>
                                <FormControl component="fieldset">
                                    <FormControlLabel value="si" control={<Checkbox color="primary"
                                                                                    checked={this.state.withFiltersBase}
                                                                                    onChange={this.handleChangeWithFiltersBase}
                                                                                    disabled={this.props.filterLabels.length === Constants.ZERO}
                                    />}
                                                      label="Deseo agregar los filtros del dashboard a la descarga"
                                                      labelPlacement="end"/>
                                </FormControl>
                            </div>
                            <div className={"text-center"} hidden={!this.state.dialogBase.isLoading}>
                                <CircularProgress color={"primary"}/>
                            </div>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCancel} color="default" variant="outlined"
                                disabled={this.state.dialogBase.isLoading}>
                            {'Cancelar'}
                        </Button>
                        <Button onClick={this.handleDownload} color="primary" variant="outlined"
                                disabled={this.state.dialogBase.isLoading}>
                            {'Descargar'}
                        </Button>
                    </DialogActions>
                </Dialog>

                <FullScreenDialog open={this.state.fullScreenDialog.open}
                                  title={this.state.fullScreenDialog.title}
                                  appliedFilters={this.props.appliedFilters}
                                  colorFont={this.props.colorFont}
                                  isQuestionnaire={this.state.isQuestionnaire}
                                  isSaveButtonshow={false}
                                  handleCloseFullScreenDialog={this.handleCloseFullScreenDialog}
                                  period={this.state.period}
                                  locationId={this.state.locationId}
                                  blacklist={this.state.blacklist}
                                  locationsStr={this.state.locationsStr}
                                  isReadyQuestionnaire={this.state.isReadyQuestionnaire}
                                  dashboardId={this.props.dashboardId}
                                  handleOpenSnackbar={this.props.handleOpenSnackbar}
                                  clientLogo={this.state.logo.url}
                                  periods={this.props.periods}
                                  questionnaires={this.state.questionnaires}
                                  locationName={locationName}
                                  logoClient={this.state.logo.url}
                >
                    {content}
                </FullScreenDialog>

            </div>
        );
    }
}

export default withStyles(useStyles)(TitleBar);
