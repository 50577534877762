import React from 'react';
import SimpleCard from "../../common/SimpleCard";
import ClusteredBarChart from "../../common/Chart/ClusteredBar";
import {DashboardService} from "../../../api/service";
import Constants from "../../../common/Constants";
import CircularProgress from "@material-ui/core/CircularProgress";

class SectionFinalScore extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            title: 'Resultado final',
            subheader: 'Muestra la diferencia evaluando preguntas imperdonables',
            chart: {
                data: []
            }
        };
    }

    getFinalScore = () => {
        let url = "dashboard/score/final";
        let data = {
            dashboardId: this.props.dashboardId,
            periods: this.props.periodsString,
            semaphore: this.props.semaphore,
            appliedFilters: this.props.appliedFilters,
            decimals: this.props.decimals,
            typeCalculation: this.props.typeCalculation,
            userId: this.props.userId,
            locationsStr: this.props.locationsStr
        };
        DashboardService.post(url, data)
            .then(response => {
                this.setState({
                    chart: {
                        data: response.body.data
                    }
                });
            })
            .catch(error => console.log(error))
            .finally(() => this.setState({isLoading: false}))
    };

    componentDidMount() {
        this.getFinalScore();
    }

    render() {
        let symbol = this.props.typeCalculation === Constants.TYPE_CALCULATION_DECIMAL ? "" : "%";
        return (
            <SimpleCard
                cardHeader={
                    {
                        title: this.state.title,
                        subheader: this.state.subheader,
                        action: (
                            <CircularProgress color={"primary"} hidden={!this.state.isLoading}/>
                        )
                    }
                }
                cardContent={
                    this.state.isLoading ? "" :
                    <ClusteredBarChart
                        chartId={"rfc"}
                        height={(this.props.periods.length * 100) + 50}
                        width={"100%"}
                        chart={this.state.chart}
                        symbol={symbol}
                        semaphore={this.props.semaphore}
                        typeCalculation={this.props.typeCalculation}
                    />
                }
            />
        );
    }
}

export default SectionFinalScore;
