import React from 'react';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import CropOriginalIcon from "@material-ui/icons/CropOriginal";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import GetAppIcon from "@material-ui/icons/GetApp";
import Divider from "@material-ui/core/Divider";
import ImageGallery from "react-image-gallery";
import {withStyles} from "@material-ui/core/styles";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Tooltip from "@material-ui/core/Tooltip";
import AssignmentReturnedIcon from '@material-ui/icons/AssignmentReturned';
import Zip from "../Zip";
import JSZip from "jszip";
import FileSaver from 'file-saver';

const useStyles = theme => ({
    primary: {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main
    }
});

class Photographs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            photos: [],
            isOpenDialogZip: false,
            filesAvailable: [],
            isLoading: false,
            success: true
        }
    }

    componentDidMount() {
        let filesAvailable = [];
        let photos = this.props.photos;
        for (let i = 0; i < photos.length; i++) {
            let file = {
                "id": photos[i].id,
                "name": photos[i].name,
                "url": photos[i].original,
                "selected": false
            };
            filesAvailable.push(file);
        }
        this.setState({
            filesAvailable
        });
    }

    handlerDownloadPhoto = () => {
        const index = this.gallery.getCurrentIndex();
        const photo = this.props.photos[index];
        const link = document.createElement('a');
        link.download = photo.name;
        link.href = photo.original;
        link.target = '_blank';
        link.click();
        link.remove();
    };

    renderCustomControls = () => {
        return <div>
            <Tooltip title={"Descargar zip"} aria-label="download zip" children={
                <IconButton color="primary"
                            className={'image-gallery-custom-action float-right'}
                            aria-label="download zip"
                            component="span"
                            disabled={this.state.filesAvailable.length <= 0}
                            onClick={() => this.handleOpenDialogZip()}
                >
                    <AssignmentReturnedIcon/>
                </IconButton>
            }/>
            <Tooltip title={"Descargar fotografía actual"} aria-label="download" children={
                <IconButton color="primary"
                            className={'image-gallery-custom-action float-right'}
                            aria-label="download photo"
                            component="span"
                            disabled={this.state.filesAvailable.length <= 0}
                            onClick={() => this.handlerDownloadPhoto()}
                >
                    <GetAppIcon/>
                </IconButton>
            }/>
        </div>;
    };

    handleOpenDialogZip = () => {
        this.setState({
            isOpenDialogZip: true
        });
    };

    handleCloseDialogZip = () => {
        let filesAvailable = this.setStateAllItems(false);
        this.setState({
            isOpenDialogZip: false,
            success: true,
            isLoading:false,
            filesAvailable
        });
    };

    setStateAllItems = (state) => {
        let filesAvailable = this.state.filesAvailable;
        for (let i = 0; i < filesAvailable.length; i++) {
            filesAvailable[i].selected = state;
        }
        return filesAvailable;
    };

    handleSelectFile = (event) => {
        let isChecked = event.target.checked;
        let filesAvailable = this.state.filesAvailable;
        let id = parseInt(event.target.value);
        for (let i = 0; i < filesAvailable.length; i++) {
            if (parseInt(filesAvailable[i].id) === id) {
                filesAvailable[i].selected = isChecked;
            }
        }
        this.setState({
            filesAvailable
        });
    };

    handleSelectedAll = () => {
        let filesAvailable = this.setStateAllItems(true);
        this.setState({
            filesAvailable
        });
    };

    handleDeselectAll = () => {
        let filesAvailable = this.setStateAllItems(false);
        this.setState({
            filesAvailable
        });
    };

    handleCreateZip = () => {
        let filesAvailable = this.state.filesAvailable;
        let success = false;
        for (let x = 0; x < filesAvailable.length; x++) {
            if (filesAvailable[x].selected) {
                success = true;
                break;
            }
        }
        this.setState({
            success: success
        });

        if (success) {
            this.setState({
                isLoading: true
            });
            this.createZip();
        }

    };

    createZip = async () => {
        let filesAvailable = this.state.filesAvailable;
        let locationName = this.props.locationName.name;
        const zip = new JSZip();
        const list = filesAvailable.map(async (item, index) => {
            if (item.selected) {
                const response = await fetch(item.url);
                const data = await response.blob();
                zip.file(item.name + ".png", data);
                return data;
            }

        })

        Promise.all(list)
            .then( () => {
                zip.generateAsync({type: "blob"})
                    .then( (content) => {
                        FileSaver.saveAs(content, "images_" + locationName + ".zip");
                        this.handleCloseDialogZip();
                    });
            });
    };

    render() {
        const {classes} = this.props;
        let renderCustomControls = this.renderCustomControls();
        return (
            <div>
                <Zip
                    isOpenDialogZip={this.state.isOpenDialogZip}
                    handleCloseDialogZip={this.handleCloseDialogZip}
                    items={this.state.filesAvailable}
                    handleSelectFile={this.handleSelectFile}
                    handleSelectedAll={this.handleSelectedAll}
                    handleDeselectAll={this.handleDeselectAll}
                    handleCreateZip={this.handleCreateZip}
                    success={this.state.success}
                    isLoading={this.state.isLoading}
                    type={1}
                />
                <br/>
                <Divider variant="fullWidth"/>
                <List dense={true}>
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar className={classes.primary}>
                                <CropOriginalIcon/>
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary="Fotografías"
                            secondary={'Galería de fotografías realizadas en la experiencia'}
                        />
                        <ListItemSecondaryAction>
                            {renderCustomControls}
                        </ListItemSecondaryAction>
                    </ListItem>
                </List>

                {
                    this.props.photos.length === 0 ? (
                        <div className={'mt-4'} style={{height: '20vh'}}>
                            <p>No hay fotografías para mostrar</p>
                        </div>
                    ) : (
                        <div style={{height: '60vh'}}>
                            <ImageGallery
                                ref={gallery => this.gallery = gallery}
                                items={this.props.photos}
                                showFullscreenButton={true}
                                thumbnailPosition={"bottom"}
                                showPlayButton={false}
                            />
                        </div>
                    )
                }


            </div>
        );
    }

}

export default withStyles(useStyles)(Photographs);
