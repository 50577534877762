import React from 'react';
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import SimpleCard from "../../../common/SimpleCard";
import Button from "@material-ui/core/Button";
import SaveIcon from "@material-ui/icons/Save";
import TextField from "@material-ui/core/TextField";
import {TwitterPicker} from "react-color";
import Constants from "../../../../common/Constants";
import SnackbarContentWrapper from "../../../common/SnackBar";
import Snackbar from "@material-ui/core/Snackbar";

class AddRange extends React.Component {
    constructor(props) {
        super(props);
        let minimumValue = parseFloat(this.props.nextValue).toFixed(this.props.metric.decimals);
        this.state = {
            name: '',
            minimumValue: minimumValue,
            maximumValue: minimumValue,
            color: '#EB144C',
            snackbar: {
                open: false,
                type: 'error',
                message: ''
            }
        };
    }

    handleChangeRangeValues = (event) => {
        let key = event.target.name;
        let value = event.target.value;
        this.setState({
            [key]: value
        });
    };

    formatNumber = () => {
        let formatValue = parseFloat(this.state.maximumValue).toFixed(this.props.metric.decimals);
        this.setState({
            maximumValue: formatValue
        });

    };

    handleChangeColor = (color) => {
        this.setState({
            color: color
        });
    };

    validateAddRange = () => {
        if (this.state.name === ''){
            this.handleOpenSnackbar('Ingresa el nombre del rago', Constants.SNACKBAR_ERROR_TYPE);
        }else if (parseFloat(this.state.maximumValue) < parseFloat(this.state.minimumValue)){
            this.handleOpenSnackbar('El valor máximo debe ser  mayor o igual al minímo', Constants.SNACKBAR_ERROR_TYPE);
        }else if (parseFloat(this.state.maximumValue) === Constants.ZERO){
            this.handleOpenSnackbar('El valor máximo debe ser mayor a zero', Constants.SNACKBAR_ERROR_TYPE);
        }  else if (this.state.color === ''){
            this.handleOpenSnackbar('Selecciona el color del rango', Constants.SNACKBAR_ERROR_TYPE);
        }else {
            let min = this.state.minimumValue;
            let max = this.state.maximumValue;
            if(this.props.metric.type !== Constants.TYPE_CALCULATION_DECIMAL) {
                min = parseFloat(min) / 100;
                max = parseFloat(max) / 100;
                if (max > 1){
                    this.handleOpenSnackbar('El valor máximo del rango no puede ser mayor a 100', Constants.SNACKBAR_ERROR_TYPE);
                }else {
                    let range = {
                        orden: 0,
                        name: this.state.name,
                        min: min,
                        max: max,
                        color_background: this.state.color,
                        color_font: "#FFFFFF"
                    };
                    this.props.addRange(range);
                }
            }else {
                min = parseInt(min);
                max = parseInt(max);
                let range = {
                    orden: 0,
                    name: this.state.name,
                    min: min,
                    max: max,
                    color_background: this.state.color,
                    color_font: "#FFFFFF"
                };
                this.props.addRange(range);
            }
        }
    };

    // <editor-fold defaultstate="collapsed" desc="Snackbar">
    handleCloseSnackbar = () => {
        this.setState({
            snackbar: {
                open: false,
                type: 'error',
                message: ''
            }
        });
    };

    handleOpenSnackbar = (message, type) => {
        this.setState({
            snackbar: {
                open: true,
                type: type,
                message: message
            }
        });
    };

    // </editor-fold>

    render() {
        return (
            <React.Fragment>
                <CssBaseline/>
                <Container maxWidth="sm">
                    <div className={'row pt-4'}>
                        <div className={'col-md-12 pt-3'}>
                            <SimpleCard
                                cardHeader={
                                    {
                                        title: 'Agregar rango',
                                        subheader: 'Ingresa los datos del nuevo rango'
                                    }
                                }
                                cardContent={
                                    <div>
                                        <div className={'row pb-4'}>
                                            <div className={'col-md-12'}>
                                                <TextField
                                                    required
                                                    id="add-name-range"
                                                    name={'name'}
                                                    label="Nombre"
                                                    variant="outlined"
                                                    value={this.state.name}
                                                    fullWidth={true}
                                                    size={"small"}
                                                    inputProps={{maxLength: 50}}
                                                    onChange={this.handleChangeRangeValues}
                                                />
                                            </div>
                                        </div>
                                        <div className={'row pb-4'}>
                                            <div className={'col-md-6'}>
                                                <TextField
                                                    required
                                                    id="add-min-value"
                                                    name={'minimumValue'}
                                                    label="Valor mínimo"
                                                    variant="outlined"
                                                    value={this.state.minimumValue}
                                                    fullWidth={true}
                                                    size={"small"}
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                />
                                            </div>

                                            <div className={'col-md-6'}>
                                                <TextField
                                                    required
                                                    name={'maximumValue'}
                                                    id="add-max-value"
                                                    label="Valor máximo"
                                                    variant="outlined"
                                                    value={this.state.maximumValue}
                                                    fullWidth={true}
                                                    size={"small"}
                                                    type={'number'}
                                                    InputProps={{inputProps: {min: this.state.minimumValue}}}
                                                    onChange={this.handleChangeRangeValues}
                                                    onBlur={this.formatNumber}
                                                />
                                            </div>
                                        </div>

                                        <div className={'row'}>
                                            <div className={'col-md-2'}/>
                                            <div className={'col-md-8'}>
                                                <h6 className={'pb-2'}>Color</h6>
                                                <TwitterPicker color={this.state.color}
                                                              onChangeComplete={color => this.handleChangeColor(color.hex)}
                                                />
                                            </div>
                                            <div className={'col-md-2'}/>
                                        </div>

                                        <div className={"pt-4 float-right"}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="small"
                                                startIcon={<SaveIcon/>}
                                                disabled={false}
                                                onClick={this.validateAddRange}
                                            >
                                                Guardar
                                            </Button>
                                        </div>
                                    </div>
                                }
                            />
                        </div>
                    </div>
                    <Snackbar
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        open={this.state.snackbar.open}
                        autoHideDuration={6000}
                        onClose={this.handleCloseSnackbar}
                    >
                        <SnackbarContentWrapper
                            onClose={this.handleCloseSnackbar}
                            variant={this.state.snackbar.type}
                            message={this.state.snackbar.message}
                        />
                    </Snackbar>
                </Container>
            </React.Fragment>
        );
    }
}

export default AddRange;
